import React, { useState } from "react";
import Header from "../partials/UserHeader";
import { Link, useNavigate } from "react-router-dom";

const CompanySignup = () => {
  const navigate = useNavigate();

  const APIURL = "https://job.creativewebpixel.in";
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [userMesaage, setUserMessage] = useState("");
  const [loader, setLoader] = useState(false);

  function companyFormSubmit() {
    const type = "company";
    const data = {
      fullName,
      email,
      phoneNo,
      password,
      type,
    };

    console.log(data);
    setLoader(true);
    fetch(`${APIURL}/manuallyRegistration`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json(console.log(response)))
      .then((res) => {
        console.log("resss", res.message);
        setUserMessage(res.message);
        setLoader(false);

        if (res.message == "Thanks for registration") {
          navigate("/login");
          setLoader(false);
        }
      })
      .catch((error) => console.log(error));
  }

  const [view, setView] = useState(false)

  const viewPassword = () => {
    if (view == false) {
      setView(true)
    } else {
      setView(false)
    }

  }
  return (
    <div>


      <div class="modal-body p-0">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-5 p-0 position-relative">
              <img src="images/img/login.jpg" alt className="img-fluid" style={{ transform: "rotateY(180deg)" }} />
              <div className="bg-color7 w-75 m-auto ps-5 py-4 position-absolute position2">
                <button className="bg-color6 border-0 fs-6 p-2  rounded-2 text-white">
                  {" "}
                  🖐&nbsp;&nbsp;Hey We're here for you!
                </button>
                <p className="pt-3 text-white w-75">
                Welcome to Creative Web Pixel Job Portal! Discover exceptional talent to fuel your company's success. Let's find the perfect match together!
                </p>
              </div>
            </div>
            <div className="col-md-7 px-5">
              <form
                onSubmit={(e) => companyFormSubmit(e.preventDefault())}
                className=" form-login"
              >
                <div className="pb-4 text-center">
                  <h3 className="fw-semibold ">Get Started With CWP</h3>
                  <p className="text-black">Getting started is easy</p>

                  <div
                    className="btn-group btn-group-toggle"
                    data-toggle="buttons"
                  >
                    <label className="btn btn-primary active">
                      <Link to="/company-signup" className="text-white">
                        Company
                      </Link>
                    </label>
                    <label className="btn btn-secondary">
                      <Link to="/candidate-signup" className="text-white">
                        Candidate
                      </Link>{" "}
                    </label>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Full Name
                  </label>
                  <input
                    required
                    onChange={(event) => setFullName(event.target.value)}
                    type="text"
                    className="form-control rounded-0 py-3"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="User5643"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email{" "}
                  </label>
                  <input
                    required
                    onChange={(event) => setEmail(event.target.value)}
                    type="email"
                    className="form-control rounded-0 py-3"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="demo@gmail.com"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Phone Number
                  </label>
                  <input
                    required
                    onChange={(event) => setPhoneNo(event.target.value)}
                    type="number"
                    className="form-control rounded-0 py-3"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Number"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Password
                  </label>
                  <div className=" position-relative d-flex align-items-center">
                    <input required
                      onChange={(event) => setPassword(event.target.value)}
                      type={view == false ? "password" : "text"}
                      className="form-control rounded-0 py-3"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="*************"
                    />
                    <i className={
                      view
                        ?
                        "fa-regular fa-eye-slash position-absolute"
                        :
                        "fa-regular fa-eye position-absolute"
                    }
                      onClick={() => viewPassword()}
                      style={{ right: "15px", cursor: "pointer" }}
                    >
                    </i>
                  </div>


                </div>

                {loader ? (
                  <button
                    type="submit"
                    className="btn bg-color1 rounded-0 btn2 py-2 hire border-0 position-relative"
                    disabled
                  >
                    {" "}
                    <div
                      className="spinner-border"
                      style={{ width: "25px", height: "25px", right: "150px" }}
                      role="status"
                    >
                      <span class="visually-hidden ">Loading...</span>
                    </div>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn bg-color1 rounded-0 btn2 py-2 hire "
                  >
                    Sign Up
                  </button>
                )}

                {userMesaage == "Thanks for registration" ? (
                  <p
                    style={{ color: "#FFCD4D", textAlign: "center", margin: 0 }}
                  >
                    {userMesaage}
                  </p>
                ) : (
                  <p style={{ color: "red", textAlign: "center", margin: 0 }}>
                    {userMesaage}
                  </p>
                )}

                <p className="text-center pt-4">
                  {" "}
                  Have an Account?{" "}
                  <Link to="/login" className="text-color">
                    {" "}
                    Login
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanySignup;
