import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../css/images/Black-Logo.png";
import VectorDown from "../../css/images/Vector-down.png";
import profile from "../../css/images/profile.png";
import ph_bell from "../../css/images/ph_bell.png";
import DeleteImg from "../../css/images/Inbox-cleanup-rafiki.png";
import noProfileImg from "../../css/images/profile.jpg";
import axios from "axios";

const APIURL = "https://job.creativewebpixel.in";

function CompanyHeader() {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState();
  useEffect(() => {
    setCurrentPath(location.pathname);

    // window.scrollTo(0, 0);
  }, [location]);
  const [profileStatus, setProfileStatus] = useState("");
  const [response, setResponse] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("email");

    axios({
      url: `${APIURL}/userDetails`,
      method: "GET",

      headers: {
        Authorization: `${userId}`,
      },
    })
      .then((response) => response.data)
      .then((res) => {
        console.log("userdetails", res.message[0]);
        setResponse(res.message[0]);

        setProfileStatus(res.message[0].status);
      });
  }, []);

  const logout = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("type");
    localStorage.removeItem("userId");
    window.location.reload();
  };
  return (
    <>
      <header style={{ position: "sticky", top: 0, zIndex: 2 }}>
        <nav className="navbar navbar-expand-lg bg-white" style={{ zIndex: 2 }}>
          <div className="container">
            <Link className="navbar-brand " to="/company-dashboard">
              <img src={logo} alt />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav nav-underline">
                <li className="nav-item ">
                  <Link
                    className={
                      currentPath == "/company-dashboard"
                        ? "nav-link fw-normal active fw-semibold"
                        : "nav-link fw-normal"
                    }
                    aria-current="page"
                    to="/company-dashboard"
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item position-relative">
                  <Link
                    className={
                      currentPath == "/all-candidate" ||
                      currentPath == "/hired-candidate" ||
                      currentPath == "/candidate-request" ||
                      currentPath == "/listed-job"
                        ? "nav-link fw-normal active  "
                        : "nav-link fw-normal"
                    }
                    to="#"
                  >
                    Work Opportunities
                  </Link>
                  <div className="dropdown ">
                    <ul className=" p-0  dropdown-content ">
                      <li>
                        <Link
                          to="/all-candidate"
                          className={
                            currentPath == "/all-candidate"
                              ? "d-block active pt-2 "
                              : "d-block pt-2"
                          }
                        >
                          <i className="bi bi-globe" />
                          All Candidate
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/hired-candidate"
                          className={
                            currentPath == "/hired-candidate"
                              ? "d-block active pt-2 "
                              : "d-block pt-2"
                          }
                        >
                          <i className="bi bi-star" />
                          Hired Candidate
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/candidate-request"
                          className={
                            currentPath == "/candidate-request"
                              ? "d-block active pt-2 "
                              : "d-block pt-2"
                          }
                        >
                          <i className="bi bi-star" />
                          Candidate Requests
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/listed-job"
                          className={
                            currentPath == "/listed-job"
                              ? "d-block active pt-2 "
                              : "d-block pt-2"
                          }
                        >
                          <i className="bi bi-star" />
                          Listed Jobs
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                {/* <li className="nav-item ">
                  <Link
                    className={
                      currentPath == "/company-interview"
                        ? "nav-link fw-normal active"
                        : "nav-link fw-normal"
                    }
                    to="/company-interview"
                  >
                    Create Interview
                  </Link>
                </li> */}

                <li className="nav-item ">
                  <Link
                    className={
                      currentPath == "/company-profile"
                        ? "nav-link fw-normal active"
                        : "nav-link fw-normal"
                    }
                    to="/company-profile"
                  >
                    Profile
                  </Link>
                </li>
                <li className="nav-item ">
                  {profileStatus == "complete" ? (
                    <Link
                      className={
                        currentPath == "/create-job"
                          ? "nav-link fw-normal active"
                          : "nav-link fw-normal"
                      }
                      to="/create-job"
                    >
                      Post Job
                    </Link>
                  ) : (
                    <Link
                      className="nav-link fw-normal "
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Post Job
                    </Link>
                  )}
                </li>
              </ul>
            </div>
            <div className="header-right  d-flex align-items-center">
              <div className>
                <Link to="" className="position-relative">
                  <span className="position-absolute notficaton d-flex justify-content-center align-items-center ">
                    1
                  </span>
                  <img src={ph_bell} alt />
                </Link>
              </div>

              <div className="profile-menu">
                <ul className="nav-item p-0 m-0">
                  <li
                    className="nav-item position-relative d-flex"
                    style={{ lineHeight: "50px" }}
                  >
                    <div>
                      {" "}
                      <Link to="/company-profile">
                        {" "}
                        {response.companyLogo == "" ||
                        response.companyLogo == null ? (
                          <img
                            src={noProfileImg}
                            className="ms-3"
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "100%",
                              objectFit: "cover",
                              border: "solid 1px #000",
                            }}
                            alt=""
                          />
                        ) : (
                          <img
                            src={APIURL + "/img/" + response.companyLogo}
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "100%",
                              objectFit: "cover",
                              border: "solid 1px #000",
                            }}
                            className="ms-3"
                            alt
                          />
                        )}
                      </Link>
                    </div>
                    <Link className="nav-link fw-normal  ms-2 " to="#">
                      <img src={VectorDown} alt />
                    </Link>
                    <div className="dropdown1 position-absolute mt-3">
                      <ul className=" p-0  dropdown-content ">
                        <li style={{ lineHeight: "30px" }}>
                          <Link to="/company-profile" className="d-block pt-2 ">
                            <i className="bi bi-person" />
                            Profile
                          </Link>
                        </li>
                        <li style={{ lineHeight: "30px" }}>
                          <Link to="" className="d-block w-100">
                            <i className="bi bi-key" />
                            Password
                          </Link>
                        </li>
                        <hr />
                        <li
                          style={{ lineHeight: "30px" }}
                          data-bs-toggle="modal"
                          data-bs-target="#companyDelete"
                        >
                          <Link to="" className="d-block w-100">
                            <i className="bi bi-box-arrow-right" /> Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div
        class="modal fade"
        id="companyDelete"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <img src={DeleteImg} className="w-100" alt="" />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                onClick={() => logout()}
                class="btn btn-primary"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <img src="/images/request_img.jpg" className="w-100" />
              <h3 className="text-center mt-5">
                Please first update your Profile{" "}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyHeader;
