import React, { useEffect, useRef, useState } from "react";

import CompanyHeader from "../partials/CompanyHeader";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
const APIURL = "https://job.creativewebpixel.in";

const CreateJob = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const type = localStorage.getItem("type");
    if (type !== "company") {
      navigate("/user-dashboard");
    }
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }
  }, []);







  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const [jobTitle, setJobTitle] = useState("");
  const [department, setDepartment] = useState("");
  const [jobType, setJobType] = useState("");
  const [salleryMin, setSalleryMin] = useState("");
  const [salleryMax, setSalleryMax] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [tag, setTag] = useState(Array);
  const [jobDetails, setJobDetails] = useState("");
  const [responsibilities, setResponsibilities] = useState("");
  const [qualification, setQualification] = useState("");
  const [skills, setSkills] = useState("");

  const [output, setOutput] = useState("");


  const [category, setCategory] = useState([])
  const [categoryId, setCategoryId] = useState("")

  const [year, setYear] = useState([])
  const [selectYear, setSelectYear] = useState(0)
  const [month, setMonth] = useState([])
  const [selectMonth, setSelectMonth] = useState(0)

  useEffect(() => {
    const years = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    setYear(years)
    setMonth(months)

  }, [])




  useEffect(() => {
    fetch(`${APIURL}/getAllCategory`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        setCategory(res.message)
      }
      )
  }, [])


  var handleKeyPress = (event) => {
    setOutput("");
    // if (event.key === 'Enter') {
    const name = document.getElementById("tegInput").value;
    // console.log(event.target.value)
    const value = event.target.value;
    setTag([...tag, output]);
    // setTag("");
    document.getElementById("tegInput").value = "";
    //  }
  };

  const handleRemoveTag = (indexToRemove) => {
    const newTags = tag.filter((_, index) => index !== indexToRemove);
    setTag(newTags);
  };

  const handleDescContentChange = (content) => {
    setJobDetails(content);
    console.log(content);
  };

  const handleResponseChange = (content) => {
    setResponsibilities(content);
    console.log(content);
  };

  const handleQualificChange = (content) => {
    setQualification(content);
    console.log(content);
  };

  const handleSkillsChange = (content) => {
    setSkills(content);
    console.log(content);
  };

  const handleChange = (event) => {
    setOutput(event.target.value);
  };

  const [response, setResponse] = useState("");
  const [loader, setLoader] = useState(false);
  const jobDetailsRequired = useRef(null);

  function handleSubmit(e) {
    e.preventDefault();
    const email = localStorage.getItem("email");
    const companyId = localStorage.getItem("userId");
    const experienceLvl = selectYear + "." + selectMonth

    const object = {
      jobTitle,
      department,
      jobType,
      experienceLvl,
      salleryMin,
      salleryMax,
      jobLocation,
      tag,
      jobDetails,
      responsibilities,
      qualification,
      skills,
      email,
      companyId,
      categoryId
    };



    console.log(object);

    if (jobDetails == "") {
      jobDetailsRequired.current.scrollIntoView({
        behavior: "smooth",
      });
    }

    setLoader(true);
    fetch(`${APIURL}/createJob`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(object),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        console.log("resss", res);
        setResponse(res.message);
        if (res.message == "Job Successfully Created") {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        setLoader(false);
      })
      .catch((error) => console.log(error));
  }



  return (

    <div style={{ background: "#F5F5F5" }}>
      <CompanyHeader />
      <section className="information-data border-bottom  pb-5">
        <div className="container">
          <div className="row   information-linkes">

            <div className="col-md-12  position-sticky  bg-1" style={{top:72, zIndex:1}}>
              
                <h3 className="mt-4">Company Create Job</h3>
                <p className="pb-3">Create job with details</p>
                <hr />
            </div>

            <div className="col-md-3 ">
              <div className="row position-sticky bg-1 main-head-bar ">
              </div>
              <div className="information-link position-sticky bg-1">
                <a href="#information" style={{color:"#1A2FE7"}} className="d-block mb-2" >
                Job title & department details
                </a>
                <a href="#information" style={{color:"#1A2FE7"}} className="d-block" >
                Job Details & Responsivities
                </a>
                

              </div>
            </div>
            <div className="col-md-9">
              <h4 className="mt-3 mb-4">Job Title &amp; Department Details</h4>

              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium"
                      >
                        Job Title
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setJobTitle(e.target.value)}
                        className="form-control form-in bg-1 rounded-0 "
                        id="exampleFormControlInput1"
                        placeholder="Job Title"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium "
                      >
                        Department
                      </label>
                      <select
                        className="form-select form-in bg-1 rounded-0"
                        onChange={(e) => setDepartment(e.target.value)}
                        required
                      >
                        <option value="" selected disabled hidden>
                          Department...
                        </option>
                        <option value={"Senior"}>Senior</option>
                        <option value={"Junior"}>Junior</option>
                        <option value={"Trainer"}>Trainer</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium "
                      >
                        Job Type
                      </label>
                      <select
                        className="form-select form-in bg-1 rounded-0"
                        onChange={(e) => setJobType(e.target.value)}
                        required
                      >
                        <option value="" selected disabled hidden>
                          Job Type...
                        </option>
                        <option value={"full-time"}>Full Time</option>
                        <option value={"half-time"}>Half Time</option>
                        <option value={"freelancer"}>Freelancer</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">


                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label fw-medium "
                    >
                      Experience Level
                    </label>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className=" col-5">
                        <select
                          type="number"
                          className="form-select form-in bg-1 rounded-0"
                          onChange={(e) => setSelectYear(e.target.value)}
                          aria-label="Default select example"
                          placeholder="Year"

                        >
                          <option value={0} disabled selected hidden>Select Year...</option>
                          {year.map((item) => {
                            return (
                              <option >{item}</option>
                            )
                          })}

                        </select>
                      </div>
                      <div className="px-3 d-flex ">to</div>
                      <div className="col-5">
                        <select
                          type="number"
                          className="form-select form-in bg-1 rounded-0"
                          onChange={(e) => setSelectMonth(e.target.value)}
                          aria-label="Default select example"
                          placeholder="Year"

                        >
                          <option value={0} disabled selected hidden>Select Month...</option>
                          {month.map((item) => {
                            return (
                              <option >{item}</option>
                            )
                          })}

                        </select>
                      </div>

                    </div>




                    {/* <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium "
                      >
                        Experience Level
                      </label>
                      <input
                        className="form-control form-in bg-1 rounded-0"
                        onChange={(e) => setExperienceLvl(e.target.value)}
                        aria-label="Default select example"
                        required
                      />
                    </div> */}
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label fw-medium "
                    >
                      Salery Range
                    </label>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className=" col-5">
                        <input
                          type="number"
                          className="form-control form-in bg-1 rounded-0"
                          onChange={(e) => setSalleryMin(e.target.value)}
                          aria-label="Default select example"
                          required
                        />
                      </div>
                      <div className="px-3 d-flex ">to</div>
                      <div className="col-5">
                        <input
                          type="number"
                          className="form-control form-in bg-1 rounded-0"
                          onChange={(e) => setSalleryMax(e.target.value)}
                          aria-label="Default select example"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 mt-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium"
                      >
                        Job Location
                      </label>
                      <input
                        type="text"
                        className="form-control form-in bg-1 rounded-0"
                        onChange={(e) => setJobLocation(e.target.value)}
                        aria-label="Default select example"
                        required
                      />
                    </div>
                  </div>

                
                  <div className="col-md-6 mt-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium"
                      >
                        Category
                      </label>
                      <select
                        type="text"
                        onChange={(e) => setCategoryId(e.target.value)}
                        className="form-select form-in bg-1 rounded-0 "
                        placeholder="Job Title"
                        required
                      >
                        <option value="" selected hidden disabled>Select Category...</option>
                        {category.map((item) => {
                          console.log(item)
                          return (
                            <option value={item.id} >{item.title}</option>


                          )
                        })}


                      </select>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label fw-medium "
                    >
                      Skills
                    </label>
                    <div style={{ position: "relative" }}>
                      {tag == "" ? (
                        <input
                          type="text"
                          className="form-select form-in bg-1 rounded-0"
                          id="tegInput"
                          value={output}
                          onChange={handleChange}
                          aria-label="Default select example"
                          required
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-select form-in bg-1 rounded-0"
                          id="tegInput"
                          value={output}
                          onChange={handleChange}
                          aria-label="Default select example"
                        />
                      )}

                      {output == "" ? (
                        <span
                          className="btn bg-secondary text-white"
                          style={{
                            position: "absolute",
                            cursor: "not-allowed",
                            top: "6px",
                            right: "6px",
                          }}
                        >
                          add
                        </span>
                      ) : (
                        <span
                          className="btn bg-primary text-white"
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "6px",
                          }}
                          onClick={handleKeyPress}
                        >
                          add
                        </span>
                      )}

                    </div>

                  </div>
                
                  <div className="col-md-12 ">
                    {tag.map((tag, index) => {
                      console.log(index);
                      return (
                        <>
                          <div className="tag btn bg-primary pe-4 me-3 my-2 text-white position-relative">
                            <span>{tag}</span>
                            <button
                              type="button"
                              onClick={() => handleRemoveTag(index)}
                              class="border-0 bg-transparent position-absolute  top-0 text-white fs-6"
                              style={{ right: "4px" }}
                            >
                              <i class="fa-solid fa-xmark"></i>{" "}
                            </button>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  {/* <div class="col-md-3">
                          
                      </div> */}
                  {/* ========== */}
                  <div className="col-md-12">
                    <h5 className="my-3" id="summary">
                      Job Details &amp; Responsivities
                    </h5>
                  </div>
                  <div className="col-md-12 mb-5">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label fw-medium "
                    >
                      Description
                    </label>
                    <ReactQuill
                      theme="snow"
                      ref={jobDetailsRequired}
                      modules={modules}
                      formats={formats}
                      placeholder="write your content ...."
                      onChange={handleDescContentChange}
                      style={{ height: "220px" }}
                    ></ReactQuill>
                  </div>

                  <div className="col-md-12 mt-3 mb-5">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label fw-medium "
                    >
                      Responsibilities
                    </label>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      placeholder="write your content ...."
                      onChange={handleResponseChange}
                      style={{ height: "220px" }}
                      required
                    ></ReactQuill>
                  </div>
                  <div className="col-md-12 mt-3 mb-5">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label fw-medium "
                    >
                      Qualification
                    </label>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      placeholder="write your content ...."
                      onChange={handleQualificChange}
                      style={{ height: "220px" }}
                      required
                    ></ReactQuill>
                  </div>

                  <div className="col-md-12 mt-3 mb-5">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label fw-medium "
                    >
                      Skills
                    </label>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      placeholder="write your content ...."
                      onChange={handleSkillsChange}
                      style={{ height: "220px" }}
                      required
                    ></ReactQuill>
                  </div>
                  <div className="col-md-12 ">
                  <hr className="mb-3 mt-5" />
                  </div>
                  <div className="form-end">
                    <div className="row">
                      <div className="col-md-5">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.&nbsp;
                        </p>
                      </div>
                      <div className="col-md-7 text-end ">
                        <button className=" form-ebtn1  border-0 rounded-0 me-2  ">
                          {" "}
                          Cancel
                        </button>
                        <button className=" form-ebtn2  border-0 rounded-0 me-2 ">
                          {" "}
                          Preview as a Candidate
                        </button>
                        {loader ? (
                          <button
                            type="submit"
                            className=" form-ebtn3  border-0 rounded-0 mb-2 position-relative"
                            disabled
                          >
                            <span className="opacity-0">
                              {" "}
                              Save &amp; Publish
                            </span>
                            <div
                              className="spinner-border position-absolute"
                              style={{
                                width: "25px",
                                height: "25px",
                                right: "60px",
                              }}
                              role="status"
                            >
                              <span class="visually-hidden ">Loading...</span>
                            </div>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className=" form-ebtn3  border-0 rounded-0 mb-2 "
                          >
                            {" "}
                            Save &amp; Publish
                          </button>
                        )}
                        <h5 style={{ color: "#FFCD4D" }}> {response}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </div>
  );
};

export default CreateJob;
