import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const ForgetPassword = () => {


  // const navigate = useNavigate();



  const [forgetmessage, setforgetMessage] = useState("");
  const [email, setEmail] = useState("");


  const [loader, seLoader] = useState(false);



  const forgetSubmit = (event) => {
    event.preventDefault();



    // add entity - POST
    // e.preventDefault();
    // creates entity

    const object = {
      email: email
    }
    console.log(object)

    if (email.trim() == "") {
      return;
    } else {
      seLoader(true);
      fetch("https://job.creativewebpixel.in/fogetPasswordReq", {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(object),
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((json) => {



          console.log(json.message)
          setforgetMessage(json.message);



          seLoader(false);
        })
        .catch((err) => {
          // console.log(err);
        });


    }
  };




  return (
    <div>
      <div className="modal-body p-0">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-5  p-0 position-relative hide4">
              <img src="images/img/login.jpg"  style={{ transform: "rotateY(180deg)" }} className="img-fluid " />
              <div className="bg-color7 w-75 m-auto ps-5 py-4 position-absolute position2 hide4">
                <button className="bg-color6 border-0 fs-6 p-2 rounded-2 text-white">
                  {" "}
                  <i className="bi bi-hand-thumbs-up-fill text-color4 pe-2" />
                  Top Notch Stock Resources
                </button>
                <p className="pt-3 text-white w-75">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Integer mattis quis est ut pulvinar. Quisque semper.
                </p>
              </div>
            </div>
            <div className="col-md-7 px-5 mb-3">
              <form
                onSubmit={forgetSubmit}
                className=" form-login"
              >
                <div className="pb-4 text-center">
                  <h1 className="fw-semibold">Welcome</h1>
                  <p className="text-black">Forget your Password</p>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email
                  </label>
                  <input
                    onChange={(event) => setEmail(event.target.value)}
                    type="email"
                    className="form-control rounded-0 py-3"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Email"
                    required
                  />
                </div>



                {loader ? (
                  <button
                    type="submit"
                    className="btn bg-color1 rounded-0 btn2 py-2 hire border-0 position-relative"
                    disabled
                  >
                    {" "}
                    <div
                      className="spinner-border"
                      style={{ width: "25px", height: "25px", right: "150px" }}
                      role="status"
                    >
                      <span class="visually-hidden ">Loading...</span>
                    </div>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn bg-color1 rounded-0 btn2 py-2 hire "
                  >
                    Send Mail
                  </button>
                )}

                {forgetmessage == "Message has been sent" ? (
                  <p
                    style={{ color: "#FFCD4D", textAlign: "center", margin: 0 }}
                  >
                    {forgetmessage}
                  </p>
                ) : (
                  <p style={{ color: "red", textAlign: "center", margin: 0 }}>
                    {forgetmessage}
                  </p>
                )}

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword
