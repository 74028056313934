import React from 'react'
import Header from '../partials/UserHeader'
import { Link } from 'react-router-dom'

const Online = () => {
  return (
    <div>
           <Header/>
           <div className='bg-1'>
  <section className="find-job border-bottom mt-5 pb-5">
    <div className="container">
      <div className="row justify-content-around">
        <div className="col-md-4">
          <img src="images/online-page-ing.png" alt />
        </div>
        <div className="col-md-7">
          <p className="mt-5 clr-r">Do not exit, otherwise refresh this project.</p>
          <h4 className=" fs-3">Creative Web Pixel Assessments</h4>
          <p className="text-black fw-normal py-1">Here are some key points to understand what you do in this
            design.</p>
          <p><span className="text-primary pe-3"><img src="images/cheak-icon.png" alt /></span>Clean, simple
            design for clear information.</p>
          <p><span className="text-primary pe-3"><img src="images/cheak-icon.png" alt /></span>Easy, step-by-step
            layout for clarity.</p>
          <p><span className="text-primary pe-3"><img src="images/cheak-icon.png" alt /></span>Eye-catching
            visuals with brief text.</p>
          <p><span className="text-primary pe-3"><img src="images/cheak-icon.png" alt /></span>Smooth scrolling,
            easy-to-read sections.</p>
          <p><span className="text-primary pe-3"><img src="images/cheak-icon.png" alt /></span>Organized grid
            layout for easy navigation.</p>
          <hr className="mt-5" />
          <div className="d-flex justify-content-between align-items-center">
            <p className="m-0">Before you start read <Link to="" className="text">terms conditions</Link></p>
            <div className="buttons-assment">
              <button className=" rounded-0  cancel-btn me-3">Cancel</button>
              <button className=" rounded-0 px-5 continue-btn">Start</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="footer-bar footer-bg ">
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-end">
            <p className="m-0 p-0"><span className="pe-3">Terms and Conditions</span><span>Privacy and Policy</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </section>
</div>

    </div>
  )
}

export default Online
