import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import UserHeader from "../partials/UserHeader";
import CompanyHeader from "../partials/CompanyHeader";
import moment from "moment";

const APIURL = "https://job.creativewebpixel.in";

const CandidateDetail = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    const type = localStorage.getItem("type");
    if (type !== "company") {
      navigate("/user-dashboard");
    }
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }
  }, []);

  const [jobDetails, setJobDetails] = useState([]);
  const [jobTag, setJobTag] = useState([]);
  const [professional, setProfessional] = useState([]);
  const [education, setEducation] = useState([]);

  useEffect(() => {
    fetch(`${APIURL}/getAllCandidate`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response.message);
        const filterData = response.message.filter((item) => {
          return item.id == id;
        });
        setJobDetails(filterData[0]);
        console.log(filterData[0]);
        setJobTag(JSON.parse(filterData[0].skillsName));
        setProfessional(JSON.parse(filterData[0].professional));
        setEducation(JSON.parse(filterData[0].education));
        console.log(JSON.parse(filterData[0].professional));
        console.log(JSON.parse(filterData[0].education));
      });
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const [alreadyModalShow, setAlreadyModalShow] = useState(false);
  const [response, setResponse] = useState("");

  const quickApply = () => {
    const companyId = localStorage.getItem("userId");

    const object = {
      companyId: companyId,
      userId: id,
    };
    console.log(object);

    setModalOpen(false);
    setAlreadyModalShow(false);

    fetch(`${APIURL}/hireCandidate`, {
      method: "POST",
      body: JSON.stringify(object),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        setResponse(response.message);
        // if (response.message == "successfull hire candidate") {

        //   setModalOpen(true)
        // }
        // if (response.message == "alredy applyed on this job") {
        //   setModalOpen(true)
        // }
      });
  };

  const [PortfolioData, setPortfolioData] = useState([]);
  const id3 = id;

  useEffect(() => {
    fetch(`${APIURL}/getAllProject/${id3}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setPortfolioData(response.message.sort().reverse());
        console.log(response.message);
      });
  }, []);
  return (
    <div className="bg-1">
      <CompanyHeader />
      {/* -=======================section=1==============================  */}
      <section className="bg-white mt-5">
        <div className="container">
          <div className="row d-flex justify-content-between align-items-center  arlene ">
            <div className="col-md-8">
              <div className="row jobdetail align-items-center">
                <div className=" col-md-2">
                  {jobDetails.avtar == "" || jobDetails.avtar == null ? (
                    <img
                      style={{
                        borderRadius: "100%",
                        height: "100px",
                        width: "100px",
                        objectFit: "cover",
                        border: "solid 3px #dedede",
                      }}
                      src="/images/profile.jpg"
                      alt=""
                      className=""
                    />
                  ) : (
                    <img
                      src={APIURL + "/img/" + jobDetails.avtar}
                      className=""
                      style={{
                        borderRadius: "100%",
                        height: "100px",
                        width: "100px",
                        objectFit: "cover",
                        border: "solid 3px #dedede",
                      }}
                    />
                  )}
                </div>
                <div className="col-md-10">
                  <h4 className="m-0">{jobDetails.fullname}</h4>
                  <div className="d-flex align-items-center pt-2 flex-wrap">
                    <p className="m-0">{jobDetails.designation}</p>
                    <p className="m-0  stopwatch">
                      <i className="bi bi-geo-alt" />
                      {jobDetails.country}/{jobDetails.city}
                    </p>
                  </div>
                  <div className="d-flex align-items-center pt-2 flex-wrap">
                    {jobTag.slice(0, 3).map((item) => {
                      console.log(item);
                      return (
                        <button
                          type="button"
                          className="job-btn  py-1 border-0 me-2 my-2 rounded-pill"
                        >
                          {item}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-end mb-3 arlene1">
              <p className="m-0">
                <a href="#" className="a-tag text-black">
                  {jobDetails.phoneNo}
                </a>
              </p>
              <p className="m-0">
                <a href="#" className="a-tag text-black">
                  {jobDetails.email}
                </a>
              </p>
              <p className="d-flex m-0 arlene2">
                <a href={jobDetails.twitter} className=" text-color2">
                  <i className="bi bi-twitter fs-5" />
                </a>
                <a href={jobDetails.facebook} className=" text-color2">
                  <i className="bi bi-facebook ps-3 fs-5" />
                </a>
                <a href={jobDetails.instagram} className=" text-color2">
                  <i className="bi bi-instagram ps-3 fs-5" />
                </a>
              </p>
              <div className="d-flex arlene2">
                <button
                  className="bg-color1 border-0 px-2  py-2 mt-3  me-3"
                  data-bs-toggle="modal"
                  data-bs-target="#hireCandidate"
                  onClick={() => quickApply()}
                >
                  Want to Hire
                </button>
                <button
                  className="bg-color2 border-0 px-2  py-2 mt-3  "
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  data-bs-whatever="@mdo"
                >
                  Download CV
                </button>
              </div>
              <div
                className=" modal fade"
                id="hireCandidate"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1
                        className="modal-title fs-5"
                        id="exampleModalLabel"
                      ></h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body">
                      <img
                        src="/images/img/Product_quality.gif"
                        className="w-100"
                      />
                      {response == "successfull hire candidate" ? (
                        <h3 className="text-center">Hired Successfull</h3>
                      ) : response == "alredy applyed on this job" ? (
                        <h3 className="text-center">Already Hired</h3>
                      ) : (
                        <h3 className="text-center">
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*-=======================section=2============================== */}
      <section>
        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-8 col-md-12 ">
              <div className=" ">
                <h4>About Candidates </h4>
                <p
                  className="m-0 pt-3 "
                  style={{ overflowWrap: "break-word  " }}
                >
                  {jobDetails.description}
                </p>
              </div>
              <div className="pt-5  ">
                <h4>Work &amp; Experience </h4>
                {professional.map((item) => {
                  console.log(item);
                  return (
                    <div className="d-flex pt-3 position-relative mb-3">
                      {/* {item.companyName == "" &&
                      item.currentWork == "" &&
                      item.employmentType == "" &&
                      item.enDate == "" &&
                      item.location == "" &&
                      item.profileHeadline == "" &&
                      item.startDate == "" &&
                      item.title == "" ? (
                        ""
                      ) : (
                        <div className="boder11  " />
                      )} */}
                      <div className=" sarkl1 border-start">
                        <div className="d-flex align-items-center pt-1 ">
                          <h5 className="p-0 m-0">{item.title}</h5>
                          {item.startDate == "" || item.enDate == "" ? (
                            <p className="m-0 ms-3 bg-color4 px-2 rounded-pill ">
                              {moment(item.startDate).format("YYYY") +
                                "-" +
                                moment(item.enDate).format("YYYY")}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="text-color pt-2 p-0 m-0">
                          {item.companyName}
                        </p>
                        <p className="pt-2 p-0 m-0">{item.profileHeadline}</p>
                      </div>
                      {item.companyName == "" &&
                      item.currentWork == "" &&
                      item.employmentType == "" &&
                      item.enDate == "" &&
                      item.location == "" &&
                      item.profileHeadline == "" &&
                      item.startDate == "" &&
                      item.title == "" ? (
                        ""
                      ) : (
                        <div className=" sarkl position-absolute " />
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="pt-5  ">
                <h4>Education </h4>
                {education.map((item, i) => {
                  console.log(item);
                  return (
                    <div className="d-flex pt-3 position-relative mb-3">
                      {/* {item.degree == "" &&
                        item.activities == false &&
                        item.educationDescription == "" &&
                        item.fieldOfStudy == "" &&
                        item.grade == "" &&
                        item.school == "" &&
                        item.studyEndDate == "" &&
                        item.studyStartDate == "" ? (
                        ""
                      ) : (
                        <div className="boder11  " />
                      )} */}

                      <div className=" sarkl1 border-start">
                        <div className="d-flex align-items-center pt-1 ">
                          <h5 className="p-0 m-0">{item.degree}</h5>
                          {item.studyStartDate == "" &&
                          item.studyEndDate == "" ? (
                            <p className="m-0 ms-3 bg-color4 px-2 rounded-pill ">
                              {moment(item.studyStartDate).format("YYYY") +
                                "-" +
                                moment(item.studyEndDate).format("YYYY")}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="text-color pt-2 p-0 m-0">{item.school}</p>
                        <p className="pt-2 p-0 m-0">
                          {item.educationDescription}
                        </p>
                      </div>

                      {item.degree == "" &&
                      item.activities == "" &&
                      item.educationDescription == "" &&
                      item.fieldOfStudy == "" &&
                      item.grade == "" &&
                      item.school == "" &&
                      item.studyEndDate == "" &&
                      item.studyStartDate == "" ? (
                        ""
                      ) : (
                        <div className=" sarkl position-absolute " />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-lg-4 ">
              <div className="bg-white p-4">
                <div className="d-flex">
                  <div className>
                    <i className="bi bi-calendar text-color fs-5" />
                  </div>
                  <div className="ps-3">
                    <p className="m-0 p-0 fw-bold">Experience:</p>
                    <p className="m-0 p-0 text-color2 pt-2">
                      {jobDetails.experience}
                    </p>{" "}
                  </div>
                </div>
                <div className="d-flex pt-3">
                  <div className>
                    <i className="bi bi-hourglass text-color fs-5" />
                  </div>
                  <div className="ps-3">
                    <p className="m-0 p-0 fw-bold">Date of Birth:</p>
                    <p className="m-0 p-0 text-color2 pt-2">
                      {jobDetails.dob}
                    </p>{" "}
                  </div>
                </div>
                <div className="d-flex pt-3">
                  <div className>
                    <i className="bi bi-currency-rupee text-color fs-5" />
                  </div>
                  <div className="ps-3">
                    <p className="m-0 p-0 fw-bold">Current Salary:</p>
                    <p className="m-0 p-0 text-color2 pt-2">
                      {jobDetails.currentSallery}
                    </p>{" "}
                  </div>
                </div>
                <div className="d-flex pt-3">
                  <div className>
                    <i className="bi bi-currency-rupee text-color fs-5" />
                  </div>
                  <div className="ps-3">
                    <p className="m-0 p-0 fw-bold">Expected Salary:</p>
                    <p className="m-0 p-0 text-color2 pt-2">
                      {jobDetails.expectedSallery}
                    </p>{" "}
                  </div>
                </div>
                <div className="d-flex pt-3">
                  <div className>
                    <i className="bi bi-person text-color fs-5" />
                  </div>
                  <div className="ps-3">
                    <p className="m-0 p-0 fw-bold">Gender:</p>
                    <p className="m-0 p-0 text-color2 pt-2">
                      {jobDetails.gender}
                    </p>{" "}
                  </div>
                </div>
                <div className="d-flex pt-3">
                  <div className>
                    <i className="bi bi-translate text-color fs-5" />
                  </div>
                  <div className="ps-3">
                    <p className="m-0 p-0 fw-bold">Language:</p>
                    <p className="m-0 p-0 text-color2 pt-2">
                      {jobDetails.language}
                    </p>{" "}
                  </div>
                </div>
                {/* <div className="d-flex pt-3">
                  <div className><i className="bi bi-mortarboard text-color fs-5" /></div>
                  <div className="ps-3">
                    <p className="m-0 p-0 fw-bold">Education Level:</p>
                    <p className="m-0 p-0 text-color2 pt-2">Master Degree</p> </div>
                </div> */}
              </div>

              <div className="bg-white p-4 mt-5">
                <div className="d-flex">
                  <div className="">
                    <p className="m-0 p-0 fw-bold">Professional Skills:</p>
                    <p className="m-0 p-0 text-color2 pt-2">
                      <div className="d-flex align-items-center pt-2 flex-wrap">
                        {jobTag.map((item) => {
                          console.log(item);
                          return (
                            <button
                              type="button"
                              className="job-btn py-1 border-0 me-2 my-2 rounded-pill"
                            >
                              {item}
                            </button>
                          );
                        })}
                      </div>
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className=" pt-5">
              <h4>Portfolio </h4>
              <div className="row pt-4">
                {PortfolioData.map((item) => {
                  return (
                    <div className="col-md-4 mb-3">
                      <div className="portfolio-box  position-relative ">
                        <img
                          className="w-100"
                          style={{ height: "200px", objectFit: "cover" }}
                          src={APIURL + "/img/" + item.image}
                          alt
                        />
                        <div className="text-center my-5 position-absolute top1 w-100">
                          <Link
                            to={item.url}
                            className="a-tag text-black  bg-color1 py-2 px-4"
                          >
                            View Project
                          </Link>
                        </div>
                      </div>
                      <h5 className="text-center fw-semibold w-75 m-auto pt-3">
                        {item.projectName}
                      </h5>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CandidateDetail;
