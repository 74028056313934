import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import UserHeader from '../partials/UserHeader'
import CompanyHeader from '../partials/CompanyHeader'

const APIURL = "https://job.creativewebpixel.in"

const Portfolio = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const type = localStorage.getItem("type");
    if (type !== "student") {
      navigate("/company-dashboard")
    }
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }
  }, []);



  const [PortfolioData, setPortfolioData] = useState([])
  const id3 = localStorage.getItem("userId")

  useEffect(() => {
    fetch(`${APIURL}/getAllProject/${id3}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    }).then((res) => res.json()).then((response) => {

      setPortfolioData(response.message.sort().reverse())
      console.log(response.message)
    })
  }, [])
  return (
    <>
      <UserHeader />
      <section className="mt-5">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className><h1 className="m-0">Portfolio</h1></div>
            <div className="d-flex align-items-center position-relative">
              <Link to="/add-portfolio">
                <button className=' form-ebtn3  border-0 rounded-0 mb-2'>Add</button>
              </Link>
            </div>
          </div>
          <div className="   overflow-x-auto pt-5 table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Project Name</th>
                  <th scope="col">Image</th>
                  <th scope="col">Project Url</th>
                  <th scope="col">Team Member</th>
                  <th scope="col">Your Role</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Description</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {PortfolioData.map((item, i) => {
                  return (
                    <tr>
                      <th scope="row">{i + 1}</th>
                      <td>{item.projectName}</td>
                      <td className='project-img'><img src={APIURL+"/img/"+item.image} alt="" /></td>
                      <td className='project-link'><Link style={{maxWidth: "100px"}} title={item.url} className='d-inline-block text-truncate' to={item.url}>{item.url}</Link></td>
                      <td>{item.team}</td>
                      <td>{item.roll}</td>
                      <td>{item.duration}</td>
                      <td>{item.description}</td>
                      <td>

                        <button className='bg-transparent border-0' data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots" /></button>

                        <ul class="dropdown-menu">
                          <li><Link class="dropdown-item" ><i class="fa-regular fa-eye"></i> View</Link></li>
                          <li><Link class="dropdown-item" to="#"><i class="fa-regular fa-pen-to-square"></i> Edit</Link></li>
                          <li><Link class="dropdown-item" to="#"><i class="bi bi-trash"></i> Delete</Link></li>
                        </ul>

                      </td>
                    </tr>
                  )
                })}

              </tbody>
            </table>
            <div className="d-flex justify-content-between align-items-center">

              <div className="align-items-center">
                <Link to="#" className="pe-2 align-items-center text-color"><i className="bi bi-caret-left " /> previous</Link>
                <div className="btn-group  align-items-center" role="group" aria-label="First group">
                  <button type="button" className="btn btn-outline-secondary rounded-0 me-3">1</button>
                  <p className="m-0 me-2">of</p>
                  <button type="button" className="btn btn-outline-secondary border-0 rounded-0">10</button>
                </div>
                <Link to="#" className="pe-2 align-items-center text-color"> Next <i className="bi bi-caret-right" /></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  )
}

export default Portfolio
