import React from 'react'
import Header from '../partials/UserHeader'
import { Link } from 'react-router-dom'

const Result = () => {
  return (
    <div>
        <div>
        <Header/>
  <section className="find-job border-bottom mt-5 pb-5">
    <div className="container">
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-md-7 text-center score  p-3">
          <div className="card rounded-0 border-0 ">
            <div className="card-body">
              <img src="images/Component -score.png" alt />
              <h3 className="mt-4 clr-g">80/100</h3>
              <p className="fw-semibold">Score</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas quis leo nulla. Sed
                cursus leo ut fringilla.</p>
              <div className="buttons-assment mt-4">
                <button className=" rounded-0   cancel-btn me-3">Back to Home</button>
                <button className=" rounded-0 border-0 continue-btn">Next Task</button>
              </div>
              <p className="fw-semibold mt-3">Check your answers</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="footer-bar footer-bg ">
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-end">
            <p className="m-0 p-0"><span className="pe-3">Terms and Conditions</span><span>Privacy and Policy</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </section>
</div>

    </div>
  )
}

export default Result
