import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import UserHeader from '../partials/UserHeader'
import CompanyHeader from '../partials/CompanyHeader'
import ReactPaginate from 'react-paginate'

const APIURL = "https://job.creativewebpixel.in"

const PER_PAGE = 21

const OurListedJob = () => {
  const navigate = useNavigate()
  useEffect(() => {
      const type = localStorage.getItem("type")
         if (type !== "company") {
       navigate("/user-dashboard");
    }
 const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }

  }, [])

  const [ListedJobs, setListedJobs] = useState([])
  const userId = localStorage.getItem("userId")

  useEffect(() => {
    fetch(`${APIURL}/ourListedJob`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `${userId}`
      }
    }).then((res) => res.json()).then((response) => {

      setListedJobs(response.message.sort().reverse())
      console.log(response.message)
    })
  }, [])


  const [currentPage, setCurrentPage] = useState(0);

  function handleClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
    // window.scrollTo(0, 0)

  }

  const offset = currentPage * PER_PAGE;

  const pageCount = Math.ceil(ListedJobs.length / PER_PAGE);
  return (
    <>
      <CompanyHeader />
      <section className="mt-5">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className><h1 className="m-0">Our Listed Jobs</h1></div>
            <div className="d-flex align-items-center position-relative">
              <input type="email" className="form-control me-3 ps-4" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Search..." />
              <p className="d-flex align-items-center m-0 border border-2 px-3 py-1"><i className="bi bi-plus-lg pe-2" />Filter</p>
              <i className="bi bi-search position-absolute top-0 start-0 pt-2 ps-1" />
            </div>
          </div>
          <div className="   overflow-x-auto pt-5">
            <table className="table ">
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Job Title</th>
                  <th scope="col">Department</th>
                  <th scope="col">Type</th>
                  <th scope="col">Location</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {ListedJobs.slice(offset, offset + PER_PAGE).map((item, i) => {
                  return (
                    <tr>
                      <th scope="row">{i + 1}</th>
                      <td>{item.jobTitle}</td>
                      <td>{item.department}</td>
                      <td>{item.jobType}</td>
                      <td>{item.jobLocation}</td>
                      <td>
                        
                        <button className='bg-transparent border-0' data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots" /></button>

                        <ul class="dropdown-menu">
                          <li><Link class="dropdown-item" to={`/my-job-detail/${item.id}`}><i class="fa-regular fa-eye"></i> View</Link></li>
                          <li><Link class="dropdown-item" to={`/edit-job/${item.id}`}><i class="fa-regular fa-pen-to-square"></i> Edit</Link></li>
                          <li><Link class="dropdown-item" to="#"><i class="bi bi-trash"></i> Delete</Link></li>
                        </ul>

                      </td>
                    </tr>
                  )
                })}

              </tbody>
            </table>
            <div className="pagination fwmpag">
                  <ReactPaginate
                    breakLabel=". . ."
                    previousLabel={"◁ prev"}
                    nextLabel={"next ▷"}
                    pageCount={pageCount}
                    onPageChange={handleClick}
                    pageRangeDisplayed={1}
                    containerClassName={"pagination page"}
                    renderOnZeroPageCount={null}
                    previousLinkClassName={"prevposts-link"}
                    nextLinkClassName={"nextposts-link"}
                    disabledClassName={"pagination__link--disabled "}
                    activeClassName={"pagination__link--active"}
                    marginPagesDisplayed={1}
                  // renderOnZeroPageCount={null}
                  />

                </div>
          </div>
        </div>
      </section>
    </>

  )
}

export default OurListedJob
