import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserHeader from "../partials/UserHeader";
import CompanyHeader from "../partials/CompanyHeader";

const Subscription = () => {
  const [type, setType] = useState("");

  useEffect(() => {
    const type = localStorage.getItem("type");
    setType(type);
  }, []);
  return (
    <div>
      {type == "student" ? <UserHeader /> : <CompanyHeader />}

      <section className="subscription-page  mt-5 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-8">
              <h4 className="fw-bold">My subscription</h4>
              <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <div className="current-plan">
                <p className="fw-semibold text-black  mb-2">Current Plan</p>
                <div className=" border-0 rounded-0">
                  <div className="card-body">
                    <div className=" d-flex justify-content-between subsion m-0 p-0">
                      <p className="m-0 p-0">
                        <span className="fw-semibold text-black">
                          Creative Web Pixel
                        </span>
                        <span className="clr-g fw-semibold ps-2">
                          <img
                            src="images/crown 1.png"
                            className="img-fluid"
                            alt
                          />
                          Premium Plan
                        </span>
                      </p>
                      <a href="#" className="fw-medium">
                        Pause subscription
                      </a>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-8">
                        <p className="mb-2">
                          Next payment 1500.00 INR on&nbsp;Jan 31st,
                          2025.&nbsp;(VAT may apply) Automatic renewal every
                          year
                        </p>
                      </div>
                      <div className="col-md-4 text-end">
                        <a href="#" className="fw-medium clr-b">
                          Cancel Plan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="current-plan mt-4">
                <p className="fw-semibold text-black  mb-2">
                  Billing information
                </p>
                <div className=" border-0 rounded-0">
                  <div className="card-body">
                    <div className=" d-flex justify-content-between align-items-center m-0 p-0">
                      <div className>
                        <p className="m-0 p-0">
                          <span className="fw-semibold text-black">
                            Ashish Vaishnav
                          </span>
                        </p>
                        <p className="mb-2">ashishvaishnavtest.com</p>
                      </div>
                      <a href="#" className="fw-medium clr-b">
                        Download Invoice
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Subscription;
