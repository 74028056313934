import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import UserHeader from "../partials/UserHeader";
import axios from "axios";
import sallery from "../../css/images/money-bag 1.png";
import locationImg from "../../css/images/locationicon.png";
import no_img from "../../css/images/profile.jpg";
import ReactPaginate from "react-paginate";

const APIURL = "https://job.creativewebpixel.in";

const PER_PAGE = 3

const JobDetail = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    const type = localStorage.getItem("type");
    if (type !== "student") {
      navigate("/company-dashboard");
    }
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }
  }, []);

  const [jobDetails, setJobDetails] = useState([]);
  const [jobTag, setJobTag] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    fetch(`${APIURL}/getAllJobs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const filterData = response.message.filter((item) => {
          return item.id == id;
        });
        setJobDetails(filterData[0]);
        console.log(filterData[0]);
        setJobTag(JSON.parse(filterData[0].tag));
        const id2 = filterData[0].categoryId;

        fetch(`${APIURL}/getAllRelatedJob/${id2}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((res) => res.json())
          .then((response) => {
            setCategoryData(response.message.sort().reverse());
            console.log(response);
          });
      });
  }, []);

  const [userStatus, setUserStatus] = useState("");

  useEffect(() => {
    const email = localStorage.getItem("email");

    axios({
      url: `${APIURL}/userDetails`,
      method: "GET",

      headers: {
        Authorization: `${email}`,
      },
    })
      .then((response) => response.data)
      .then((res) => {
        console.log("userdetails", res.message[0]);
        setUserStatus(res.message[0].status);
      });
  }, []);

  const [modalShow, setModalShow] = useState(false);
  const [response, setResponse] = useState("");

  const quickApply = () => {
    const userId = localStorage.getItem("userId");
    // const companyId = localStorage.getItem("email")
    console.log("", jobDetails.companyDetails.id);
    const object = {
      userId: userId,
      companyId: jobDetails.companyDetails.id,
      jobId: id,
    };
    console.log(object);
    setModalShow(false);

    fetch(`${APIURL}/applyJob`, {
      method: "POST",
      body: JSON.stringify(object),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        setResponse(response.message);
        // if (response.message == "successfull job applyed") {
        //   setModalShow(true)
        // }
      });
  };

  const [currentPage, setCurrentPage] = useState(0);

  function handleClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
    // window.scrollTo(0, 0)

  }

  const offset = currentPage * PER_PAGE;

  const pageCount = Math.ceil(categoryData.length / PER_PAGE);

  return (
    <div className="bg-1 ">
      <UserHeader />
      {/*-=======================section=1============================== */}
      <section className="bg-white mt-3">
        <div className="container">
          <div className="row  align-items-center justify-content-between  arlene ">
            <div className="col-md-8">
              <div className="row jobdetail align-items-center">
                <div className=" col-md-2 text-center">
                  {jobDetails.companyDetails?.companyLogo == "" || jobDetails.companyDetails?.companyLogo == null ? (
                    <img
                      style={{
                        borderRadius: "100%",
                        height: "100px",
                        width: "100px",
                        objectFit: "cover",
                        border: "solid 3px #dedede"
                      }}
                      src="/images/profile.jpg"
                      alt=""
                    />
                  ) : (
                    <img
                      src={
                        APIURL + "/img/" + jobDetails.companyDetails?.companyLogo
                      }
                      style={{
                        borderRadius: "100%",
                        height: "100px",
                        width: "100px",
                        objectFit: "cover",
                        border: "solid 3px #dedede"
                      }}
                      alt=""
                    />
                  )}
                </div>
                <div className=" col-md-10">
                  <h4 className="m-0 fw-semibold">{jobDetails.jobTitle}</h4>
                  <div className="d-flex align-items-center pt-2 flex-wrap">
                    <span className="money">
                      <img src="/images/money-bag 1.png" alt />
                    </span>
                    &nbsp;
                    <span className="fs-16">
                      {jobDetails.salleryMin}- {jobDetails.salleryMax}
                    </span>
                   
                    &nbsp;&nbsp;{" "}
                    <p className="m-0  stopwatch stopwatch2">
                      <i className="bi bi-stopwatch" />
                      &nbsp;
                      {jobDetails.jobType == "full-time"
                        ? "Full Time"
                        : jobDetails.jobType == "half-time"
                          ? "Half Time"
                          : jobDetails.jobType == "freelancer"
                            ? "Freelancer"
                            : ""}
                    </p>
                  </div>
              
                    <p className="m-0 mt-2 ">
                      <i className="bi bi-geo-alt" /> &nbsp;
                      {jobDetails.jobLocation}
                    </p>  
                  <div className="d-flex align-items-center pt-2 flex-wrap">
                    {jobTag.map((item) => {
                      console.log(item);
                      return (
                        <button
                          type="button"
                          className="job-btn border-0 rounded-pill m-2"
                        >
                          {item}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 justify-content-end arlene1 d-flex align-items-center">
              <div className>
                <button
                  type="button"
                  className="bg-color1 border-0 py-2 px-2   hire me-3"
                  data-bs-toggle="modal"
                  data-bs-target="#appliedCandidate"
                  onClick={() => quickApply()}
                >
                  Quick Apply
                </button>
              </div>
              <div>
                <button className="border-0 px-3 bg-black me-3 ">
                  <a href="#">
                    <i className="bi bi-share-fill fs-4 text-white" />
                  </a>
                </button>
              </div>
            </div>

            <div
              className=" modal fade"
              id="appliedCandidate"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1
                      className="modal-title fs-5"
                      id="exampleModalLabel"
                    ></h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <img
                      src="/images/img/Product_quality.gif"
                      className="w-100"
                    />

                    {response == "successfull job applyed" ? (
                      <h3 className="text-center">Applied Successfull</h3>
                    ) : response == "alredy applyed on this job" ? (
                      <h3 className="text-center">Already Job Applied</h3>
                    ) : (
                      <h3 className="text-center">
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </h3>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*-=======================section=2============================== */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <div className=" pt-5">
                <h5>About the job</h5>
                <div
                  style={{ overflowWrap: "break-word  " }}
                  dangerouslySetInnerHTML={{ __html: jobDetails.jobDetails }}
                />
              </div>
              <div className=" pt-5">
                <h5>Key Responsibilities</h5>
                <div
                  style={{ overflowWrap: "break-word  " }}
                  dangerouslySetInnerHTML={{
                    __html: jobDetails.responsibilities,
                  }}
                />
              </div>
              <div className=" pt-5">
                <h5>Required Knowledge &amp; Qualification</h5>

                <div
                  style={{ overflowWrap: "break-word  " }}
                  dangerouslySetInnerHTML={{ __html: jobDetails.qualification }}
                />
              </div>
              <div className=" pt-5">
                <h5>Required Skills</h5>
                <div
                  style={{ overflowWrap: "break-word  " }}
                  dangerouslySetInnerHTML={{ __html: jobDetails.skills }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*-=======================section=3============================== */}
      <section className="pt-5">
        <div className="container">
          <div className="text-center pt-4">
            <h3>Related Jobs</h3>
            <p className="w-75 m-auto">
              Nulla tincidunt orci mi, ut gravida sapien imperdiet vel. Donec
              sodales elementum placerat. Fusce consectetur mattis odio, et
              convallis magna dapibus et.{" "}
            </p>
          </div>
          <div className="row pt-5">
            {categoryData.slice(offset, offset + PER_PAGE).map((item) => {
              return (
                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="card rounded-0 border-0 p-2">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="compny-img">
                          {item.companyDetails.companyLogo == "" ? (
                            <img
                              src={no_img}
                              alt=""
                              style={{
                                height: "30px",
                                objectFit: "contain",
                              }}
                              className="w-100"
                            />
                          ) : (
                            <img
                              src={
                                APIURL +
                                "/img/" +
                                item.companyDetails.companyLogo
                              }
                              style={{
                                height: "30px",
                                objectFit: "contain",
                              }}
                              className="w-100"
                            />
                          )}
                        </div>
                        <button
                          type="button"
                          className=" job-btn border-0 rounded-pill "
                        >
                          {item.jobType == "full-time"
                            ? "Full Time"
                            : item.jobType == "half-time"
                              ? "Half Time"
                              : item.jobType == "freelancer"
                                ? "Freelancer"
                                : ""}
                        </button>
                      </div>
                      <h4 className="mt-3 fw-semibold mb-1">
                        {" "}
                        {item.jobTitle.length > 20
                          ? item.jobTitle.slice(0, 20) + "..."
                          : item.jobTitle}
                      </h4>
                      <p className="mb-3">
                        {" "}
                        {item.companyDetails &&
                          item.companyDetails.companyName &&
                          item.companyDetails.companyName.length > 20
                          ? `${item.companyDetails.companyName.slice(0, 20)}...`
                          : item.companyDetails.companyName}
                      </p>
                      <div className="price  d-flex align-items-center">
                        <span className="money">
                          <i className="bi bi-currency-rupee fs-5" />
                        </span>
                        <span className="fs-16   ps-1">
                          {item.salleryMin} - {item.salleryMax}
                        </span>
                      </div>
                      <div className="location align-items-center justify-content-between d-flex mt-3">
                        <div className="align-items-center">
                          <span className="money">
                            <i className="bi bi-geo-alt fs-5" />
                          </span>
                          <span className=" ps-1">
                            {" "}
                            {item.companyDetails.country == null
                              ? ""
                              : item.companyDetails.country + "-"}
                            {item.companyDetails.city == null
                              ? ""
                              : item.companyDetails.city}
                          </span>{" "}
                          <span></span>
                        </div>
                        <p className=" m-0">2 Day ago</p>
                      </div>
                      {userStatus == "panding" ? (
                        <button
                          className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4"
                          data-bs-toggle="modal"
                          data-bs-target="#noProfile"
                        >
                          Quick Apply
                        </button>
                      ) : (
                        <Link to={`/job-details/${item.id}`}>
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="pagination fwmpag">
              <ReactPaginate
                breakLabel=". . ."
                previousLabel={"◁ prev"}
                nextLabel={"next ▷"}
                pageCount={pageCount}
                onPageChange={handleClick}
                pageRangeDisplayed={1}
                containerClassName={"pagination page"}
                renderOnZeroPageCount={null}
                previousLinkClassName={"prevposts-link"}
                nextLinkClassName={"nextposts-link"}
                disabledClassName={"pagination__link--disabled "}
                activeClassName={"pagination__link--active"}
                marginPagesDisplayed={1}
              // renderOnZeroPageCount={null}
              />

            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default JobDetail;
