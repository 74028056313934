import React, { useEffect, useState } from "react";
import UserHeader from "../partials/UserHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import sallery from "../../css/images/money-bag 1.png";
import locationImg from "../../css/images/locationicon.png";
import no_img from "../../css/images/profile.jpg";
import AllHeader from "../partials/AllHeader";
import moment from "moment";
const APIURL = "https://job.creativewebpixel.in";

const PER_PAGE = 21;

const Home = () => {
  const [allJob, setAllJob] = useState([]);
  const [allJobLength, setAllJobLength] = useState([]);
  const [freelanceFilter, setFreelanceFilter] = useState("");
  const [slugKey, setSlugKey] = useState("");
  const [freelanceLength, setFreelanceLength] = useState("");
  const [fullTimeLength, setFullTimeLength] = useState("");
  const [halfTimeLength, setHalfTimeLength] = useState("");

  const getAllJobs = (key) => {
    fetch(`${APIURL}/getAllJobs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then(async (response) => {
        setAllJobLength(response.message);
        const freelanceFilter = response.message.filter((item) => {
          return item.jobType == "freelancer";
        });
        const fulltimeFilter = response.message.filter((item) => {
          return item.jobType == "full-time";
        });
        const halftimeFilter = response.message.filter((item) => {
          return item.jobType == "half-time";
        });
        setFullTimeLength(fulltimeFilter.length);
        setFreelanceLength(freelanceFilter.length);
        setHalfTimeLength(halftimeFilter.length);
        setFreelanceFilter(freelanceFilter);

        setSlugKey(key);
        if (key == undefined || !key) {
          setAllJob(response.message.sort().reverse());
        } else {
          if (key == "freelance") {
            const filteredItems = await response.message.filter((user) =>
              user.jobType.toLowerCase().includes(key.toLowerCase())
            );
            setAllJob(filteredItems.sort().reverse());
            console.log("filteredItems", filteredItems);
          } else if (key == "full-time") {
            const filteredItems = await response.message.filter((user) =>
              user.jobType.toLowerCase().includes(key.toLowerCase())
            );
            setAllJob(filteredItems.sort().reverse());
            console.log("filteredItems", filteredItems);
          } else if (key == "half-time") {
            const filteredItems = await response.message.filter((user) =>
              user.jobType.toLowerCase().includes(key.toLowerCase())
            );
            setAllJob(filteredItems.sort().reverse());
            console.log("filteredItems", filteredItems);
          } else {
            console.log("return");
          }
        }

        console.log(response.message);
      });
  };

  useEffect(() => {
    // const queryParams = new URLSearchParams(location.search);
    // const key = queryParams.get("job-type");
    // if (!key) {
    getAllJobs();
    // }
  }, []);

  // const [userStatus, setUserStatus] = useState("");

  // useEffect(() => {
  //   const email = localStorage.getItem("email");

  //   axios({
  //     url: `${APIURL}/userDetails`,
  //     method: "GET",

  //     headers: {
  //       Authorization: `${email}`,
  //     },
  //   })
  //     .then((response) => response.data)
  //     .then((res) => {
  //       console.log("userdetails", res.message[0]);
  //       setUserStatus(res.message[0].status);
  //     });
  // }, []);

  const handleChange = (e) => {
    const searchTerm = e.target.value;

    fetch(`${APIURL}/getAllJobs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const filteredItems = response.message.filter((user) => {
          const jobTitle = user.jobTitle || "";
          const companyName = user.companyDetails.companyName || "";
          const city = user.companyDetails.city || "";
          return (
            jobTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
            companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            city.toLowerCase().includes(searchTerm.toLowerCase())
          );
        });

        setAllJob(filteredItems.sort().reverse());
      });

    if (searchTerm == "") {
      getAllJobs();
    }
  };

  const [experience, setExperience] = useState([]);
  const [experienceLvl, setExperienceLvl] = useState();

  const [salleryFilter, setSalleryFilter] = useState([]);
  const [salleryAmount, setSalleryAmount] = useState();
  useEffect(() => {
    const years = [
      { id: "0-1", experience: "0-1 Year" },
      { id: "1-2", experience: "1-2 Year" },
      { id: "2-3", experience: "2-3 Year" },
      { id: "3-4", experience: "3-4 Year" },
      { id: "4-5", experience: "4-5 Year" },
      { id: "5-6", experience: "5-6 Year" },
      { id: "6-7", experience: "6-7 Year" },
      { id: "7-8", experience: "7-8 Year" },
      { id: "8-9", experience: "8-9 Year" },
      { id: "9-10", experience: "9-10 Year" },
      { id: "10-11", experience: "10-11 Year" },
      { id: "11-12", experience: "11-12 Year" },
      { id: "12-13", experience: "12-13 Year" },
      { id: "13-14", experience: "13-14 Year" },
      { id: "14-15", experience: "14-15 Year" },
      { id: "15-16", experience: "15-16 Year" },
      { id: "16-17", experience: "16-17 Year" },
      { id: "17-18", experience: "17-18 Year" },
      { id: "18-19", experience: "18-19 Year" },
      { id: "19-20", experience: "19-20 Year" },
    ];

    setExperience(years);

    const amount = [
      { id: "1000-10000", amount: "1000-10000" },
      { id: "10000-20000", amount: "10000-20000" },
      { id: "20000-30000", amount: "20000-30000" },
      { id: "30000-40000", amount: "30000-40000" },
      { id: "40000-50000", amount: "40000-50000" },
      { id: "50000", amount: "50000+..." },
    ];

    setSalleryFilter(amount);
  }, []);

  const experienceLvlCheck = (id) => {
    setExperienceLvl(id);
    setSalleryAmount(id);
    const value = id.split("-");
    console.log(value);
    const firstValue = value[0];
    const secondValue = value[1];
    console.log(id);

    fetch(`${APIURL}/getAllJobs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response.message);

        const filteredItems = response.message.filter((user) => {
          console.log(user);
          return (
            parseInt(user.experienceLvl) >= parseInt(firstValue) &&
            parseInt(user.experienceLvl) < parseInt(secondValue)
          );
        });
        console.log("filteredItems", filteredItems);
        setAllJob(filteredItems.sort().reverse());
      });
  };

  const salleryAmountCheck = (id) => {
    setSalleryAmount(id);
    const value = id.split("-");
    console.log(value);
    const firstValue = value[0];
    const secondValue = value[1];
    console.log(id);

    fetch(`${APIURL}/getAllJobs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response.message);
        if (id == 50000) {
          const filteredItems = response.message.filter((user) => {
            return (
              parseInt(user.salleryMin) >= 50000 &&
              parseInt(user.salleryMax) >= 50000
            );
          });
          console.log("filteredItems50000", filteredItems);
          setAllJob(filteredItems.sort().reverse());
        } else {
          const filteredItems = response.message.filter((user) => {
            return (
              parseInt(user.salleryMin) >= parseInt(firstValue) &&
              parseInt(user.salleryMax) <= parseInt(secondValue)
            );
          });
          console.log("filteredItems", filteredItems);
          setAllJob(filteredItems.sort().reverse());
        }
      });
  };



  const [currentPage, setCurrentPage] = useState(0);

  function handleClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
    // window.scrollTo(0, 0)

  }

  const offset = currentPage * PER_PAGE;

  const pageCount = Math.ceil(allJob.length / PER_PAGE);
  return (
    <div>
      <AllHeader />
      <section>
        <div className="container">
          <div className="text-center embracing  ">
            <h1 className="fw-bold">
              Are you looking a <span className="text-color">good job</span>, or
              maybe something else?
            </h1>
          </div>
        </div>
      </section>
      <div className="bg-1">
        <section className="find-job  bg-1 ">
          <div className="bg-1 company-group   w-100  z-1">
            <div className="container">
              <div className="row   justify-content-between">
                <div className="col-md-7">
                  <h3 className="mt-30">Find a job with Us</h3>
                </div>
                <div className=" overflow border-bottom">
                <div className="col-md-12 pt-2 pb-4">
                  <ul
                    className="nav   nav-underline  z-1 bg-1"
                    id="myTab"
                    role="tablist"
                  >
                    <li
                      className="nav-item active"
                      onClick={() => getAllJobs()}
                      role="presentation"
                    >
                      <button
                        className={!slugKey ? "nav-link active" : "nav-link"}
                      >
                        All ({allJobLength.length})
                      </button>
                    </li>
                    <li className="nav-item active" role="presentation">
                      <button
                        className={
                          slugKey == "freelance"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        onClick={(e) => getAllJobs(e.target.value)}
                        value="freelance"
                      >
                        Freelance ({freelanceLength})
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={
                          slugKey == "full-time"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={(e) => getAllJobs(e.target.value)}
                        value="full-time"
                      >
                        Full-Time({fullTimeLength})
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={
                          slugKey == "half-time"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={(e) => getAllJobs(e.target.value)}
                        value="half-time"
                      >
                        Half Time ({halfTimeLength})
                      </button>
                    </li>
                  </ul>
                </div>
                </div>  
              </div>
              <div className="">
                <div className="row pt-4 mb-4 hide">
                  <div className="col-md-6 position-relative">
                    <div className="mb-3 ">
                      <input
                        type="text"
                        className="form-control py-3 ps-5 fs-6 rounded-0"
                        id="exampleInputEmail1"
                        onChange={handleChange}
                        aria-describedby="emailHelp"
                        placeholder="Job title, keywords, or company"
                      />
                      <i className="bi bi-search position-absolute top-0 start-0 pt-3 ps-4 fs-5" />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <select
                          className="form-select py-3 bg-color2 rounded-0"
                          onChange={(e) => experienceLvlCheck(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option
                            value={experienceLvl ? experienceLvl : ""}
                            selected
                            hidden
                          >
                            Experience Level
                          </option>
                          {experience.map((item) => {
                            return (
                              <option value={item.id}>{item.experience}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <select
                          className="form-select py-3 bg-color2 rounded-0"
                          onChange={(e) => salleryAmountCheck(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option
                            value={salleryAmount ? salleryAmount : ""}
                            selected
                            hidden
                          >
                            Salary estimate
                          </option>
                          {salleryFilter.map((item) => {
                            return (
                              <option value={item.id}>{item.amount}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-2 mb-3">
                <select
                  className="form-select py-3 bg-color2 rounded-0"
                  aria-label="Default select example"
                >
                  <option selected>Sort by (default)</option>
                  <option value={1}>One</option>
                  <option value={2}>Two</option>
                  <option value={3}>Three</option>
                </select>
              </div> */}
                </div>
                <div className="my-3">
                  <button
                    className="bi-funnel funnel"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar"
                    aria-controls="offcanvasNavbar"
                    aria-label="Toggle navigation"
                  >
                    <span>Filter</span>
                  </button>
                  <div
                    className="offcanvas offcanvas-end"
                    tabIndex={-1}
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                  >
                    <div className="offcanvas-header">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      />
                    </div>
                    <div className="row pt-4 mb-4 px-3">
                      <div className="col-md-12 position-relative">
                        <div className="mb-3 ">
                          <input
                            type="text"
                            className="form-control py-3 ps-5 fs-6 rounded-0"
                            id="exampleInputEmail1"
                            onChange={handleChange}
                            aria-describedby="emailHelp"
                            placeholder="Job title, keywords, or company"
                          />
                          <i className="bi bi-search position-absolute top-0 start-0 pt-3 ps-4 fs-5" />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <select
                          className="form-select py-3 bg-color2 rounded-0"
                          onChange={(e) => experienceLvlCheck(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option
                            value={experienceLvl ? experienceLvl : ""}
                            selected
                            hidden
                          >
                            Experience Level
                          </option>
                          {experience.map((item) => {
                            return (
                              <option value={item.id}>{item.experience}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <select
                          className="form-select py-3 bg-color2 rounded-0"
                          onChange={(e) => salleryAmountCheck(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option
                            value={salleryAmount ? salleryAmount : ""}
                            selected
                            hidden
                          >
                            Salary estimate
                          </option>
                          {salleryFilter.map((item) => {
                            return (
                              <option value={item.id}>{item.amount}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="tab-content" id="myTabContent">
              <div
                className=""
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabIndex={0}
              >
                <div className="row mt-4">
                  {allJob.slice(offset, offset + PER_PAGE).map((item, i) => {
                    // console.log(item.companyDetails.companyName.length);
                    return (
                      <div key={i} className="col-md-4 mb-4 ">
                        <div className="card rounded-0 border-0 p-2">
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                              <div className="compny-img">
                                {item.companyDetails.companyLogo == "" ? (
                                  <img
                                    src={no_img}
                                    alt=""
                                    style={{
                                      height: "30px",
                                      objectFit: "contain",
                                    }}
                                    data-toggle={item.id} data-placement="top" title={item.companyDetails.companyName}
                                  />
                                ) : (
                                  <img
                                    src={
                                      APIURL +
                                      "/img/" +
                                      item.companyDetails.companyLogo
                                    }
                                    style={{
                                      height: "30px",
                                      objectFit: "contain",
                                    }}
                                    data-toggle={item.id} data-placement="top" title={item.companyDetails.companyName}
                                  />
                                )}
                              </div>
                              <button
                                type="button"
                                className=" job-btn border-0 rounded-pill "
                              >
                                {item.jobType == "full-time"
                                  ? "Full Time"
                                  : item.jobType == "half-time"
                                  ? "Half Time"
                                  : item.jobType == "freelancer"
                                  ? "Freelancer"
                                  : ""}
                              </button>
                            </div>
                            <h4 className="mt-3 fw-semibold mb-1">
                              {item.jobTitle.length > 20
                                ? item.jobTitle.slice(0, 20) + "..."
                                : item.jobTitle}
                            </h4>
                            <p className="mb-3">
                              {/* {item.companyDetails.companyName} */}
                              {item.companyDetails &&
                              item.companyDetails.companyName &&
                              item.companyDetails.companyName.length > 20
                                ? `${item.companyDetails.companyName.slice(
                                    0,
                                    20
                                  )}...`
                                : item.companyDetails.companyName}
                            </p>
                            <div className="price  d-flex ">
                              <span className="money">
                                <img src={sallery} alt />
                              </span>
                              <span className="fs-16   ps-3">
                                {item.salleryMin}- {item.salleryMax}
                              </span>
                            </div>
                            <div className="location align-items-center justify-content-between d-flex mt-3">
                              <div className="d-flex">
                                <span className="money">
                                  <img src={locationImg} alt="" />
                                </span>
                                <span className=" ps-3">
                                  {item.jobLocation == null
                                    ? ""
                                    : item.jobLocation}

                                </span>{" "}
                                <span></span>
                              </div>
                            </div>
                            
                            <div className="location align-items-center justify-content-between d-flex mt-3">
                              <div>
                                <span className="money">
                                  <i className="bi bi-stopwatch" alt="" />
                                </span>
                                <span className=" ps-3">
                                  {moment(item.date_time).format("Do MMMM YYYY")}
                                </span>{" "}
                                <span></span>
                              </div>
                            </div>
                            {!localStorage.getItem("emailId") &&
                            !localStorage.getItem("userId") ? (
                              <Link to="/login">
                                <button
                                  className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#noProfile"
                                >
                                  Quick Apply
                                </button>
                              </Link>
                            ) : (
                              <Link to={`/job-details/${item.id}`}>
                                {" "}
                                <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                                  Quick Apply
                                </button>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                   <div className="pagination fwmpag">
                        <ReactPaginate
                          breakLabel=". . ."
                          previousLabel={"◁ prev"}
                          nextLabel={"next ▷"}
                          pageCount={pageCount}
                          onPageChange={handleClick}
                          pageRangeDisplayed={1}
                          containerClassName={"pagination page"}
                          renderOnZeroPageCount={null}
                          previousLinkClassName={"prevposts-link"}
                          nextLinkClassName={"nextposts-link"}
                          disabledClassName={"pagination__link--disabled "}
                          activeClassName={"pagination__link--active"}
                          marginPagesDisplayed={1}
                        // renderOnZeroPageCount={null}
                        />
             
                      </div>
                </div>
               
              </div>
            
              
            </div>
         
          </div>
        </section>
        <section className="footer-bar footer-bg mt-3">
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-end">
                  <p className="m-0 p-0">
                    <span className="pe-3">Terms and Conditions</span>
                    <span>Privacy and Policy</span>
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </div>

      <div
        className=" modal fade"
        id="noProfile"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <img src="/images/AddUser-rafiki.png" className="w-100" />
              <h3 className="text-center">
                Please First Complete Your Profile
              </h3>
              {/* {response == "successfull job applyed" ? (
                
              ) : response == "alredy applyed on this job" ? (
                <h3 className="text-center">Already Job Applied</h3>
              ) : (
                <h3 className="text-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </h3>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
