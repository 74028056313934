import React, { useEffect, useState } from "react";
import CompanyHeader from "../partials/CompanyHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

const APIURL = "https://job.creativewebpixel.in";

const PER_PAGE = 21

const AllCandidate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const type = localStorage.getItem("type");

    if (type !== "company") {
      navigate("/user-dashboard");
    }


    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }
  }, []);
  const [allCandidate, setAllCandidate] = useState([]);
  const [allCandidateLength, setAllCandidateLength] = useState([]);
  const [freelanceFilter, setFreelanceFilter] = useState("");
  const [slugKey, setSlugKey] = useState("");
  const [freelanceLength, setFreelanceLength] = useState("");
  const [fullTimeLength, setFullTimeLength] = useState("");
  const [halfTimeLength, setHalfTimeLength] = useState("");
  const getAllCandidate = (getKey) => {
    fetch(`${APIURL}/getAllCandidate`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then(async (response) => {
        setAllCandidateLength(response.message)
        console.log(response.message)

        const freelanceFilter = response.message.filter((item) => {
          return item.employementType == "freelancer";
        });
        const fulltimeFilter = response.message.filter((item) => {
          return item.employementType == "full-time";
        });
        const halftimeFilter = response.message.filter((item) => {
          return item.employementType == "half-time";
        });
        setFullTimeLength(fulltimeFilter.length);
        setFreelanceLength(freelanceFilter.length);
        setHalfTimeLength(halftimeFilter.length);
        setFreelanceFilter(freelanceFilter);

        setSlugKey(getKey)
        console.log("getKey", getKey)
        if (getKey == undefined || !getKey) {
          setAllCandidate(response.message.sort().reverse());
        } else {


          if (getKey == "freelance") {
            const filteredItems = await response.message.filter((user) =>
              user.employementType.toLowerCase().includes(getKey.toLowerCase())
            );
            setAllCandidate(filteredItems.sort().reverse());
            console.log("filteredItems", filteredItems);
          } else if (getKey == "full-time") {
            const filteredItems = await response.message.filter((user) =>
              user.employementType.toLowerCase().includes(getKey.toLowerCase())
            );
            setAllCandidate(filteredItems.sort().reverse());
            console.log("filteredItems", filteredItems);
          } else if (getKey == "half-time") {
            const filteredItems = await response.message.filter((user) =>
              user.employementType.toLowerCase().includes(getKey.toLowerCase())
            );
            setAllCandidate(filteredItems.sort().reverse());
            console.log("filteredItems", filteredItems);
          } else {
            return
          }
        }

        console.log(response.message);
      });
  };

  useEffect(() => {
    getAllCandidate();
  }, []);




  const [searchText, setSearchText] = useState("");

  const handleChange = (e) => {
    const searchTerm = e.target.value;
    setSearchText(searchTerm);

    const filteredItems = allCandidate.filter((user) =>
      user.designation.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setAllCandidate(filteredItems.sort().reverse());

    if (searchTerm == "") {
      getAllCandidate();

    }
  };



  const [experience, setExperience] = useState([])
  const [experienceLvl, setExperienceLvl] = useState()
  const [salleryFilter, setSalleryFilter] = useState([])
  const [salleryAmount, setSalleryAmount] = useState()
  useEffect(() => {
    const years = [
      { id: "0-1", experience: "0-1 Year" },
      { id: "1-2", experience: "1-2 Year" },
      { id: "2-3", experience: "2-3 Year" },
      { id: "3-4", experience: "3-4 Year" },
      { id: "4-5", experience: "4-5 Year" },
      { id: "5-6", experience: "5-6 Year" },
      { id: "6-7", experience: "6-7 Year" },
      { id: "7-8", experience: "7-8 Year" },
      { id: "8-9", experience: "8-9 Year" },
      { id: "9-10", experience: "9-10 Year" },
      { id: "10-11", experience: "10-11 Year" },
      { id: "11-12", experience: "11-12 Year" },
      { id: "12-13", experience: "12-13 Year" },
      { id: "13-14", experience: "13-14 Year" },
      { id: "14-15", experience: "14-15 Year" },
      { id: "15-16", experience: "15-16 Year" },
      { id: "16-17", experience: "16-17 Year" },
      { id: "17-18", experience: "17-18 Year" },
      { id: "18-19", experience: "18-19 Year" },
      { id: "19-20", experience: "19-20 Year" },

    ]

    setExperience(years)

    const amount = [
      { id: "1000-10000", amount: "1000-10000" },
      { id: "10000-20000", amount: "10000-20000" },
      { id: "20000-30000", amount: "20000-30000" },
      { id: "30000-40000", amount: "30000-40000" },
      { id: "40000-50000", amount: "40000-50000" },
      { id: "50000", amount: "50000+..." },



    ]

    setSalleryFilter(amount)
  }, [])

  const experienceLvlCheck = (id) => {

    setExperienceLvl(id)
    const value = id.split("-")
    console.log(value)
    const firstValue = value[0]
    const secondValue = value[1]
    console.log(id)

    fetch(`${APIURL}/getAllCandidate`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {


        const filteredItems = response.message.filter((user) =>
          parseInt(user.experience) >= parseInt(firstValue) && parseInt(user.experience) < parseInt(secondValue)
        );
        console.log("filteredItems", filteredItems)
        setAllCandidate(filteredItems.sort().reverse());
      })
  }



  const salleryAmountCheck = (id) => {


    setSalleryAmount(id)
    const value = id.split("-")
    console.log(value)
    const firstValue = value[0]
    const secondValue = value[1]
    console.log(id)

    fetch(`${APIURL}/getAllCandidate`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response.message)
        if (id == 50000) {
          const filteredItems = response.message.filter((user) => {


            return parseInt(user.currentSallery) >= 50000 && parseInt(user.expectedSallery) >= 50000

          });
          console.log("filteredItems50000", filteredItems)
          setAllCandidate(filteredItems.sort().reverse());

        } else {
          const filteredItems = response.message.filter((user) => {


            return parseInt(user.currentSallery) >= parseInt(firstValue) && parseInt(user.expectedSallery) <= parseInt(secondValue)

          });
          console.log("filteredItems", filteredItems)
          setAllCandidate(filteredItems.sort().reverse());
        }

      })
  }

  const [currentPage, setCurrentPage] = useState(0);

  function handleClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
    // window.scrollTo(0, 0)

  }

  const offset = currentPage * PER_PAGE;

  const pageCount = Math.ceil(allCandidate.length / PER_PAGE);

  return (
    <div>
      {/*-=======================section=1============================== */}
      <CompanyHeader />
      <section>
        <div className="container">
          <div className="text-center embracing  ">
            <h1 className="fw-bold">
              Opportunity Knocking: Embracing New Talen{" "}
              <span className="text-color">- Are You Hiring</span>?
            </h1>
          </div>
        </div>
      </section>
      {/*-=======================section=2============================== */}
      <section className="bg-color2">
        <div className="container">
          <div className=" overflow">
            <div className="col-md-12 pt-2 pb-2">
              <ul
                className="nav   border-bottom nav-underline  z-1 bg-1"
                id="myTab"
                role="tablist"
              >


                <li className="nav-item active" onClick={() => getAllCandidate()} role="presentation">

                  <button
                    className={slugKey == undefined || !slugKey ? "nav-link active" : "nav-link"}



                  >
                    All ({allCandidateLength.length})
                  </button>

                </li>
                <li className="nav-item active" role="presentation">

                  <button
                    className={slugKey == "freelance" ? "nav-link active" : "nav-link"}

                    type="button"
                    onClick={(e) => getAllCandidate(e.target.value)}
                    value="freelance"
                  >
                    Freelance ({freelanceLength})
                  </button>

                </li>
                <li className="nav-item" role="presentation">

                  <button
                    className={slugKey == "full-time" ? "nav-link active" : "nav-link"}

                    type="button"
                    onClick={(e) => getAllCandidate(e.target.value)}
                    value="full-time"

                  >
                    Full-Time({fullTimeLength})
                  </button>

                </li>
                <li className="nav-item" role="presentation">


                  <button
                    className={slugKey == "half-time" ? "nav-link active" : "nav-link"}

                    type="button"
                    onClick={(e) => getAllCandidate(e.target.value)}
                    value="half-time"

                  >
                    Half Time ({halfTimeLength})
                  </button>

                </li>
              </ul>
            </div>
          </div>
          <div className="">
            <div className="row pt-4 mb-4 hide">
              <div className="col-md-6 position-relative">
                <div className="mb-3 ">
                  <input
                    type="text"
                    className="form-control py-3 ps-5 fs-6 rounded-0"
                    id="exampleInputEmail1"
                    onChange={handleChange}
                    aria-describedby="emailHelp"
                    placeholder="Job title, keywords, or company"
                  />
                  <i className="bi bi-search position-absolute top-0 start-0 pt-3 ps-4 fs-5" />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <select
                      className="form-select py-3 bg-color2 rounded-0"
                      onChange={(e) => experienceLvlCheck(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value={experienceLvl ? experience : ""} selected hidden>Experience Level</option>
                      {experience.map((item) => {
                        return (
                          <option value={item.id}>{item.experience}</option>
                        )
                      })}

                    </select>
                  </div>
                  <div className="col-md-6 mb-3">
                    <select
                      className="form-select py-3 bg-color2 rounded-0"
                      onChange={(e) => salleryAmountCheck(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value={salleryAmount ? salleryAmount : ""} selected hidden>Salary estimate</option>
                      {salleryFilter.map((item) => {
                        return (
                          <option value={item.id}>{item.amount}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-2 mb-3">
                <select
                  className="form-select py-3 bg-color2 rounded-0"
                  aria-label="Default select example"
                >
                  <option selected>Sort by (default)</option>
                  <option value={1}>One</option>
                  <option value={2}>Two</option>
                  <option value={3}>Three</option>
                </select>
              </div> */}
            </div>
            <div className="my-3">
              <button
                className="bi-funnel funnel"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                aria-label="Toggle navigation"
              >
                <span>Filter</span>
              </button>
              <div
                className="offcanvas offcanvas-end"
                tabIndex={-1}
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
              >
                <div className="offcanvas-header">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  />
                </div>
                <div className="row pt-4 mb-4 px-3">
                  <div className="col-md-12 position-relative">
                    <div className="mb-3 ">
                      <input
                        type="email"
                        className="form-control py-3 ps-5 fs-6 rounded-0"
                        id="exampleInputEmail1"
                        onChange={handleChange}
                        aria-describedby="emailHelp"
                        placeholder="Job title, keywords, or company"
                      />
                      <i className="bi bi-search position-absolute top-0 start-0 pt-3 ps-4 fs-5" />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <select
                      className="form-select py-3 bg-color2 rounded-0"
                      onChange={(e) => experienceLvlCheck(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value={experienceLvl ? experience : ""} selected hidden>Experience Level</option>
                      {experience.map((item) => {
                        return (
                          <option value={item.id}>{item.experience}</option>
                        )
                      })}

                    </select>
                  </div>
                  <div className="col-md-6 mb-3">
                    <select
                      className="form-select py-3 bg-color2 rounded-0"
                      onChange={(e) => salleryAmountCheck(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value={salleryAmount ? salleryAmount : ""} selected hidden>Salary estimate</option>
                      {salleryFilter.map((item) => {
                        return (
                          <option value={item.id}>{item.amount}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabIndex={0}
            >
              <div className="row pb-4 ">
                {allCandidate.slice(offset, offset + PER_PAGE).map((item, i) => {
                  console.log(item);
                  return (
                    <div className="col-lg-4 col-md-6 position-relative mb-4">
                      <div className="bg-white text-center p-4 ">
                        {item.avtar == "" || item.avtar == null ? (
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                            src="images/profile.jpg"
                            alt=""
                            className="rounded-circle"
                            data-toggle={item.id} data-placement="top" title={item.fullname+": " + item.designation}
                          />
                        ) : (
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                            src={APIURL + "/img/" + item.avtar}
                            alt=""
                            className="rounded-circle"
                            data-toggle={item.id} data-placement="top" title={item.fullname+": " + item.designation}
                          />
                        )}

                        <h4 className=" fw-medium paddi fs-5">
                          {item.fullname}
                        </h4>
                        <span className=" text-color1 designer">
                          {item.designation}
                        </span>
                        <p className=" m-auto pt-3 text-color1 py-3 ">
                          {item.currentSallery}- {item.expectedSallery}
                        </p>
                        <div className="d-flex justify-content-center ">
                          <Link to={item.twitter} className=" text-color2">
                            <i className="bi bi-twitter fs-5" />
                          </Link>
                          <Link to={item.facebook} className=" text-color2">
                            <i className="bi bi-facebook ps-3 fs-5" />
                          </Link>
                          <Link to={item.instagram} className=" text-color2">
                            <i className="bi bi-instagram ps-3 fs-5" />
                          </Link>
                        </div>
                        <button type="button" class=" job-btn border-0 rounded-pill mt-4 mb-3">{item.employementType == "full-time"
                                  ? "Full Time"
                                  : item.employementType == "half-time"
                                    ? "Half Time"
                                    : item.employementType == "freelancer"
                                      ? "Freelancer"
                                      : ""}</button>
                        <Link to={`/candidate-detail/${item.id}`}>
                          {" "}
                          <button className="bg-color2 border-0  py-2 mt-3 hire ">
                            Want to Hire
                          </button>
                        </Link>
                        {item.rating == 5 ? <div className="position-absolute top-0 start-0 ms-4 mt-3 check">
                          <i
                            className="bi bi-check-lg fs-4 "
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            data-bs-title="Verified for Our Team"
                          />
                        </div> : ""}

                      </div>
                    </div>
                  );
                })}
                <div className="pagination fwmpag">
                  <ReactPaginate
                    breakLabel=". . ."
                    previousLabel={"◁ prev"}
                    nextLabel={"next ▷"}
                    pageCount={pageCount}
                    onPageChange={handleClick}
                    pageRangeDisplayed={1}
                    containerClassName={"pagination page"}
                    renderOnZeroPageCount={null}
                    previousLinkClassName={"prevposts-link"}
                    nextLinkClassName={"nextposts-link"}
                    disabledClassName={"pagination__link--disabled "}
                    activeClassName={"pagination__link--active"}
                    marginPagesDisplayed={1}
                  // renderOnZeroPageCount={null}
                  />

                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      {/*-=======================section=3============================== */}
      <section className="bg-color2 pt-4">
        <div className="container">
          <div className=" cat-strip text-center">
            <h3 className="text-white cat-strip1">
              Do you want to open a job position for your company?
            </h3>
            <Link to="/create-job">
              <button className="bg-color2 border-0  py-2 mt-3 hire1 ">
                Post a Job
              </button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AllCandidate;
