import React, { useEffect, useState } from "react";
import CompanyHeader from "../partials/CompanyHeader";
import { Link, useNavigate } from "react-router-dom";

const APIURL = "https://job.creativewebpixel.in";

const RequestJobCandidate = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const type = localStorage.getItem("type");
      if (type !== "company") {
       navigate("/user-dashboard");
    }
 const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }
  }, []);
  const [allCandidate, setAllCandidate] = useState([]);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    fetch(`${APIURL}/getAllJobRequest`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `${userId}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setAllCandidate(response.message.sort().reverse());
        console.log(response.message);
      });
  }, []);

  return (
    <div>
      {/*-=======================section=1============================== */}
      <CompanyHeader />
      <section>
        <div className="container">
          <div className="text-center embracing  ">
            <h1 className="fw-bold">
              Candidate <span className="text-color">Request</span>?
            </h1>
          </div>
        </div>
      </section>
      {/*-=======================section=2============================== */}
      <section className="bg-color2">
        <div className="container">
          <div className>
            <div className="row pt-4 mb-4 hide">
              <div className="col-md-6 position-relative">
                <div className="mb-3 ">
                  <input
                    type="email"
                    className="form-control py-3 ps-5 fs-6 rounded-0"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Job title, keywords, or company"
                  />
                  <i className="bi bi-search position-absolute top-0 start-0 pt-3 ps-4 fs-5" />
                </div>
              </div>
            </div>
            <div className="my-3">
              <button
                className="bi-funnel funnel"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                aria-label="Toggle navigation"
              >
                <span>Filter</span>
              </button>
              <div
                className="offcanvas offcanvas-end"
                tabIndex={-1}
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
              >
                <div className="offcanvas-header">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  />
                </div>
                <div className="row pt-4 mb-4 px-3">
                  <div className="col-md-12 position-relative">
                    <div className="mb-3 ">
                      <input
                        type="email"
                        className="form-control py-3 ps-5 fs-6 rounded-0"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Job title, keywords, or company"
                      />
                      <i className="bi bi-search position-absolute top-0 start-0 pt-3 ps-4 fs-5" />
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <select
                      className="form-select py-3 bg-color2 rounded-0"
                      aria-label="Default select example"
                    >
                      <option selected>Experience Level</option>
                      <option value={1}>One</option>
                      <option value={2}>Two</option>
                      <option value={3}>Three</option>
                    </select>
                  </div>
                  <div className="col-md-12 mb-3">
                    <select
                      className="form-select py-3 bg-color2 rounded-0"
                      aria-label="Default select example"
                    >
                      <option selected>Salary estimate</option>
                      <option value={1}>One</option>
                      <option value={2}>Two</option>
                      <option value={3}>Three</option>
                    </select>
                  </div>
                  <div className="col-md-12 mb-3">
                    <select
                      className="form-select py-3 bg-color2 rounded-0"
                      aria-label="Default select example"
                    >
                      <option selected>Sort by (default)</option>
                      <option value={1}>One</option>
                      <option value={2}>Two</option>
                      <option value={3}>Three</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabIndex={0}
            >
              <div className="row pb-4 ">
                {allCandidate.map((item, i) => {
                  console.log(item);
                  return (
                    <div className="col-lg-4 col-md-6 position-relative mb-4">
                      <div className="bg-white text-center p-4 ">
                        <img
                          style={{
                            width: "30%",
                            height: "100px",
                            objectFit: "cover",
                          }}
                          src={APIURL + "/img/" + item.userId.avtar}
                          alt=""
                          className="rounded-circle"
                        />
                        <p className="fw-medium text-color m-0  bg-color3  rounded-pill position-absolute top2 mb-5">
                          80%
                        </p>
                        <Link
                          className="text-dark"
                          to={`/candidate-detail/${item.userId.id}`}
                        >
                          <h4 className=" fw-medium paddi fs-5">
                            {item.userId.fullname}
                          </h4>
                        </Link>
                        <span className=" text-color1 designer">
                          {item.userId.designation}{" "}
                        </span>
                        <p className=" m-auto pt-3 text-color1 py-3 ">
                          {item.userId.description.slice(0, 15)}
                        </p>
                        <div className="d-flex justify-content-center ">
                          <Link
                            to={item.userId.twitter}
                            className=" text-color2"
                          >
                            <i className="bi bi-twitter fs-5" />
                          </Link>
                          <Link
                            to={item.userId.facebook}
                            className=" text-color2"
                          >
                            <i className="bi bi-facebook ps-3 fs-5" />
                          </Link>
                          <Link
                            to={item.userId.instagram}
                            className=" text-color2"
                          >
                            <i className="bi bi-instagram ps-3 fs-5" />
                          </Link>
                        </div>
                        <Link to={`/candidate-detail/${item.userId.id}`}>
                          {" "}
                          <button
                            className="bg-color2 border-0  py-2 mt-3 hire "
                            style={{ cursor: "auto" }}
                          >
                            {item.companyStatus}
                          </button>
                        </Link>{" "}
                        <div className="position-absolute top-0 start-0 ms-4 mt-3 check">
                          <i
                            className="bi bi-check-lg fs-4 "
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            data-bs-title="Verified for Our Team"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="  text-center">
                  <button className="bg-color1  border-0 load">
                    Load More
                  </button>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="profile-tab-pane"
              role="tabpanel"
              aria-labelledby="profile-tab"
              tabIndex={0}
            >
              <div className="row pb-4 ">
                <div className="col-lg-4 col-md-6 position-relative mb-4">
                  <div className="bg-white text-center p-4 ">
                    <img
                      width="30%"
                      src="/images/img/card.png"
                      alt
                      className="rounded-circle"
                    />
                    <p className="fw-medium text-color m-0  bg-color3  rounded-pill position-absolute  top2 mb-5">
                      80%
                    </p>
                    <h4 className=" fw-medium paddi fs-5">Arlene AcCoy</h4>
                    <span className=" text-color1 designer">
                      UI/UX Designer{" "}
                    </span>
                    <p className=" m-auto pt-3 text-color1 py-3 ">
                      Lorem ipsum dolor sit amet consectetur adipiscing elit
                      Morbi luctus.
                    </p>
                    <div className="d-flex justify-content-center ">
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-twitter fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-linkedin ps-3 fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-behance ps-3 fs-5" />
                      </Link>
                    </div>
                    <p className="mt-3 bg-color2 fs-6  m-auto rounded-pill py-1 full-time">
                      Full Time
                    </p>
                    <button className="bg-color2 border-0  py-2 mt-3 hire ">
                      Want to Hire
                    </button>
                    <div className="position-absolute top-0 start-0 ms-4 mt-3 check">
                      <i
                        className="bi bi-check-lg fs-4 "
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        data-bs-title="Verified for Our Team"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 position-relative mb-4">
                  <div className="bg-white text-center p-4 ">
                    <img
                      width="30%"
                      src="/images/img/card1.png"
                      alt
                      className="rounded-circle"
                    />
                    <p className="fw-medium text-color m-0  bg-color3  rounded-pill position-absolute  top2 mb-5">
                      90%
                    </p>
                    <h4 className=" fw-medium paddi fs-5">Arlene AcCoy</h4>
                    <span className=" text-color1 designer">
                      UI/UX Designer{" "}
                    </span>
                    <p className=" m-auto pt-3 text-color1 py-3 ">
                      Lorem ipsum dolor sit amet consectetur adipiscing elit
                      Morbi luctus.
                    </p>
                    <div className="d-flex justify-content-center ">
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-twitter fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-linkedin ps-3 fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-behance ps-3 fs-5" />
                      </Link>
                    </div>
                    <p className="mt-3 bg-color2 fs-6  m-auto rounded-pill py-1 full-time">
                      Full Time
                    </p>
                    <button className="bg-color2 border-0  py-2 mt-3 hire ">
                      Want to Hire
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 position-relative mb-4">
                  <div className="bg-white text-center p-4 ">
                    <img
                      width="30%"
                      src="/images/img/card3.png"
                      alt
                      className="rounded-circle"
                    />
                    <p className="fw-medium text-color m-0  bg-color3  rounded-pill position-absolute  top2 mb-5">
                      90%
                    </p>
                    <h4 className=" fw-medium paddi fs-5">Arlene AcCoy</h4>
                    <span className=" text-color1 designer">
                      UI/UX Designer{" "}
                    </span>
                    <p className=" m-auto pt-3 text-color1 py-3 ">
                      Lorem ipsum dolor sit amet consectetur adipiscing elit
                      Morbi luctus.
                    </p>
                    <div className="d-flex justify-content-center ">
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-twitter fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-linkedin ps-3 fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-behance ps-3 fs-5" />
                      </Link>
                    </div>
                    <p className="mt-3 bg-color2 fs-6  m-auto rounded-pill py-1 full-time">
                      Full Time
                    </p>
                    <button className="bg-color2 border-0  py-2 mt-3 hire ">
                      Want to Hire
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 position-relative mb-4">
                  <div className="bg-white text-center p-4 ">
                    <img
                      width="30%"
                      src="/images/img/card4.png"
                      alt
                      className="rounded-circle"
                    />
                    <p className="fw-medium text-color m-0  bg-color3  rounded-pill position-absolute  top2 mb-5">
                      90%
                    </p>
                    <h4 className=" fw-medium paddi fs-5">Arlene AcCoy</h4>
                    <span className=" text-color1 designer">
                      UI/UX Designer{" "}
                    </span>
                    <p className=" m-auto pt-3 text-color1 py-3 ">
                      Lorem ipsum dolor sit amet consectetur adipiscing elit
                      Morbi luctus.
                    </p>
                    <div className="d-flex justify-content-center ">
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-twitter fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-linkedin ps-3 fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-behance ps-3 fs-5" />
                      </Link>
                    </div>
                    <p className="mt-3 bg-color2 fs-6  m-auto rounded-pill py-1 full-time">
                      Full Time
                    </p>
                    <button className="bg-color2 border-0  py-2 mt-3 hire ">
                      Want to Hire
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 position-relative mb-4">
                  <div className="bg-white text-center p-4 ">
                    <img
                      width="30%"
                      src="/images/img/card8.png"
                      alt
                      className="rounded-circle"
                    />
                    <p className="fw-medium text-color m-0  bg-color3  rounded-pill position-absolute  top2 mb-5">
                      90%
                    </p>
                    <h4 className=" fw-medium paddi fs-5">Arlene AcCoy</h4>
                    <span className=" text-color1 designer">
                      UI/UX Designer{" "}
                    </span>
                    <p className=" m-auto pt-3 text-color1 py-3 ">
                      Lorem ipsum dolor sit amet consectetur adipiscing elit
                      Morbi luctus.
                    </p>
                    <div className="d-flex justify-content-center ">
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-twitter fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-linkedin ps-3 fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-behance ps-3 fs-5" />
                      </Link>
                    </div>
                    <p className="mt-3 bg-color2 fs-6  m-auto rounded-pill py-1 full-time">
                      Full Time
                    </p>
                    <button className="bg-color2 border-0  py-2 mt-3 hire ">
                      Want to Hire
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 position-relative mb-4">
                  <div className="bg-white text-center p-4 ">
                    <img
                      width="30%"
                      src="/images/img/card9.png"
                      alt
                      className="rounded-circle"
                    />
                    <p className="fw-medium text-color m-0  bg-color3  rounded-pill position-absolute  top2 mb-5">
                      90%
                    </p>
                    <h4 className=" fw-medium paddi fs-5">Arlene AcCoy</h4>
                    <span className=" text-color1 designer">
                      UI/UX Designer{" "}
                    </span>
                    <p className=" m-auto pt-3 text-color1 py-3 ">
                      Lorem ipsum dolor sit amet consectetur adipiscing elit
                      Morbi luctus.
                    </p>
                    <div className="d-flex justify-content-center ">
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-twitter fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-linkedin ps-3 fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-behance ps-3 fs-5" />
                      </Link>
                    </div>
                    <p className="mt-3 bg-color2 fs-6  m-auto rounded-pill py-1 full-time">
                      Full Time
                    </p>
                    <button className="bg-color2 border-0  py-2 mt-3 hire ">
                      Want to Hire
                    </button>
                  </div>
                </div>
                <div className="  text-center">
                  <button className="bg-color1  border-0 load">
                    Load More
                  </button>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="contact-tab-pane"
              role="tabpanel"
              aria-labelledby="contact-tab"
              tabIndex={0}
            >
              <div className="row pb-4 ">
                <div className="col-lg-4 col-md-6 position-relative mb-4">
                  <div className="bg-white text-center p-4 ">
                    <img
                      width="30%"
                      src="/images/img/card.png"
                      alt
                      className="rounded-circle"
                    />
                    <p className="fw-medium text-color m-0  bg-color3  rounded-pill position-absolute  top2 mb-5">
                      80%
                    </p>
                    <h4 className=" fw-medium paddi fs-5">Arlene AcCoy</h4>
                    <span className=" text-color1 designer">
                      UI/UX Designer{" "}
                    </span>
                    <p className=" m-auto pt-3 text-color1 py-3 ">
                      Lorem ipsum dolor sit amet consectetur adipiscing elit
                      Morbi luctus.
                    </p>
                    <div className="d-flex justify-content-center ">
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-twitter fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-linkedin ps-3 fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-behance ps-3 fs-5" />
                      </Link>
                    </div>
                    <p className="mt-3 bg-color2 fs-6  m-auto rounded-pill py-1 full-time">
                      Full Time
                    </p>
                    <button className="bg-color2 border-0  py-2 mt-3 hire ">
                      Want to Hire
                    </button>
                    <div className="position-absolute top-0 start-0 ms-4 mt-3 check">
                      <i
                        className="bi bi-check-lg fs-4 "
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        data-bs-title="Verified for Our Team"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 position-relative mb-4">
                  <div className="bg-white text-center p-4 ">
                    <img
                      width="30%"
                      src="/images/img/card1.png"
                      alt
                      className="rounded-circle"
                    />
                    <p className="fw-medium text-color m-0  bg-color3  rounded-pill position-absolute  top2 mb-5">
                      90%
                    </p>
                    <h4 className=" fw-medium paddi fs-5">Arlene AcCoy</h4>
                    <span className=" text-color1 designer">
                      UI/UX Designer{" "}
                    </span>
                    <p className=" m-auto pt-3 text-color1 py-3 ">
                      Lorem ipsum dolor sit amet consectetur adipiscing elit
                      Morbi luctus.
                    </p>
                    <div className="d-flex justify-content-center ">
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-twitter fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-linkedin ps-3 fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-behance ps-3 fs-5" />
                      </Link>
                    </div>
                    <p className="mt-3 bg-color2 fs-6  m-auto rounded-pill py-1 full-time">
                      Full Time
                    </p>
                    <button className="bg-color2 border-0  py-2 mt-3 hire ">
                      Want to Hire
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 position-relative mb-4">
                  <div className="bg-white text-center p-4 ">
                    <img
                      width="30%"
                      src="/images/img/card5.png"
                      alt
                      className="rounded-circle"
                    />
                    <p className="fw-medium text-color m-0  bg-color3  rounded-pill position-absolute  top2 mb-5">
                      80%
                    </p>
                    <h4 className=" fw-medium paddi fs-5">Arlene AcCoy</h4>
                    <span className=" text-color1 designer">
                      UI/UX Designer{" "}
                    </span>
                    <p className=" m-auto pt-3 text-color1 py-3 ">
                      Lorem ipsum dolor sit amet consectetur adipiscing elit
                      Morbi luctus.
                    </p>
                    <div className="d-flex justify-content-center ">
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-twitter fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-linkedin ps-3 fs-5" />
                      </Link>
                      <Link to="#" className=" text-color2">
                        <i className="bi bi-behance ps-3 fs-5" />
                      </Link>
                    </div>
                    <p className="mt-3 bg-color2 fs-6  m-auto rounded-pill py-1 full-time">
                      Full Time
                    </p>
                    <button className="bg-color2 border-0  py-2 mt-3 hire ">
                      Want to Hire
                    </button>
                    <div className="position-absolute top-0 start-0 ms-4 mt-3 check">
                      <i
                        className="bi bi-check-lg fs-4 "
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        data-bs-title="Verified for Our Team"
                      />
                    </div>
                  </div>
                </div>
                <div className="  text-center">
                  <button className="bg-color1  border-0 load">
                    Load More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*-=======================section=3============================== */}
      <section className="bg-color2 pt-4">
        <div className="container">
          <div className=" cat-strip text-center">
            <h3 className="text-white cat-strip1">
              Do you want to open a job position for your company?
            </h3>
            <Link to="/create-job">
              <button className="bg-color2 border-0  py-2 mt-3 hire1 ">
                Post a Job
              </button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RequestJobCandidate;
