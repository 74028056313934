import React, { useEffect, useState } from "react";
import Header from "../partials/UserHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { SwiperSlide, Swiper, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { withRouter } from 'react-router-dom';

import { EffectFade, Pagination, Navigation, Autoplay } from "swiper/modules";

const APIURL = "https://job.creativewebpixel.in";

const InterviewStart = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const type = localStorage.getItem("type");
    if (type !== "student") {
      navigate("/company-dashboard");
    }
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }
  }, []);

  const [interviewData, setInterviewData] = useState([]);
  const [interview, setInterview] = useState([]);
  const [count, setCount] = useState();
  const [running, setRunning] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("interview_token");
    fetch(`${APIURL}/getStudentInterviewByToken`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then(async (response) => {
        for (
          let i = 0;
          i < response.message[0].interview[0].randomQuestion.length;
          i++
        ) {
          const element = response.message[0].interview[0].randomQuestion[i];

          response.message[0].interview[0].randomQuestion[i].selectedAnswers =
            [];
        }
        setInterviewData(response.message[0].interview[0].randomQuestion);
        console.log(response.message[0].interview[0].randomQuestion);
        setInterview(response.message[0].interview[0]);
        console.log(response.message[0].interview[0].timing);
        let time = response.message[0].interview[0].timing.split(":");
        console.log(time);
        await setCount(time[0])



      });
  }, []);

  const [crull, setCrull] = useState([]);
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);

  const handleOptionSelection = (e) => {
    if (e.target.checked == true) {
      // Make a copy of quizData
      const newData = interviewData;
      console.log("prevData", e.target.value);
      if (e.target.value == newData.length) {
        setShowSubmitBtn(true);
      }

      if (e.target.type == "radio") {
        const newName = [{ answer: e.target.id }];
        console.log(newData);
        console.log(newName);
        // Update the selected answers for the specified question
        console.log(e.target.value - 1);
        // console.log("newData[e.target.value].selectedAnswers.includes(e.target.name)", newData[e.target.value - 1].selectedAnswers.includes(newName))

        //const selectedAnswers = new Set(newData[e.target.value - 1].selectedAnswers)
        // if (newData[e.target.value - 1].selectedAnswers.includes(newName[0].answer)) {
        //   newData[e.target.value - 1].selectedAnswers.filter((selectedOption) => selectedOption !== newName[0].answer)
        //   console.log("11111")
        // } else {
        newData[e.target.value - 1].selectedAnswers = newName[0].answer;

        console.log("22222");
        // }
        console.log("newDataIf", newData);
        setInterviewData(newData);
      } else {
        const newName = [{ ...e.target.id, answer: e.target.id }];
        console.log(newData);
        console.log(newName);
        // Update the selected answers for the specified question
        console.log(e.target.value - 1);
        //console.log(" newData[e.target.value].selectedAnswers.includes(e.target.name)", newData[e.target.value - 1].selectedAnswers.includes(newName))

        //const selectedAnswers = new Set(newData[e.target.value - 1].selectedAnswers)
        newData[e.target.value - 1].selectedAnswers = [
          ...newData[e.target.value - 1].selectedAnswers,
          newName[0].answer,
        ];
        console.log("newDataElse", newData);
        setInterviewData(newData);
        console.log("newData", newData);
        setCrull(newData);
        console.log(interview);
      }
    }
  };


  function startTimer() {

    const token = localStorage.getItem("interview_token");
    fetch(`${APIURL}/getStudentInterviewByToken`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then(async (response) => {

        let time = response.message[0].interview[0].timing.split(":");
        console.log(time);
        // Initial time set to 5 minutes and 30 seconds
        let totalSeconds = parseInt(time[0]) * 60 + parseInt(time[1]);
       // console.log(totalSeconds)

        const timerId = setInterval(() => {
          totalSeconds--;  // Decrement the total seconds by one
          const minutes = Math.floor(totalSeconds / 60);  // Calculate remaining minutes
          const seconds = totalSeconds % 60;  // Calculate remaining seconds

          // Format the time string as MM:SS
          const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

          // Display the formatted time
          //console.log(formattedTime)
          document.getElementById('timer').textContent = formattedTime;

          // Stop the timer when it reaches 00:00
          if (totalSeconds === 0) {
            clearInterval(timerId);
            console.log("Timer stopped."); // Optional: Remove if you don't need a console log.
          }
        }, 1000)
      });  // Run the function every 1000 milliseconds (1 second)
  }
  useEffect(() => {

    startTimer();  // Call the function to start the timer
  }, [])



  useEffect(() => {
    const handleBeforeUnload = () => {
      // Check if the page is being reloaded
     
        // Redirect to another page
        navigate(`/questions/${id}`);
   
    };

    // Listen for the beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Cleanup the event listener when component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [props.history]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process the selected answers (e.g., validate, send to server, etc.)
    console.log("Quiz Data:", interviewData);
    console.log("newData", crull);
    // console.log('interview', interview.id);
    const data = {
      interview: interview,
    };
    console.log(data);
  };




  // useEffect(() => {

  //   const token = localStorage.getItem("interview_token");
  //   fetch(`${APIURL}/getStudentInterviewByToken`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       authorization: `${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((response) => {

  //       let time = response.message[0].interview[0].timing.split(":");
  //       console.log(time);
  //   const interval = setInterval(() => {
  //     // Calculate new time
  //     let newSeconds = parseInt(time[1]) - 1;
  //     let newMinutes = parseInt(time[0]);

  //     if (newSeconds < 0) {
  //       newSeconds = 59;
  //       newMinutes -= 1;
  //       if (newMinutes < 0) {
  //         // Time's up! You can handle this case however you want.
  //         clearInterval(interval);
  //         return;
  //       }
  //     }

  //     // Update state
  //     console.log({ minutes: newMinutes, seconds: newSeconds });
  //     setCount({ minutes: newMinutes, seconds: newSeconds });
  //   }, 1000); // 1000 milliseconds = 1 second

  //   // Cleanup function to clear the interval when the component unmounts
  //   return () => clearInterval(interval);
  // })
  // }, [count]);


  // const [time, setTime] = useState({ minutes: 0, seconds: 0 });

  // useEffect(() => {
  //   // Function to fetch time from API
  //   const fetchTime = async () => {
  //     try {
  //       // Replace 'apiEndpoint' with your actual API endpoint
  //       const token = localStorage.getItem("interview_token");
  //       const response = await fetch(`${APIURL}/getStudentInterviewByToken`, {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `${token}`,
  //         },
  //       });
  //       const data = await response.json();
  //       console.log(data)
  //       const hello = data.message[0].interview[0].timing.split(":");
  //       // Assuming the API response contains 'minutes' and 'seconds' fields
  //       setTime({ minutes: parseInt(hello[0]) || 0, seconds: parseInt(hello[1]) || 0 });
  //     } catch (error) {
  //       console.error('Error fetching time data:', error);
  //     }
  //   };

  //   // Fetch time when the component mounts
  //   fetchTime();

  //   // Set up interval to fetch time periodically (e.g., every minute)
  //   const interval = setInterval(fetchTime, 1000); // Fetch every 60 seconds

  //   // Cleanup function to clear the interval when the component unmounts
  //   return () => clearInterval(interval);
  // }, []); // Empty dependency array means effect runs only once on component mount


  return (
    <div className="bg-1" style={{ height: "100vh" }}>
      <Header />
      <section className="question-find  mt-5 pb-5">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-8 col-md-12 order-02 interviews">
              <Swiper
                slidesPerView={1}
                // spaceBetween={30}
                navigation={true}
                loop={false}
                effect={"fade"}
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}

                modules={[EffectFade, Navigation, Autoplay]}
                className="mySwiper"
              >
                {interviewData?.map((item, i) => {
                  console.log(item);
                  console.log(i + 1);

                  return (
                    <SwiperSlide>
                      <div className="bg-1 mt-5">
                        <div className="d-flex mt-5">
                          <p>{i + 1}.</p>
                          <p className="ps-2">{item.question}</p>
                        </div>

                        <div className="option-bar">
                          <div className="form-check mb-3">
                            <input
                              className="form-check-input"
                              type={
                                item.type == "multipalSelect"
                                  ? "checkbox"
                                  : "radio"
                              }
                              value={i + 1}
                              onChange={handleOptionSelection}
                              name="check"
                              id="A"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                            >
                              (A). {item.A}
                            </label>
                          </div>
                          <div className="form-check mb-3">
                            <input
                              className="form-check-input"
                              type={
                                item.type == "multipalSelect"
                                  ? "checkbox"
                                  : "radio"
                              }
                              value={i + 1}
                              onChange={handleOptionSelection}
                              name="check"
                              id="B"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                            >
                              (B). {item.B}
                            </label>
                          </div>
                          <div className="form-check mb-3">
                            <input
                              className="form-check-input"
                              type={
                                item.type == "multipalSelect"
                                  ? "checkbox"
                                  : "radio"
                              }
                              value={i + 1}
                              onChange={handleOptionSelection}
                              name="check"
                              id="C"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                            >
                              (C). {item.C}
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type={
                                item.type == "multipalSelect"
                                  ? "checkbox"
                                  : "radio"
                              }
                              value={i + 1}
                              onChange={handleOptionSelection}
                              name="check"
                              id="D"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                            >
                              (D). {item.D}
                            </label>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <div className="text-center">
                {" "}
                {showSubmitBtn ? (
                  <button
                    type="button"
                    className="btn btn-warning rounded-0 allAnswer"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                ) : (
                  ""
                )}
              </div>
              {/* <div className="questions mt-5">
                <ReactPaginate
                  breakLabel=" "
                  previousLabel={"←  Previous"}
                  nextLabel={"Next  →"}
                  pageCount={pageCount}
                  onPageChange={handleClick}

                  containerClassName={"pagination page"}
                  renderOnZeroPageCount={null}
                  previousLinkClassName={"prevposts-link"}
                  nextLinkClassName={"nextposts-link"}
                  disabledClassName={"pagination__link--disabled "}
                  activeClassName={"pagination__link--active"}
                  marginPagesDisplayed={1}
                // renderOnZeroPageCount={null}
                />
              </div> */}
            </div>
            <div class="col-lg-4  col-md-12 mb-4 order-01">
              <div class="card py-2 rounded-0 border-0">
                <div class="card-body">
                  <p>Due: 29, March 2024, 11:11 AM</p>
                  <div class="clocker-time fs-5 text-primary bg-1 d-inline px-3 py-2">
                    <span>
                      <i class="bi bi-alarm pe-2"></i>
                    </span>
                    <span id="timer"></span>
                  </div>
                  <span class="ps-3">Remaining</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InterviewStart;
