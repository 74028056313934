import React, { useEffect, useState } from "react";
import Header from "../partials/UserHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const APIURL = "https://job.creativewebpixel.in";

const InterviewQuestions = () => {
  const { id } = useParams()
  const navigate = useNavigate();
  useEffect(() => {
    const type = localStorage.getItem("type");
    if (type !== "student") {
      navigate("/company-dashboard")
    }
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }
  }, []);


  const [interviewData, setInterviewData] = useState([])
  const [AllinterviewData, setAllInterviewData] = useState([])
  useEffect(() => {
    fetch(`${APIURL}/getAllInterviews`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response.message)
        const filterData = response.message.filter((item) => {
          return (
            item.id == id
          )
        })
        setInterviewData(filterData[0].category)
        setAllInterviewData(filterData[0])
        console.log(filterData[0])


      })
  }, [])

  const startInterView = () => {
    const userId = localStorage.getItem("userId")
    console.log(JSON.stringify(userId))
    axios({
      url: `${APIURL}/createRandomQuestions/${id}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `${userId}`,
      },
    }


    )
      .then((response) => {
        console.log(response.data)
        localStorage.setItem("interview_token", response.data.token)
        if (response.data) {
          navigate(`/interview/${id}`)
        }
      }

      ).catch((err) => console.log(err))
  }



  const filterCategory = (filterId) => {
    console.log(filterId)
    fetch(`${APIURL}/getAllInterviews`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response.message)
        const filterData = response.message.filter((item) => {
          return (
            item.id == id
          )
        })

        if (filterId == "all-interviews") { setInterviewData(filterData[0].category) } else {

          const filter = filterData[0].category.filter((item) => {
            console.log(item)
            return (
              item.category == filterId
            )
          })
          console.log("filter", filter)
          setInterviewData(filter)

        }
      })
  }

  return (
    <div className="bg-1">
      <Header />
      <section className="bg-white">
        <div className="container">
          <div className="text-center embracing  ">
            <h1 className="fw-bold">Preparation Your&nbsp;&nbsp;
              <span className="text-color">
                Interview
              </span>

            </h1>
          </div>
        </div>
      </section>

      <section className="question-find  mt-5 pb-5">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-8 col-md-12 order-02">
              <h3 class="mt-30">{AllinterviewData.title}</h3>

              <div className="col-md-12 pt-2 pb-4">
                <ul
                  className="nav   nav-underline  z-1 bg-1"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item active" role="presentation">
                    <button
                      className="nav-link active"
                      id="all-interviews"
                      onClick={(e) => filterCategory(e.target.id)}

                    >
                      All Interviews
                    </button>
                  </li>
                  {AllinterviewData?.category?.map((item,i) => {
                    return (
                      <li className="nav-item active" role="presentation">
                        <button
                          className={i+1?"nav-link active":"nav-link"}
                          id={item.category}
                          onClick={(e) => filterCategory(e.target.id)}

                        >
                          {item.category}
                        </button>
                      </li>
                    )
                  })}

                </ul>
              </div>

              {interviewData.map((item, i) => {
                return (

                  <div className=" mt-5">

                    {item.questions.map((item2, i2) => {
                      console.log(item2)
                      return (<>
                        <div className="d-flex mt-5">

                          <p>{i2 + 1}.</p>
                          <p className="ps-2">{item2.question}</p>
                        </div>

                        <p className="d-flex"><span className="fw-bold">Answer:</span>&nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <>{item2.type == "singleSelect" ? item2.rightAnswer == "A" ? item2.A : item2.rightAnswer == "B" ? item2.B : item2.rightAnswer == "C" ? item2.C : item2.rightAnswer == "D" ? item2.D : "" : item2.rightAnswer.map((itemAnswer) => {
                            return (<div>
                              <div>{itemAnswer == "A" ? <div className="pe-3"> {"A). " + item2.A + ","}</div> : ""}</div>
                              <div> {itemAnswer == "B" ? <div className="pe-3">{"B). " + item2.B + ","}</div> : ""}</div>
                              <div> {itemAnswer == "C" ? <div className="pe-3">{"C). " + item2.C + ","}</div> : ""}</div>
                              <div>{itemAnswer == "D" ? <div className="pe-3">{"D). " + item2.D + ","}</div> : ""}</div>
                            </div>
                            )
                          })}</></p>

                      </>
                      )
                    })}
                  </div>
                )
              })}
              {/* <div className="buttons-assment justify-content-between mt-4 d-flex">
                <button className=" rounded-0 d-block cancel-btn px-4 me-3"><i className="bi bi-arrow-left pe-2" />Previous</button>
                <button className=" rounded-0 text-black d-block px-4 continue-btn">Next <i className="bi bi-arrow-right ps-2" /></button>
              </div> */}
            </div>
            <div className="col-lg-4  col-md-12 mb-4 order-01">
              <div className="card py-2 bg-transparent rounded-0 border-0">
                <div className="mt-5 text-end">
                  <button className="btn btn-warning rounded-0  px-5" onClick={() => startInterView()}>Start Interview</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default InterviewQuestions;
