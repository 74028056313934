import React, { useEffect, useState } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import axios from "axios";
import UserHeader from "../partials/UserHeader";
import { Country, State, City } from "country-state-city";
import CompanyHeader from "../partials/CompanyHeader";
import Interview from "../student/Interview";
const APIURL = "https://job.creativewebpixel.in";

const CompanyInteriview = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const type = localStorage.getItem("type");
    if (type !== "company") {
      navigate("/user-dashboard");
    }
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }
  }, []);


  const [interviewType, setInterviewType] = useState("")

  const InterviewTypeSelect = (e) => {
    setInterviewType(e.target.value)


  }

  const [interviewcheckbox, setInterviewCheckbox] = useState("")
  const interviewCheckbox = (e) => {
    if (e.target.checked == true) {
      setInterviewCheckbox(e.target.value)
    }

    else {
      setInterviewCheckbox("")
    }
  }


  const [selectquestion, setSelectquestion] = useState("")
  const selectQuestionType = (e) => {
    setSelectquestion(e.target.value)
  }

  const [singlerightAnswer, setSingleRightAnswer] = useState([])
  const handleSingleQuestionChange = (e) => {

    setSingleRightAnswer(e.target.value);
  }

  const [multiplerightAnswer, setMultipleRightAnswer] = useState([])
  const handleMultipleQuestionChange = (name) => {
    if (multiplerightAnswer.includes(name)) {
      setMultipleRightAnswer(multiplerightAnswer.filter(item => item !== name));
    } else {
      setMultipleRightAnswer([...multiplerightAnswer, name]);
    }
  };


  const [customrightAnswer, setCustomRightAnswer] = useState([])

  const handleCustomQuestionChange = (e) => {
    setCustomRightAnswer(e.target.value);
  }


  const [BasicRoundCheckBox, setBasicRoundCheckBox] = useState([])
  const handleBasicRoundCheckBox = (name) => {
    if (BasicRoundCheckBox.includes(name)) {
      setBasicRoundCheckBox(BasicRoundCheckBox.filter(item => item !== name));
    } else {
      setBasicRoundCheckBox([...BasicRoundCheckBox, name]);
    }
  };


  const [questionformData, setQuestionFormData] = useState([]);

  const handleQuestionChange = (e) => {
    const { name, value } = e.target;


    setQuestionFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

  };

  const [questionTableData, setQuestionTableData] = useState([])

  const handleQuestionSubmit = () => {


    // let hello = questionformData
    // if (selectquestion == "single") {
    //   hello.rightAnswer = singlerightAnswer[0]
    // } else if (selectquestion == "multiple") {
    //   hello.rightAnswer = multiplerightAnswer
    // } else {
    //   hello.rightAnswer = customrightAnswer
    //   hello.question = hello.question.question
    // }
    // hello.questionType = selectquestion

    // console.log(hello)
    let questionData = questionformData
    if (selectquestion == "custom") {
      questionData = questionformData.question
    }

    const newData = {
      selectquestion, questionData,
      rightAnswer: selectquestion === 'single' ? singlerightAnswer : selectquestion == "custom" ? customrightAnswer : multiplerightAnswer,
    };
    setQuestionTableData([...questionTableData, newData])
  }

  // const [response, setResponse] = useState("");
  // const [loader, setLoader] = useState(false);

  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [timing, setTiming] = useState("");
  const [description, setDescription] = useState("");
  const [basicRoundStorage, setBasicRoundStorage] = useState("");



  const handleNextType = () => {
    const basicRound = [{ interviewType: interviewType, basicRoundCheckBox: BasicRoundCheckBox }]

    localStorage.setItem("basicRound", JSON.stringify(basicRound))
    if (localStorage.getItem("basicRound")) {
      setInterviewType("")
    }
    setBasicRoundStorage(localStorage.getItem("basicRound"))
  }


  function handleSubmit(e) {

    e.preventDefault();




    // console.log(inputSections);
    const data = {
      title: title,
      date: date,
      timing: timing,
      description: description,
      basicRound: [{ interviewType: interviewType, basicRoundCheckBox: BasicRoundCheckBox }],
    }
    console.log("data", data)

    // setLoader(true);
    // axios({
    //   url: `${APIURL}/userCompleteProfile`,
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     Accept: "application/json",
    //   },
    //   // data: formData,
    // })
    //   .then((response) => response.data)
    //   .then((res) => {
    //     console.log("resss", res);
    //     setResponse(res.message);
    //     setLoader(false);
    //     if (res.message == "profile updated") {
    //       setTimeout(() => {
    //         setLoader(false);
    //         navigate("/user-dashboard");
    //       }, 1000);
    //     }
    //   })
    //   .catch((error) => console.log(error));
  }

  const [activeMenuItem, setActiveMenuItem] = useState("information");
  const handleScroll = async (id) => {

    const element = document.getElementById(id);

    if (element) {
      console.log(element);
      const windowHeight = window.innerHeight;
      const elementHeight = element.offsetHeight;
      const elementOffset = element.offsetTop;
      const scrollPosition = elementOffset - (windowHeight - elementHeight) / 2;

      await window.scrollTo({ top: scrollPosition, behavior: "smooth" });
      await setActiveMenuItem(id);
    }
  };
  useEffect(() => {
    if (handleScroll()) {
      const scrollToSection = async () => {
        const sections = document.querySelectorAll(".scrollsection");
        const scrollPosition = window.scrollY;

        await sections.forEach((section) => {

          const sectionTop = section.offsetTop - 350;
          const sectionHeight = section.offsetHeight;


          if (
            scrollPosition >= sectionTop &&
            scrollPosition < sectionTop + sectionHeight
          ) {
            setActiveMenuItem(section.id);
          }
        });
      };

      // Call the scrollToSection  function on initial mount
      // scrollToSection ();

      // Attach scroll event listener when component mounts
      window.addEventListener("scroll", scrollToSection);

      // Cleanup: remove event listener when component unmounts
      return () => {
        window.removeEventListener("scroll", scrollToSection);
      };
    }
  }, []);



  const data = ["fgfg", "dfdd", "hhhhhh"]




  return (
    <div style={{ background: "#F5F5F5" }}>
      <div>
        <CompanyHeader />

        {/* ======= header end ===== */}
        <section className="information-data border-bottom  pb-5">
          <div className="container">
            <div className="row   information-linkes">
              <div className="col-md-12 usertophead">
                <h3 className="mt-4">Hi,</h3>
                <p className="pb-3">Update your Interview.</p>
                <hr />
              </div>
              <div className="col-md-3 ">
                <div className="row position-sticky bg-1 main-head-bar "></div>
                <div
                  className="information-link position-sticky bg-1 list-group"
                  id="list-example"
                >
                  <Link
                    to=""
                    onClick={() => handleScroll("information")}
                    className={
                      activeMenuItem == "information"
                        ? " fw-semibold"
                        : "fw-semibold text-black"
                    }
                  >
                    Your Information
                  </Link>
                  <hr className="w-75" />
                  <Link
                    to=""
                    onClick={() => handleScroll("summary")}
                    className={
                      activeMenuItem == "summary"
                        ? "fw-semibold mb-3 d-block "
                        : " fw-semibold mb-3 d-block text-black"
                    }
                  >
                    Summary
                  </Link>
                  <Link
                    to=""
                    onClick={() => handleScroll("professional")}
                    className={
                      activeMenuItem == "professional"
                        ? "fw-semibold mb-3 d-block "
                        : " fw-semibold mb-3 d-block text-black"
                    }
                  >
                    Job History
                  </Link>
                  <Link
                    to=""
                    onClick={() => handleScroll("education")}
                    className={
                      activeMenuItem == "education"
                        ? "fw-semibold mb-3 d-block "
                        : " fw-semibold mb-3 d-block text-black"
                    }
                  >
                    Education History
                  </Link>
                  <Link
                    to=""
                    onClick={() => handleScroll("skills")}
                    className={
                      activeMenuItem == "skills"
                        ? "fw-semibold mb-3 d-block "
                        : " fw-semibold mb-3 d-block text-black"
                    }
                  >
                    Skills
                  </Link>
                  <hr className="w-75" />
                  <Link
                    to=""
                    onClick={() => handleScroll("settings")}
                    className={
                      activeMenuItem == "settings"
                        ? "fw-semibold mb-3 d-block "
                        : " fw-semibold mb-3 d-block text-black"
                    }
                  >
                    Confirmation &amp; settings
                  </Link>
                </div>
              </div>
              <div className="col-md-9 mt-5">
                <h4 className="pb-4" id="information">
                  Your Information
                </h4>


                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label fw-medium"
                        >
                          Interview Title
                        </label>
                        <input
                          // disabled
                          // value={name}
                          type="text"
                          onChange={(e) => setTitle(e.target.value)}
                          className="form-control form-in bg-1 rounded-0 "
                          id="exampleFormControlInput1"
                          placeholder="Enter your name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label fw-medium "
                          >
                            Date
                          </label>
                          <input

                            type="date"
                            onChange={(e) => setDate(e.target.value)}
                            className="form-control form-in bg-1 rounded-0 "
                            id="exampleFormControlInput1"
                            placeholder="Enter your name"
                            required
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label fw-medium "
                          >
                            Time
                          </label>
                          <input

                            type="time"
                            onChange={(e) => setTiming(e.target.value)}
                            className="form-control form-in bg-1 rounded-0 "
                            id="exampleFormControlInput1"
                            placeholder="Enter your name"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label fw-medium "
                        >
                          Description
                        </label>
                        <textarea
                          // disabled
                          // value={email}

                          onChange={(e) => setDescription(e.target.value)}
                          className="form-control form-in bg-1 rounded-0 "
                          id="exampleFormControlInput1"
                          placeholder="Enter your Description"
                          required
                        />
                      </div>
                    </div>



                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label fw-medium "
                        >
                          Interview Type
                        </label>
                        <select
                          // disabled
                          // value={email}
                          onChange={InterviewTypeSelect}
                          className="form-select form-in bg-1 rounded-0 "
                          id="exampleFormControlInput1"
                          required
                        >


                          <option value="" disabled selected hidden>Select Type</option>

                          {basicRoundStorage == "" ?
                            <option value="basic-round">Basic Round</option> : ""}
                          <option value="hr">HR</option>
                          <option value="interview">Interview</option>
                          <option value="assignment" >Assignment</option>
                          <option value="live-interview">Live Interview</option>

                        </select>
                      </div>
                    </div>








                    {interviewType == "basic-round" ? <div className="col-md-12 mt-3">
                      <h4 className="my-3  scrollsection" id="settings">
                        Basic Round
                      </h4>
                      {data.map((item, i) => {
                        return (

                          <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                            <input
                              className="form-check-input p-2 bg-1 me-2 rounded-0"
                              type="checkbox"
                              checked={BasicRoundCheckBox.includes(item)}
                              onChange={() => handleBasicRoundCheckBox(item)}
                              name={item}
                            />  <span>{item}</span>

                          </div>

                        )
                      })}








                    </div> : ""}


                    {interviewType == "hr" ? <div className="col-md-12 mt-3">
                      <h4 className="my-3  scrollsection" id="settings">
                        HR Round
                      </h4>
                      <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                        <input
                          className="form-check-input p-2 bg-1 me-2 rounded-0"
                          type="checkbox"
                          defaultValue
                          id="flexCheckDefault"
                        />  <span>gfhfhfghgfhfg</span>

                      </div>

                      <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                        <input
                          className="form-check-input p-2 bg-1 me-2 rounded-0"
                          type="checkbox"
                          defaultValue
                          id="flexCheckDefault"
                        />  <span>gfhfhfghgfhfg</span>

                      </div>

                      <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                        <input
                          className="form-check-input p-2 bg-1 me-2 rounded-0"
                          type="checkbox"
                          defaultValue
                          id="flexCheckDefault"
                        />  <span>gfhfhfghgfhfg</span>

                      </div>






                    </div> : ""}


                    {interviewType == "interview" ? <div className="col-md-12 mt-3">
                      <h4 className="my-3  scrollsection" id="settings">
                        Interview
                      </h4>
                      <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                        <input
                          className="form-check-input p-2 bg-1 me-2 rounded-0"
                          type="checkbox"
                          onChange={interviewCheckbox}
                          value="auto-fill"
                          checked={interviewcheckbox == "auto-fill" ? true : false}
                          id="flexCheckDefault"
                        />  <span>Auto fill Question</span>

                      </div>

                      <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                        <input
                          className="form-check-input p-2 bg-1 me-2 rounded-0"
                          type="checkbox"
                          value="manually"
                          checked={interviewcheckbox == "manually" ? true : false}
                          onChange={interviewCheckbox}
                          id="flexCheckDefault"
                        />  <span>Manually</span>

                      </div>
                    </div> : ""}

                    {interviewcheckbox == "auto-fill" && interviewType == "interview" ? <>  <h4 className="my-3">
                      Auto Fill Round
                    </h4> <div className="col-md-12">
                        <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                          <input
                            className="form-check-input p-2 bg-1 me-2 rounded-0"
                            type="checkbox"
                            value="manually"
                            checked={interviewcheckbox == "manually" ? true : false}
                            onChange={interviewCheckbox}
                            id="flexCheckDefault"
                          />  <span>Manually</span>


                        </div>
                        <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                          <input
                            className="form-check-input p-2 bg-1 me-2 rounded-0"
                            type="checkbox"
                            value="manually"
                            checked={interviewcheckbox == "manually" ? true : false}
                            onChange={interviewCheckbox}
                            id="flexCheckDefault"
                          />  <span>Manually</span>


                        </div>
                        <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                          <input
                            className="form-check-input p-2 bg-1 me-2 rounded-0"
                            type="checkbox"
                            value="manually"
                            checked={interviewcheckbox == "manually" ? true : false}
                            onChange={interviewCheckbox}
                            id="flexCheckDefault"
                          />  <span>Manually</span>


                        </div>
                        <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                          <input
                            className="form-check-input p-2 bg-1 me-2 rounded-0"
                            type="checkbox"
                            value="manually"
                            checked={interviewcheckbox == "manually" ? true : false}
                            onChange={interviewCheckbox}
                            id="flexCheckDefault"
                          />  <span>Manually</span>


                        </div>
                        <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                          <input
                            className="form-check-input p-2 bg-1 me-2 rounded-0"
                            type="checkbox"
                            value="manually"
                            checked={interviewcheckbox == "manually" ? true : false}
                            onChange={interviewCheckbox}
                            id="flexCheckDefault"
                          />  <span>Manually</span>


                        </div>
                      </div> </> : ""}

                    {interviewcheckbox == "manually" && interviewType == "interview" ?

                      <div>
                        <h4 className="my-3">
                          Manually
                        </h4>
                        <div className="col-md-12 text-end mb-3">
                          <Link
                            to="" type="button"
                            class="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal11"
                          >
                            Add
                          </Link>
                        </div>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Questions</th>
                                <th>Option A</th>
                                <th>Option B</th>
                                <th>Option C</th>
                                <th>Option D</th>
                                <th>Right Answer</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {questionTableData.map((item, i) => {
                                console.log(item)
                                return (
                                  <tr>
                                    <td>#</td>
                                    {item.selectquestion == "custom" ? <><td>{item.questionData}</td>
                                      <td className="fw-bold bg-light">Right Answer</td>
                                      <td colSpan="4">{item.rightAnswer}</td>
                                    </> : <><td>{item.questionData.question}</td>
                                      <td>{item.questionData.optionA}</td>
                                      <td>{item.questionData.optionB}</td>
                                      <td>{item.questionData.optionC}</td>
                                      <td>{item.questionData.optionD}</td>
                                      <td>{item.rightAnswer + ","}</td>
                                    </>}
                                    <td>Action</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div class="modal fade" id="exampleModal11" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h1 class="modal-title fs-5" id="exampleModalLabel">Add Question</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div class="modal-body">
                                <form action="" onSubmit={handleQuestionSubmit}>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label fw-medium mt-3"
                                      >
                                        Question Type
                                      </label>
                                      <select name="" id="" onChange={selectQuestionType} required className="form-select form-in bg-1 rounded-0">

                                        <option value="" selected hidden disabled> Select Type</option>
                                        <option value="single">Single Select</option>
                                        <option value="multiple">Multiple Select</option>
                                        <option value="custom">Custom Select</option>

                                      </select>
                                    </div>



                                    <div className="col-md-12">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label fw-medium mt-3"
                                      >
                                        Question
                                      </label>
                                      <input name="question" id="" onChange={handleQuestionChange} required placeholder="Enter your question" className="form-control form-in bg-1 rounded-0 " />
                                    </div>
                                    {selectquestion !== "custom" ? <>
                                      <div className="col-md-6">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label fw-medium mt-3"
                                        >
                                          Option A
                                        </label>
                                        <input name="optionA" id="" onChange={handleQuestionChange} placeholder="Enter your option" className="form-control form-in bg-1 rounded-0 " />
                                      </div>
                                      <div className="col-md-6">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label fw-medium mt-3"
                                        >
                                          Option B
                                        </label>
                                        <input name="optionB" id="" onChange={handleQuestionChange} placeholder="Enter your option" className="form-control form-in bg-1 rounded-0 " />
                                      </div>
                                      <div className="col-md-6">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label fw-medium mt-3"
                                        >
                                          Option C
                                        </label>
                                        <input name="optionC" onChange={handleQuestionChange} id="" placeholder="Enter your option" className="form-control form-in bg-1 rounded-0 " />
                                      </div>
                                      <div className="col-md-6">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label fw-medium mt-3"
                                        >
                                          Option D
                                        </label>
                                        <input name="optionD" onChange={handleQuestionChange} id="" placeholder="Enter your option" className="form-control form-in bg-1 rounded-0 " />
                                      </div>
                                    </>
                                      : ""}
                                    {selectquestion == "single" ?
                                      <div className="col-md-12">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label fw-medium mt-3"
                                        >
                                          Choose Your right Answer
                                        </label>
                                        <select name="rightAnswer" onChange={handleSingleQuestionChange} required id="" className="form-select form-in bg-1 rounded-0 ">

                                          <option value="" selected hidden disabled> Select Your right Answer</option>
                                          <option value="optionA">optionA</option>
                                          <option value="optionB">optionB</option>
                                          <option value="optionC">optionC</option>
                                          <option value="optionD">optionD</option>

                                        </select>
                                      </div>
                                      : ""}

                                    {selectquestion == "multiple" ?
                                      <div className="col-md-12">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label fw-medium mt-3"
                                        >
                                          Choose Your right Answer
                                        </label>
                                        <br />
                                        <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                                          <input
                                            className="form-check-input p-2 bg-1 me-2"
                                            type="checkbox"
                                            value="manually"

                                            name="optionA" onChange={(e) => handleMultipleQuestionChange(e.target.name)}
                                            id="flexCheckDefault"
                                          />  <span>optionA</span>

                                        </div>
                                        <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                                          <input
                                            className="form-check-input p-2 bg-1 me-2 "
                                            type="checkbox"
                                            value="manually"

                                            name="optionB" onChange={(e) => handleMultipleQuestionChange(e.target.name)}
                                            id="flexCheckDefault"
                                          />  <span>optionB</span>

                                        </div>
                                        <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                                          <input
                                            className="form-check-input p-2 bg-1 me-2 "
                                            type="checkbox"
                                            value="manually"

                                            name="optionC" onChange={(e) => handleMultipleQuestionChange(e.target.name)}
                                            id="flexCheckDefault"
                                          />  <span>optionC</span>

                                        </div>

                                        <div className="tag btn bg-secondary pe-4 me-3 my-2 text-white position-relative">
                                          <input
                                            className="form-check-input p-2 bg-1 me-2 "
                                            type="checkbox"
                                            value="manually"

                                            name="optionD" onChange={(e) => handleMultipleQuestionChange(e.target.name)}
                                            id="flexCheckDefault"
                                          />  <span>optionD</span>

                                        </div>
                                      </div>
                                      : ""}


                                    {selectquestion == "custom" ?
                                      <div className="col-md-12">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label fw-medium mt-3"
                                        >
                                          Enter Your Answer
                                        </label>
                                        <br />
                                        <textarea name="rightAnswer" onChange={handleCustomQuestionChange} id="" placeholder="Enter Your Detail" className="form-control form-in bg-1 rounded-0 "></textarea>

                                      </div>
                                      : ""}






                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" onClick={(e) => handleQuestionSubmit(e.preventDefault())} class="btn btn-primary">Save changes</button>
                                  </div>
                                </form>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      : ""}



                    {interviewType == "assignment" ? <> <h4 className="my-3">Assignment</h4> <div className="col-md-12">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium mt-3"
                      >
                        Note
                      </label>
                      <br />
                      <input name="" id="" placeholder="Enter Your Description" className="form-control form-in bg-1 rounded-0 " />
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium mt-3"
                      >
                        Description
                      </label>
                      <br />
                      <textarea name="" id="" placeholder="Enter Your Description" className="form-control form-in bg-1 rounded-0 "></textarea>

                    </div> </> : ""}


                    {interviewType == "live-interview" ? <> <h4 className="my-3">Live Interview</h4> <div className="col-md-12">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium mt-3"
                      >
                        Meeting Url
                      </label>
                      <br />
                      <input name="" type="url" id="" placeholder="Enter Your Description" className="form-control form-in bg-1 rounded-0 " />


                    </div> </> : ""}


                    {/* ============== */}




                    {/* =========== */}




                    {/* ======== */}



                    {/* ===== */}
                    <div className="col-md-12 mt-3">




                      <div className="form-end">
                        <div className="row">
                          <div className="col-md-5">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit.&nbsp;
                            </p>
                          </div>
                          <div className="col-md-7 text-end ">
                            <button className=" form-ebtn1  border-0 rounded-0 me-2  ">
                              {" "}
                              Cancel
                            </button>
                            <button type="submit" className=" form-ebtn2  border-0 rounded-0 me-2 ">
                              {" "}
                              Preview as a Candidate
                            </button>
                            {/* {loader ? ( */}
                            {/* <button
                              type="submit"
                              className=" form-ebtn3  border-0 rounded-0 mb-2 position-relative"
                              disabled
                            >
                              <span className="opacity-0">
                                {" "}
                                Save &amp; Publish
                              </span>
                              <div
                                className="spinner-border position-absolute"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  right: "60px",
                                }}
                                role="status"
                              >
                                <span class="visually-hidden ">
                                  Loading...
                                </span>
                              </div>
                            </button> */}
                            {/* ) : (*/}
                            <button
                              onClick={(e) => handleNextType(e.preventDefault())}
                              className=" form-ebtn3  border-0 rounded-0 mb-2 "
                            >
                              {" "}
                              Save &amp; Publish
                            </button>
                            {/* )} */}
                            {/*<h4>{response}</h4> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="footer-bar footer-bg ">
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-end">
                  <p className="m-0 p-0">
                    <span className="pe-5">Terms and Conditions</span>
                    <span>Privacy and Policy</span>
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </div>


    </div>


  );
};

export default CompanyInteriview;
