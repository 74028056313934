import React, { useEffect, useState } from "react";
import CompanyHeader from "../partials/CompanyHeader";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const APIURL = "https://job.creativewebpixel.in";

const CompanyProfile = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const type = localStorage.getItem("type")
    if (type !== "company") {
      navigate("/user-dashboard");
    }
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }

  }, [])

  const [CompanyName, setCompanyName] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [companyProfile, setCompanyProfile] = useState("");
  const [avtar, setAvtar] = useState("");
  const [avtarName, setAvtarName] = useState("");
  const [image, setImage] = useState("");

  const uploadImg = (e) => {
    setAvtar(e.target.files[0])
    console.log(e.target.files[0])
    if (e.target.files[0]) {
      setAvtarName(e.target.files[0].name)
      const reader = new FileReader();

      reader.onloadend = () => {
        setImage(reader.result);
      };

      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    } else {
      return
    }
  }


  useEffect(() => {
    const userId = localStorage.getItem("email");

    axios({
      url: `${APIURL}/userDetails`,
      method: "GET",

      headers: {
        Authorization: `${userId}`,
      },
    })
      .then((response) => response.data)
      .then((res) => {
        console.log("userdetails", res.message[0]);
        setCompanyName(res.message[0].companyName);
        setCompanyUrl(res.message[0].companyUrl);
        setCompanyProfile(res.message[0].companyLogo);

      });
  }, []);

  const [response, setResponse] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = localStorage.getItem("email");

    const formData = new FormData();
    formData.append("companyName", CompanyName);
    formData.append("companyUrl", companyUrl);
    formData.append("email", email);
    formData.append("avtar", avtar);

    axios({
      url: `${APIURL}/companyUpdateProfile`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      data: formData,
    })
      .then((response) => response.data)
      .then((res) => {
        console.log("resss", res.message);
        setResponse(res.message);
        if (res.message == "profile updated") {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => console.log(error));
  };


  const [activeMenuItem, setActiveMenuItem] = useState('public_profile');

  useEffect(() => {
    const handleScroll = () => {
      // Get all section elements by their IDs
      const sections = document.querySelectorAll('h4[id]');


      sections.forEach((section) => {
        const sectionId = section.getAttribute('id');
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        const scrollPosition = window.scrollY;

        // Determine if the section is in the viewport
        if (
          scrollPosition >= sectionTop - 200 && // Adjust this value to fine-tune activation point
          scrollPosition < sectionTop + sectionHeight - 200
        ) {
          setActiveMenuItem(sectionId);
          console.log(scrollPosition, "=====", sectionTop - 100)
          console.log(scrollPosition, "=====", sectionTop + sectionHeight - 100)
        }
      });


    };

    // Attach scroll event listener when component mounts
    window.addEventListener('scroll', handleScroll);

    // Cleanup: remove event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div style={{ background: "#F5F5F5" }}>
      <CompanyHeader />
      <section className="information-data border-bottom  pb-5">
        <div className="container">
          <div className="row   information-linkes">
            <div className="col-md-12 usertophead">
              <h3 className="mt-4">Company Profile</h3>
              <p className="pb-3">Update your company photo and details hear.</p>
              <hr />
            </div>
            <div className="col-md-3 ">
              <div className="row position-sticky bg-1 main-head-bar ">

              </div>
              <div className="information-link position-sticky bg-1">
                <a href="#public_profile" className={activeMenuItem == "public_profile" ? "fw-semibold mb-3 d-block " : " fw-semibold mb-3 d-block text-black"}>
                  Public Profile
                </a>
                <a href="#company_logo" className={activeMenuItem == "company_logo" ? "fw-semibold mb-3 d-block " : " fw-semibold mb-3 d-block text-black"}>
                  Company Logo                </a>


              </div>
            </div>
            
            <div className="col-md-9">
            <h4 id="public_profile" style={{ visibility: "hidden" }} className="m-0"></h4>

              <form onSubmit={handleSubmit}>
                <h4 className="pb-4" id="information">
                  Public Profile
                </h4>

                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium"
                      >
                        Company Name
                      </label>
                      <input
                        value={CompanyName}
                        type="text"
                        onChange={(e) => setCompanyName(e.target.value)}
                        className="form-control form-in bg-1 rounded-0 "
                        id="exampleFormControlInput1"
                        placeholder="i.e. John Doe"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium "
                      >
                        Company Website
                      </label>
                      <input
                        value={companyUrl}
                        type="url"
                        onChange={(e) => setCompanyUrl(e.target.value)}
                        className="form-control form-in bg-1 rounded-0 "
                        id="exampleFormControlInput1"
                        placeholder="i.e. John Doe"
                        required
                      />
                    </div>
                  </div>
                  <label htmlFor="#" className="mb-2">
                    Company Logo
                  </label>
                  <h4 id="company_logo" style={{ visibility: "hidden" }} className="m-0"></h4>

                  {/* <div className="uplaod-file mb-4">
                  <div className="drop_box">
                    <input
                      onChange={(e) => setAvtar(e.target.files[0])}
                      type="file"
                      accept=""
                      id="fileID"

                    />
                    <button className="btn p-0">
                      {companyProfile == "" || companyProfile == null ?
                        <img
                          src="images/image-upload.png"
                          alt=""
                          className="img-fluid"
                        /> :
                        <img
                          style={{ width: "200px" }}
                          src={`${APIURL}/img/${companyProfile}`}
                        />
                      }
                    </button>
                  </div>

                </div> */}
                  <div className="uplaod-file mb-4">
                    <div className="drop_box row">
                      <button className="btn p-0 col-md-4">
                        {companyProfile == "" || companyProfile == null ?
                          <img
                            src="images/image-upload.png"
                            alt=""
                            className="img-fluid"
                          /> : image ? <img
                            style={{ width: "200px" }}
                            src={image}
                          /> :
                            <img
                              style={{ width: "200px" }}
                              src={`${APIURL}/img/${companyProfile}`}
                            />
                        }
                      </button>
                      <div className="file-upload-box col-md-8">
                        <input type="file" id="fileupload" className="file-upload-input" onChange={uploadImg} multiple />


                        <p className="text-center"><p>      <button className="btn p-0"><img src="images/upload-file-icon.png" alt className="img-fluid" /></button><p className="pt-3">{avtarName}</p></p> <span className="file-link">Click to upload </span>or drag and drop SVG, PNG, JPG or GIF (max. 800x400px)</p>
                      </div>

                    </div>

                  </div>


                  {/* ===== */}
                  <div className="col-md-12 mt-3">
                    <div className="form-end">
                      <div className="row">
                        <div className="col-md-12 text-end ">
                          <button
                            type="submit"
                            className=" form-ebtn3  border-0 rounded-0 mb-2 "
                          >
                            {" "}
                            Submit Profile
                          </button>
                          <h5>
                            {response}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </div>
  );
};

export default CompanyProfile;
