import React, { useEffect, useState } from "react";
import Header from "../partials/UserHeader";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate()
  const APIURL = "https://job.creativewebpixel.in";

  const [userMesaage, setUserMessage] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loader, setLoader] = useState(false);

  function loginFormSubmit() {
    const data = {
      loginEmail,
      loginPassword,
    };

    console.log(data);

    setLoader(true);
    fetch(`${APIURL}/userLogin`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json(console.log(response)))
      .then((res) => {
        console.log("resss", res);

        setUserMessage(res.message);
        setLoader(false);
        if (res.message == "successfully login") {
          setLoader(false);
          localStorage.setItem("email", res.data.email);
          localStorage.setItem("type", res.data.type);
          localStorage.setItem("userId", res.data.id);
          if (res.data.type == "student") {
            navigate("/user-dashboard")
          } else {
            navigate("/company-dashboard")
          }
        }
      })
      .catch((error) => console.log(error));
  }

  const [view, setView] = useState(false)

  const viewPassword = () => {
    if (view == false) {
      setView(true)
    } else {
      setView(false)
    }

  }
  return (
    <>

      {/* <section>

                <div className='container mt-5'>

                    <div className='row'>
                     




                        <div className='col-md-6 mt-5'>
                            <h3>Login Form</h3>
                            <form onSubmit={(e) => loginFormSubmit(e.preventDefault())}>


                                <div className="mb-3">
                                    <label className="form-label">Email address</label>
                                    <input required onChange={(event) => setLoginEmail(event.target.value)} type="email" className="form-control" placeholder="enter your email address" />
                                </div>


                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Password</label>
                                    <input required onChange={(event) => setLoginPassword(event.target.value)} type="password" className="form-control" placeholder='Enter your password' />
                                </div>

                                <button type="submit" className="btn btn-primary">Submit</button>
                                <p style={{ color: "red" }}>{userMesaage}</p>

                            </form>

                            <div>

                            </div>

                        </div>


                    </div>

                </div>


            </section> */}

      <div className="modal-body p-0">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-5  p-0 position-relative hide4">
              <img src="images/img/login.jpg"  style={{ transform: "rotateY(180deg)" }} className="img-fluid " />
              <div className="bg-color7 w-75 m-auto ps-5 py-4 position-absolute position2 hide4">
                <button className="bg-color6 border-0 fs-6 p-2 rounded-2 text-white">
                  {" "}
                  <i className="bi bi-hand-thumbs-up-fill text-color4 pe-2" />
                  Top Notch Stock Resources
                </button>
                <p className="pt-3 text-white w-75">
                Welcome back! Please enter your credentials to access your account and unlock a world of possibilities.
                </p>
              </div>
            </div>
            <div className="col-md-7 px-5 mb-3">
              <form
                onSubmit={(e) => loginFormSubmit(e.preventDefault())}
                className=" form-login"
              >
                <div className="pb-4 text-center">
                  <h1 className="fw-semibold">Welcome</h1>
                  <p className="text-black">Login into your account</p>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email
                  </label>
                  <input
                    required
                    onChange={(event) => setLoginEmail(event.target.value)}
                    type="email"
                    className="form-control rounded-0 py-3"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="User5643"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Password
                  </label>
                  <div className=" position-relative d-flex align-items-center">
                    <input
                      required
                      onChange={(event) => setLoginPassword(event.target.value)}
                      type={view == false ? "password" : "text"}
                      className="form-control rounded-0 py-3"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="*************"
                    />
                    <i className={view ? "fa-regular fa-eye-slash position-absolute" : "fa-regular fa-eye position-absolute"} onClick={() => viewPassword()} style={{ right: "15px", cursor: "pointer" }}></i>
                  </div>
                </div>
                <div className="mb-3 form-check d-flex justify-content-between">
                  <div className>
                    <input
                      required
                      type="checkbox"
                      className="form-check-input rounded-0"
                      id="exampleCheck1"
                    />
                    <label
                      className="form-check-label  "
                      htmlFor="exampleCheck1"
                    >
                      Remind me
                    </label>
                  </div>
                  <div className>
                    <Link to="/forget-password" className="text-danger">
                      Forgot Password?
                    </Link>
                  </div>
                </div>
                {loader ? (
                  <button
                    type="submit"
                    className="btn bg-color1 rounded-0 btn2 py-2 hire border-0 position-relative"
                    disabled
                  >
                    {" "}
                    <div
                      className="spinner-border"
                      style={{ width: "25px", height: "25px", right: "150px" }}
                      role="status"
                    >
                      <span class="visually-hidden ">Loading...</span>
                    </div>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn bg-color1 rounded-0 btn2 py-2 hire "
                  >
                    Login
                  </button>
                )}

                {userMesaage == "successfully login" ? (
                  <p
                    style={{ color: "#FFCD4D", textAlign: "center", margin: 0 }}
                  >
                    {userMesaage}
                  </p>
                ) : (
                  <p style={{ color: "red", textAlign: "center", margin: 0 }}>
                    {userMesaage}
                  </p>
                )}

                <p className="text-center pt-4">
                  no have Account? <br />{" "}
                  <div
                    className="btn-group btn-group-toggle"
                    data-toggle="buttons"
                  >
                    <label className="btn btn-secondary ">
                      <Link to="/company-signup" className="text-white">
                        Company
                      </Link>
                    </label>
                    <label className="btn btn-secondary">
                      <Link to="/candidate-signup" className="text-white">
                        Candidate
                      </Link>{" "}
                    </label>
                  </div>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <LoginSocialGoogle
                  appId="1420094275258574"
                  onResolve={(response) => {
                    console.log("response", response);
                  }}
                  onReject={(error) => {
                    console.log("error", error);
                  }}
                >
                  <GoogleLoginButton />
                </LoginSocialGoogle> */}
    </>
  );
};

export default Login;
