import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import errorImg from "../../css/images/Oops404-Error-with-a-broken-robot.gif"

const NewUpdatePassword = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const [urlCheck, setUrlCheck] = useState([]);

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    console.log(params.token)
    console.log(params.email)





    const email = params.email;
    const token = params.token;

    fetch("https://job.creativewebpixel.in/checkForgetPasswordUrl", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ email: email, token: token }),
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setUrlCheck("url correct");
        console.log(json.message);
        // if(json.message=="url incorrect"){
        //     navigate("/")
        // }
      })
      .catch((err) => { });
  }, []);



  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");


  const [updatemessage, setupdateMessage] = useState("");


  const [loader, setLoader] = useState(false);


  const updateSubmit = (event) => {
    event.preventDefault();
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    const updateobject = {
      password: newpassword,
      email: params.email

    };


    if (newpassword !== confirmpassword) {
      setMatchPassword("Password does not matched")
    }

    console.log(updateobject);
    // add entity - POST
    // e.preventDefault();
    // creates entity

    if (newpassword.trim() == "" ||
      confirmpassword.trim() == "" ||
      newpassword !== confirmpassword
    ) {
      return;
    } else {
      setLoader(true);
      fetch("https://job.creativewebpixel.in/updatePassword", {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(updateobject),
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          setupdateMessage(json.message);
          setLoader(false)

          if (json.message == "password successfully update") {

            setTimeout(() => {

              navigate("/login");
            }, 1000);
          }


        })
        .catch((err) => {
          console.log(err);
        });

    }
  };





  const [view, setView] = useState(false)

  const viewPassword = () => {
    if (view == false) {
      setView(true)
    } else {
      setView(false)
    }

  }

  const [view2, setView2] = useState(false)

  const viewPassword2 = () => {
    if (view2 == false) {
      setView2(true)
    } else {
      setView2(false)
    }

  }

  return (
    <div>
      {urlCheck == "url incorrect" ? (
        <div className='container'>
          <div className="row my-5 align-items-center justify-content-center">


            <div className="col-md-5">
              <div className="custom-form">
                <img src={errorImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="modal-body p-0">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-5  p-0 position-relative hide4">
                <img src="images/img/login.jpg" alt className="img-fluid " />
                <div className="bg-color7 w-75 m-auto ps-5 py-4 position-absolute position2 hide4">
                  <button className="bg-color6 border-0 fs-6 p-2 rounded-2 text-white">
                    {" "}
                    <i className="bi bi-hand-thumbs-up-fill text-color4 pe-2" />
                    Top Notch Stock Resources
                  </button>
                  <p className="pt-3 text-white w-75">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Integer mattis quis est ut pulvinar. Quisque semper.
                  </p>
                </div>
              </div>
              <div className="col-md-7 px-5 mb-3">
                <form
                  onSubmit={updateSubmit}
                  className=" form-login"
                >
                  <div className="pb-4 text-center">
                    <h1 className="fw-semibold">Welcome</h1>
                    <p className="text-black">Update your Password</p>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      New Password
                    </label>
                    <div className=" position-relative d-flex align-items-center">

                      <input
                        onChange={(event) => setNewPassword(event.target.value)}
                        type={view == false ? "password" : "text"}
                        className="form-control rounded-0 py-3"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="New Password"
                        required
                      />
                      <i className={view ? "fa-regular fa-eye-slash position-absolute" : "fa-regular fa-eye position-absolute"} onClick={() => viewPassword()} style={{ right: "15px", cursor: "pointer" }}></i>

                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Confirm Password
                    </label>
                    <div className=" position-relative d-flex align-items-center">
                      <input
                        onChange={(event) => setConfirmPassword(event.target.value)}
                        type={view2 == false ? "password" : "text"}
                        className="form-control rounded-0 py-3"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Confirm Password"
                        required
                      />
                      <i className={view2 ? "fa-regular fa-eye-slash position-absolute" : "fa-regular fa-eye position-absolute"} onClick={() => viewPassword2()} style={{ right: "15px", cursor: "pointer" }}></i>

                    </div></div>



                  {loader ? (
                    <button
                      type="submit"
                      className="btn bg-color1 rounded-0 btn2 py-2 hire border-0 position-relative"
                      disabled
                    >
                      {" "}
                      <div
                        className="spinner-border"
                        style={{ width: "25px", height: "25px", right: "150px" }}
                        role="status"
                      >
                        <span class="visually-hidden ">Loading...</span>
                      </div>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn bg-color1 rounded-0 btn2 py-2 hire "
                    >
                      Change Password
                    </button>
                  )}

                  {updatemessage == "password successfully update" ? (
                    <p
                      style={{ color: "#FFCD4D", textAlign: "center", margin: 0 }}
                    >
                      {updatemessage}
                    </p>
                  ) : matchPassword ? <p style={{ color: "red", textAlign: "center", margin: 0 }}>
                    {matchPassword}
                  </p> : (
                    <p style={{ color: "red", textAlign: "center", margin: 0 }}>
                      {updatemessage}
                    </p>
                  )}

                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NewUpdatePassword
