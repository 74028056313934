import React, { useEffect, useState } from "react";
import UserHeader from "../partials/UserHeader";
import { Link, useNavigate } from "react-router-dom";
const APIURL = "https://job.creativewebpixel.in";

const AppliedJobs = () => {
  const [allJob, setAllJob] = useState([]);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    fetch(`${APIURL}/getAllApplyedJob`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `${userId}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response.message);
        setAllJob(response.message.sort().reverse());
      });
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    const type = localStorage.getItem("type");
    if (type !== "student") {
      navigate("/company-dashboard")
    }
 const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }
  }, []);
  return (
    <div>
      <UserHeader />
      <div className="bg-1">
        <section className="find-job  bg-1 ">
          <div className="bg-1 company-group   w-100  z-1">
            <div className="container">
              <div className="row   ">
                <div className="col-md-7">
                  <h3 className="mt-30">Applied Jobs</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabIndex={0}
              >
                <div className="row mt-4">
                  {allJob.map((item, i) => {
                    console.log(item);
                    return (
                      <div key={i} className="col-md-4 mb-4 ">
                        <div className="card rounded-0 border-0 p-2">
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                              <div className="compny-img">
                                <img
                                  src={
                                    APIURL +
                                    "/img/" +
                                    item.companyId.companyLogo
                                  }
                                  className="w-100"
                                  data-toggle={item.id} data-placement="top" title={item.companyDetails.companyName}
                                />
                              </div>
                              <button
                                type="button"
                                className=" job-btn border-0 rounded-pill "
                              >
                                {item.jobId.jobType}
                              </button>
                            </div>
                            <Link to={`/job-details/${item.jobId.id}`}>
                              {" "}
                              <h4 className="mt-3 fw-semibold mb-1">
                                {item.jobId.jobTitle}
                              </h4>
                            </Link>
                            <p className="mb-3">{item.companyId.companyName}</p>
                            <div className="price  d-flex ">
                              <span className="money">
                                <img src="images/money-bag 1.png" alt />
                              </span>
                              <span className="fs-16   ps-3">
                                {item.jobId.salleryMin}- {item.jobId.salleryMax}
                              </span>
                            </div>
                            <div className="location align-items-center justify-content-between d-flex mt-3">
                              <div className>
                                <span className="money">
                                  <img src="images/locationicon.png " alt />
                                </span>
                                <span className=" ps-3">
                                  {item.jobId.jobLocation}
                                </span>{" "}
                                <span></span>
                              </div>
                            </div>
                            <button
                              className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4"
                              style={{ cursor: "auto" }}
                            >
                              Applied
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile-tab-pane"
                role="tabpanel"
                aria-labelledby="profile-tab"
                tabIndex={0}
              >
                <div className="row mt-4">
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img
                              src="images/Pathao_-_Here_with_You 1.png"
                              alt
                            />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Jr. Product Designer
                        </h4>
                        <p className="mb-3">Pathao limited</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c2.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Growth Product Designer
                        </h4>
                        <p className="mb-3">Nike</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c3.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Digital Product Designer
                        </h4>
                        <p className="mb-3">JPMorgan Chase Bank</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c4.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Sr. Product Designer
                        </h4>
                        <p className="mb-3">Chipatle Mexican Grill</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c5.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Product Designer, Social
                        </h4>
                        <p className="mb-3">Mattel</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img
                              src="images/Pathao_-_Here_with_You 1.png"
                              alt
                            />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Jr. Product Designer
                        </h4>
                        <p className="mb-3">Pathao limited</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img
                              src="images/Pathao_-_Here_with_You 1.png"
                              alt
                            />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Jr. Product Designer
                        </h4>
                        <p className="mb-3">Pathao limited</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c2.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Growth Product Designer
                        </h4>
                        <p className="mb-3">Nike</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c3.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Digital Product Designer
                        </h4>
                        <p className="mb-3">JPMorgan Chase Bank</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c4.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Sr. Product Designer
                        </h4>
                        <p className="mb-3">Chipatle Mexican Grill</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c5.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Product Designer, Social
                        </h4>
                        <p className="mb-3">Mattel</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img
                              src="images/Pathao_-_Here_with_You 1.png"
                              alt
                            />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Jr. Product Designer
                        </h4>
                        <p className="mb-3">Pathao limited</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c4.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Sr. Product Designer
                        </h4>
                        <p className="mb-3">Chipatle Mexican Grill</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c5.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Product Designer, Social
                        </h4>
                        <p className="mb-3">Mattel</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="contact-tab-pane"
                role="tabpanel"
                aria-labelledby="contact-tab"
                tabIndex={0}
              >
                <div className="row mt-4">
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img
                              src="images/Pathao_-_Here_with_You 1.png"
                              alt
                            />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Jr. Product Designer
                        </h4>
                        <p className="mb-3">Pathao limited</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c2.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Growth Product Designer
                        </h4>
                        <p className="mb-3">Nike</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c3.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Digital Product Designer
                        </h4>
                        <p className="mb-3">JPMorgan Chase Bank</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c4.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Sr. Product Designer
                        </h4>
                        <p className="mb-3">Chipatle Mexican Grill</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c5.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Product Designer, Social
                        </h4>
                        <p className="mb-3">Mattel</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img
                              src="images/Pathao_-_Here_with_You 1.png"
                              alt
                            />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Jr. Product Designer
                        </h4>
                        <p className="mb-3">Pathao limited</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img
                              src="images/Pathao_-_Here_with_You 1.png"
                              alt
                            />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Jr. Product Designer
                        </h4>
                        <p className="mb-3">Pathao limited</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c2.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Growth Product Designer
                        </h4>
                        <p className="mb-3">Nike</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c3.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Digital Product Designer
                        </h4>
                        <p className="mb-3">JPMorgan Chase Bank</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c4.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Sr. Product Designer
                        </h4>
                        <p className="mb-3">Chipatle Mexican Grill</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c5.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Product Designer, Social
                        </h4>
                        <p className="mb-3">Mattel</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img
                              src="images/Pathao_-_Here_with_You 1.png"
                              alt
                            />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Jr. Product Designer
                        </h4>
                        <p className="mb-3">Pathao limited</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c4.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Sr. Product Designer
                        </h4>
                        <p className="mb-3">Chipatle Mexican Grill</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 ">
                    <div className="card rounded-0 border-0 p-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="compny-img">
                            <img src="images/c5.png" alt />
                          </div>
                          <button
                            type="button"
                            className=" job-btn border-0 rounded-pill "
                          >
                            Full Time
                          </button>
                        </div>
                        <h4 className="mt-3 fw-semibold mb-1">
                          Product Designer, Social
                        </h4>
                        <p className="mb-3">Mattel</p>
                        <div className="price  d-flex ">
                          <span className="money">
                            <img src="images/money-bag 1.png" alt />
                          </span>
                          <span className="fs-16   ps-3">30,000 - 40,000</span>
                        </div>
                        <div className="location align-items-center justify-content-between d-flex mt-3">
                          <div className>
                            <span className="money">
                              <img src="images/locationicon.png " alt />
                            </span>
                            <span className=" ps-3">Jaipur Rajasthan</span>{" "}
                            <span></span>
                          </div>
                          <p className=" m-0">2 Day ago</p>
                        </div>
                        <Link to="/job-details">
                          {" "}
                          <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                            Quick Apply
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 d-flex align-items-center">
                <p className="p-0 m-0 pe-3">Itmems per Page </p>
                <select
                  className="form-select form-select-sm p-0 rounded-0 w-15 d-inline"
                  aria-label="Small select example"
                >
                  <option selected>10</option>
                  <option value={1}>one</option>
                  <option value={2}>Two</option>
                  <option value={3}>Three</option>
                </select>
                <p className="p-0 m-0 ps-3">1-10 of 100 itmes</p>
              </div>
              <div className="col-md-2  ">
                <div
                  className="btn-toolbar text-end "
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="First group"
                  >
                    <button type="button" className="btn btn-outline-secondary">
                      1
                    </button>
                    <button type="button" className="btn btn-outline-secondary">
                      2
                    </button>
                    <button type="button" className="btn btn-outline-secondary">
                      3
                    </button>
                    <button type="button" className="btn btn-outline-secondary">
                      4
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="footer-bar footer-bg mt-3">
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-end">
                  <p className="m-0 p-0">
                    <span className="pe-3">Terms and Conditions</span>
                    <span>Privacy and Policy</span>
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </div>
    </div>
  );
};

export default AppliedJobs;
