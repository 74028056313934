import React, { useEffect, useState } from "react";
import Header from "../partials/UserHeader";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

const APIURL = "https://job.creativewebpixel.in";
const PER_PAGE = 21
const Interview = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const type = localStorage.getItem("type");
    if (type !== "student") {
      navigate("/company-dashboard")
    }
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }
  }, []);


  const [interviewData, setInterviewData] = useState([])
  useEffect(() => {
    fetch(`${APIURL}/getAllInterviews`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setInterviewData(response.message)
        console.log(response)


      })
  }, [])

  const [currentPage, setCurrentPage] = useState(0);

  function handleClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
    // window.scrollTo(0, 0)

  }

  const offset = currentPage * PER_PAGE;

  const pageCount = Math.ceil(interviewData.length / PER_PAGE);
  return (
    <div>
      <Header />
      <div className="bg-1">
        <section className="find-job  bg-1 ">
          <div className="company-group bg-1 w-100  z-1">
            <div className="container">
              <div className="row   ">
                <div className="col-md-7">
                  <h3 className="mt-30">Interview Preparation</h3>
                </div>
                <div className="col-md-3 ">
                  <div className="input-group mt-30">
                    <span
                      className="input-group-text seaching-icon bg-white  "
                      id="inputGroup-sizing-default"
                    >
                      <Link to="">
                        <img src="images/job-seaching.png" alt />
                      </Link>
                    </span>
                    <input
                      type="text"
                      className="form-control search-input1"
                      placeholder=" Search..."
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                    />
                  </div>
                </div>
                <div className="col-md-2 ">
                  <select
                    className="form-select select-meanu mt-30 add-select   "
                    aria-label="Default select example"
                  >
                    <option selected>+ Filter</option>
                    <option value={1}>One</option>
                    <option value={2}>Two</option>
                    <option value={3}>Three</option>
                  </select>
                </div>
                <div className="col-md-12 pt-2 pb-4">
                  <ul
                    className="nav   nav-underline  z-1 bg-1"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item active" role="presentation">
                      <button
                        className="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                      >
                        All Interviews
                      </button>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabIndex={0}
              >
                <div className="row mt-4">
                  {interviewData.slice(offset, offset + PER_PAGE).map((item) => {
                    return (
                      <div className="col-md-4 mb-4 ">
                        <div className="card rounded-0 border-0 p-2">
                          <div className="card-body">
                            <h4 className="mt-2 fw-semibold mb-3 d-flex justify-content-between">
                              {item.title}
                              <button className="border-0 fs-6 py-1 job-btn rounded-pill text-primary">
                                Premium
                              </button>
                            </h4>
                            <p className="mb-3">
                              {item.description.slice(0,35)}
                            </p>

                            <p>
                              <span className="text-primary pe-2">
                                <i className="bi bi-alarm" />
                              </span>
                              {item.timing}
                            </p>
                            <p>
                              <span className="text-primary pe-2">
                                <i className="bi bi-three-dots fs-5" />
                              </span>
                              {item.category.slice(0,2).map((categoryItem)=>{
                                return(
                                  <button className="border-0 fs-6 py-1 m-1 job-btn rounded-pill text-primary">
                                  {categoryItem.category}
                                </button>

                                )
                              })}
                            </p>
                            <Link to={`/questions/${item.id}`}>
                              <button className="w-100 border-0 py-2 compny-btn cbtn-bg1 mt-4">
                                Start Hear
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                  })}
<div className="pagination fwmpag">
                    {/* breakLabel=". . ."
                      previousLabel={"◁ prev"}
                      nextLabel={"next ▷"} */}
                    <ReactPaginate
                      breakLabel=". . ."
                      previousLabel={"◁ prev"}
                      nextLabel={"next ▷"}
                      pageCount={pageCount}
                      onPageChange={handleClick}
                      pageRangeDisplayed={1}
                      containerClassName={"pagination page"}
                      renderOnZeroPageCount={null}
                      previousLinkClassName={"prevposts-link"}
                      nextLinkClassName={"nextposts-link"}
                      disabledClassName={"pagination__link--disabled "}
                      activeClassName={"pagination__link--active"}
                      marginPagesDisplayed={1}
                    // renderOnZeroPageCount={null}
                    />

                  </div>
                </div>
              </div>
           
            </div>
        
          </div>
        </section>
        <section className="footer-bar footer-bg mt-3">
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-end">
                  <p className="m-0 p-0">
                    <span className="pe-3">Terms and Conditions</span>
                    <span>Privacy and Policy</span>
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </div>
    </div>
  );
};

export default Interview;
