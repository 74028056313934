import React, { useEffect, useState } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import axios from "axios";
import UserHeader from "../partials/UserHeader";
import { Country, State, City } from "country-state-city";
const APIURL = "https://job.creativewebpixel.in";

const Profile = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const type = localStorage.getItem("type");
    if (type !== "student") {
      navigate("/company-dashboard");
    }
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }
  }, []);

  const [skillsName, setSkillsName] = useState(Array);
  const [output, setOutput] = useState("");

  const handleSkillsChange = (event) => {
    setOutput(event.target.value);
  };

  // SET SKILLS FUNCTION

  function setSkills() {
    setSkillsName([...skillsName, output]);
    console.log(skillsName);
    document.getElementById("skillsInput").value = "";
    setOutput("");
  }

  const handleSkillsTag = (indexToRemove) => {
    const newTags = skillsName.filter((_, index) => index !== indexToRemove);
    setSkillsName(newTags);
  };

  // const handleSkillsTag = (idToRemove) => {
  //   console.log(skillsName)
  //   const newInputSections = skillsName.filter(
  //     (section) => section.id !== idToRemove
  //   );
  //   setSkillsName(newInputSections);
  // };

  // SET USER INPUT FIELDS

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [state, setState] = useState("");
  const [selectState, setSelectState] = useState([]);
  const [city, setCity] = useState("");
  const [selectCity, setSelectCity] = useState([]);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [location, setLocation] = useState("");
  const [currentWork, setCurrentWork] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [enDate, setEndate] = useState("");
  const [profileHeadline, setProfileHeadline] = useState("");
  const [school, setSchool] = useState("");
  const [degree, setDegree] = useState("");
  const [fieldOfStudy, setFieldOfStudy] = useState("");
  const [studyStartDate, setStudyStartDate] = useState("");
  const [studyEndDate, setStudyEndDate] = useState("");
  const [grade, setGrade] = useState("");
  const [activities, setActivities] = useState("");
  const [educationDescription, setEducationDescription] = useState("");
  const [avtar, setAvtar] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [image, setImage] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [designation, setDesignation] = useState("");
  const [experience, setExperience] = useState("");
  const [language, setLanguage] = useState("");
  const [currentSallery, setCurrentSallery] = useState("");
  const [employementType, setEmployementType] = useState("");
  const [expectedSallery, setExpectedSallery] = useState("");

  const [inputSections, setInputSections] = useState([]);
  const [educationInput, setEducationInput] = useState([]);
  const [year, setYear] = useState([]);
  const [selectYear, setSelectYear] = useState(0);
  const [month, setMonth] = useState([]);
  const [selectMonth, setSelectMonth] = useState(0);

  const [avtarName, setAvtarName] = useState("");
  const [imageShow, setImageShow] = useState("");

  const uploadImg = (e) => {
    setImage(e.target.files[0]);
    console.log(e.target.files[0]);
    if (e.target.files[0]) {
      setAvtarName(e.target.files[0].name);
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageShow(reader.result);
      };

      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    const years = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ];
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    setYear(years);
    setMonth(months);
  }, []);

  const onselectState = async (e) => {
    console.log(e);
    //  setSelectStateCode(statecode)
    const email = localStorage.getItem("email");

    await axios({
      url: `${APIURL}/userDetails`,
      method: "GET",

      headers: {
        Authorization: `${email}`,
      },
    })
      .then((response) => response.data)
      .then(async (res) => {
        console.log("userdetails", res.message[0]);

        console.log(City.getCitiesOfState("IN"));
        console.log(State.getStatesOfCountry("IN"));
        const filterState = State.getStatesOfCountry("IN").filter((item) => {
          return item.name == e;
        });
        console.log("filterState", filterState);
        setState(filterState[0].name);
        //console.log("selectStateCode",selectStateCode)
        await setSelectCity(
          City.getCitiesOfState("IN", filterState[0].isoCode)
        );
        // console.log(City.getCitiesOfState("IN", filterState[0].isoCode));
      });

    //   console.log(City.getCitiesOfState("IN",selectStateCode))
  };

  useEffect(() => {
    setSelectState(State.getStatesOfCountry("IN"));
  }, []);

  useEffect(() => {
    const newInputSection = {
      id: inputSections.length,
      school: school,
      degree: degree,
      fieldOfStudy: fieldOfStudy,
      studyStartDate: studyStartDate,
      studyEndDate: studyEndDate,
      grade: grade,
      activities: activities,
      educationDescription: educationDescription,
    };
    setEducationInput([...educationInput, newInputSection]);

    console.log(educationInput);
  }, []);

  function educationInputFunc(index, key, value) {
    // console.log(array[0]["name"])
    //  console.log(index,key,value)

    //   var ab= inputSections[index][key] = value
    const updatedObjects = [...educationInput];
    updatedObjects[index] = { ...updatedObjects[index], [key]: value };
    setEducationInput(updatedObjects);
    //setInputSections(inputSections[0].title= "bablu")

    console.log(educationInput);
  }

  const educationInputSection = (e) => {
    e.preventDefault();
    const newInputSection = {
      id: inputSections.length,
      school: "",
      degree: "",
      fieldOfStudy: "",
      studyStartDate: "",
      studyEndDate: "",
      grade: "",
      activities: "",
      educationDescription: "",
    };
    setEducationInput([...educationInput, newInputSection]);
  };

  useEffect(() => {
    const newInputSection = {
      id: inputSections.length,
      title: title,
      employmentType: employmentType,
      companyName: companyName,
      location: location,
      currentWork: currentWork,
      startDate: startDate,
      enDate: enDate,
      profileHeadline: profileHeadline,
    };
    setInputSections([...inputSections, newInputSection]);

    console.log(inputSections);
  }, []);

  function abc(index, key, value) {
    // console.log(array[0]["name"])
    //  console.log(index,key,value)

    //   var ab= inputSections[index][key] = value
    const updatedObjects = [...inputSections];
    updatedObjects[index] = { ...updatedObjects[index], [key]: value };
    setInputSections(updatedObjects);
    //setInputSections(inputSections[0].title= "bablu")

    console.log(inputSections);
  }

  const addInputSection = (e) => {
    e.preventDefault();
    const newInputSection = {
      id: inputSections.length,
      title: "",
      employmentType: "",
      companyName: "",
      location: "",
      currentWork: "",
      startDate: "",
      enDate: "",
      profileHeadline: "",
    };
    setInputSections([...inputSections, newInputSection]);
  };

  const removeInputSection = (idToRemove) => {
    const newInputSections = inputSections.filter(
      (section) => section.id !== idToRemove
    );
    setInputSections(newInputSections);
  };

  const removeEducationInput = (idToRemove) => {
    const newInputSections = educationInput.filter(
      (section) => section.id !== idToRemove
    );
    setEducationInput(newInputSections);
  };

  // SELECT MALE FUNCTION

  //  GET USER DETAILS

  useEffect(() => {
    const email = localStorage.getItem("email");

    axios({
      url: `${APIURL}/userDetails`,
      method: "GET",

      headers: {
        Authorization: `${email}`,
      },
    })
      .then((response) => response.data)
      .then((res) => {
        console.log("userdetails", res.message[0]);
        // var professional = res.message[0].professional;
        // var education = res.message[0].education;
        // var skillsName = res.message[0].skillsName;

        // if (professional == null) {
        //   professional = [];
        // } else {
        //   professional = JSON.parse(professional);
        // }

        // if (education == null) {
        //   education = [];
        // } else {
        //   education = JSON.parse(education);
        // }

        // if (skillsName == null) {
        //   skillsName = [];
        // } else {
        //   skillsName = JSON.parse(skillsName);
        // }

        setName(res.message[0].fullname);
        setEmail(res.message[0].email);
        setPhoneNo(res.message[0].phoneNo);
        setDob(res.message[0].dob);
        setGender(res.message[0].gender);
        setState(res.message[0].country);
        console.log(res.message[0].country);
        setCity(res.message[0].city);
        setDescription(res.message[0].description);
        setFacebook(res.message[0].facebook);
        setTwitter(res.message[0].twitter);
        setInstagram(res.message[0].instagram);
        if (JSON.parse(res.message[0].professional) == null) {
          res.message[0].professional = [];
        } else {
          setInputSections(JSON.parse(res.message[0].professional));
        }
        //console.log(JSON.parse(res.message[0].professional));

        if (JSON.parse(res.message[0].education) == null) {
          res.message[0].education = [];
        } else {
          setEducationInput(JSON.parse(res.message[0].education));
        }

        if (JSON.parse(res.message[0].skillsName) == null) {
          res.message[0].skillsName = [];
        } else {
          setSkillsName(JSON.parse(res.message[0].skillsName));
        }

        setDesignation(res.message[0].designation);
        console.log(res.message[0].designation);
        setExperience(res.message[0].experience);

        console.log(res.message[0].experience.split("."));
        const yearMonth = res.message[0].experience.split(".");
        setSelectYear(yearMonth[0]);
        setSelectMonth(yearMonth[1]);
        setEmployementType(res.message[0].employementType);
        setLanguage(res.message[0].language);
        setCurrentSallery(res.message[0].currentSallery);
        setExpectedSallery(res.message[0].expectedSallery);
        setImage(res.message[0].avtar);

        // const abc = JSON.parse(skillsName);

        // const arr = abc.split(",");
        // console.log(arr);
        // for (let i = 0; i < arr.length; i++) {
        //   const element = arr[i];
        //   //console.log(element)
        //   setSkillsName([...skillsName, element]);
        // }
      })
      .catch((error) => console.log(error));
  }, []);

  const [response, setResponse] = useState("");
  const [loader, setLoader] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    console.log(inputSections);

    const experience = selectYear + "." + selectMonth;

    const formData = new FormData();

    formData.append("name", name);
    formData.append("email", email);
    formData.append("phoneNo", phoneNo);
    formData.append("dob", dob);
    formData.append("gender", gender);
    formData.append("country", state);
    formData.append("city", city);
    formData.append("description", description);
    formData.append("professional", JSON.stringify(inputSections));
    formData.append("education", JSON.stringify(educationInput));

    formData.append("skillsName", JSON.stringify(skillsName));
    formData.append("avtar", image);
    formData.append("designation", designation);
    formData.append("facebook", facebook);
    formData.append("twitter", twitter);
    formData.append("instagram", instagram);
    formData.append("experience", experience);
    formData.append("language", language);
    formData.append("currentSallery", currentSallery);
    formData.append("expectedSallery", expectedSallery);
    formData.append("employementType", employementType);

    console.log(formData);
    setLoader(true);
    axios({
      url: `${APIURL}/userCompleteProfile`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      data: formData,
    })
      .then((response) => response.data)
      .then((res) => {
        console.log("resss", res);
        setResponse(res.message);
        setLoader(false);
        if (res.message == "profile updated") {
          setTimeout(() => {
            setLoader(false);
            navigate("/user-dashboard");
          }, 1000);
        }
      })
      .catch((error) => console.log(error));
  }

  const [activeMenuItem, setActiveMenuItem] = useState("information");
  const handleScroll = async (id) => {

    const element = document.getElementById(id);
    console.log(id);
    if (element) {
      console.log(element);
      const windowHeight = window.innerHeight;
      const elementHeight = element.offsetHeight;
      const elementOffset = element.offsetTop;
      const scrollPosition = elementOffset - (windowHeight - elementHeight) / 2;
      console.log(scrollPosition)
      await window.scrollTo({ top: scrollPosition, behavior: "smooth" });
      await setActiveMenuItem(id);
    }
  };
  useEffect(() => {
    if (handleScroll()) {
      const scrollToSection = async () => {
        const sections = document.querySelectorAll(".scrollsection");
        const scrollPosition = window.scrollY;

        await sections.forEach((section) => {
          console.log(section);
          const sectionTop = section.offsetTop - 350;
          const sectionHeight = section.offsetHeight;
          console.log(sectionTop, scrollPosition, sectionHeight);
          console.log(
            scrollPosition,
            //  >=
            sectionTop
            //  &&
            //   scrollPosition < sectionTop + sectionHeight
          );

          if (
            scrollPosition >= sectionTop &&
            scrollPosition < sectionTop + sectionHeight
          ) {
            setActiveMenuItem(section.id);
          }
        });
      };

      // Call the scrollToSection  function on initial mount
      // scrollToSection ();

      // Attach scroll event listener when component mounts
      window.addEventListener("scroll", scrollToSection);

      // Cleanup: remove event listener when component unmounts
      return () => {
        window.removeEventListener("scroll", scrollToSection);
      };
    }
  }, []);








  return (
    <div style={{ background: "#F5F5F5" }}>
      <div>
        <UserHeader />

        {/* ======= header end ===== */}
        <section className="information-data border-bottom  pb-5">
          <div className="container">
            <div className="row   information-linkes">
              <div className="col-md-12 usertophead">
                <h3 className="mt-4">Hi, {name}</h3>
                <p className="pb-3">Update your photo and details hear.</p>
                <hr />
              </div>
              <div className="col-md-3 ">
                <div className="row position-sticky bg-1 main-head-bar "></div>
                <div
                  className="information-link position-sticky bg-1 list-group"
                  id="list-example"
                >
                  <Link
                    to=""
                    onClick={() => handleScroll("information")}
                    className={
                      activeMenuItem == "information"
                        ? " fw-semibold"
                        : "fw-semibold text-black"
                    }
                  >
                    Your Information
                  </Link>
                  <hr className="w-75" />
                  <Link
                    to=""
                    onClick={() => handleScroll("summary")}
                    className={
                      activeMenuItem == "summary"
                        ? "fw-semibold mb-3 d-block "
                        : " fw-semibold mb-3 d-block text-black"
                    }
                  >
                    Summary
                  </Link>
                  <Link
                    to=""
                    onClick={() => handleScroll("professional")}
                    className={
                      activeMenuItem == "professional"
                        ? "fw-semibold mb-3 d-block "
                        : " fw-semibold mb-3 d-block text-black"
                    }
                  >
                    Job History
                  </Link>
                  <Link
                    to=""
                    onClick={() => handleScroll("education")}
                    className={
                      activeMenuItem == "education"
                        ? "fw-semibold mb-3 d-block "
                        : " fw-semibold mb-3 d-block text-black"
                    }
                  >
                    Education History
                  </Link>
                  <Link
                    to=""
                    onClick={() => handleScroll("skills")}
                    className={
                      activeMenuItem == "skills"
                        ? "fw-semibold mb-3 d-block "
                        : " fw-semibold mb-3 d-block text-black"
                    }
                  >
                    Skills
                  </Link>
                  <hr className="w-75" />
                  <Link
                    to=""
                    onClick={() => handleScroll("settings")}
                    className={
                      activeMenuItem == "settings"
                        ? "fw-semibold mb-3 d-block "
                        : " fw-semibold mb-3 d-block text-black"
                    }
                  >
                    Confirmation &amp; settings
                  </Link>
                </div>
              </div>
              <div className="col-md-9 mt-5">
                <h4 className="pb-4" id="information">
                  Your Information
                </h4>

                <div className="uplaod-file mb-4">
                  <div className="drop_box">
                    <label htmlFor="#" className="mb-2">
                      Profile Image
                    </label>
                    <br />
                    {/* <input
                      onChange={(e) => setAvtar(e.target.files[0])}
                      type="file"
                      accept=""
                      id="fileID"
                    />

                    {image == "" || image == null ? <img
                     className="img-fluid"
                      src="images/image-upload.png"
                    /> : <img
                      style={{ width: "200px" }}
                      src={`${APIURL}/img/${image}`}
                    />}
 */}

                    <div className="uplaod-file mb-4">
                      <div className="drop_box row">
                        <button className="btn p-0 col-md-4">
                          {image == "" || image == null ? (
                            <img
                              src="images/image-upload.png"
                              alt=""
                              className="img-fluid"
                            />
                          ) : imageShow ? (
                            <img style={{ width: "200px" }} src={imageShow} />
                          ) : (
                            <img
                              style={{ width: "200px" }}
                              src={`${APIURL}/img/${image}`}
                            />
                          )}
                        </button>
                        <div className="file-upload-box col-md-8">
                          <input
                            type="file"
                            id="fileupload"
                            className="file-upload-input"
                            onChange={uploadImg}
                            multiple
                          />

                          <p className="text-center">
                            <p>
                              {" "}
                              <button className="btn p-0">
                                <img
                                  src="images/upload-file-icon.png"
                                  alt
                                  className="img-fluid"
                                />
                              </button>
                              <p className="pt-3">{avtarName}</p>
                            </p>{" "}
                            <span className="file-link">Click to upload </span>
                            or drag and drop SVG, PNG, JPG or GIF
                            (max. 800x400px)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label fw-medium"
                        >
                          Name
                        </label>
                        <input
                          disabled
                          value={name}
                          type="text"
                          className="form-control form-in bg-1 rounded-0 "
                          id="exampleFormControlInput1"
                          placeholder="Enter your name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label fw-medium "
                        >
                          Email
                        </label>
                        <input
                          disabled
                          value={email}
                          type="email"
                          className="form-control form-in bg-1 rounded-0 "
                          id="exampleFormControlInput1"
                          placeholder="Enter your name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label fw-medium "
                        >
                          Phone number
                        </label>
                        <input
                          disabled
                          value={phoneNo}
                          type="number"
                          className="form-control form-in bg-1 rounded-0 "
                          id="exampleFormControlInput1"
                          placeholder="Enter your name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label fw-medium "
                        >
                          Date of birth
                        </label>
                        <input
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                          type="date"
                          className="form-control form-in bg-1 rounded-0 "
                          id="exampleFormControlInput1"
                          placeholder="Enter your name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium mb-2"
                      >
                        Gender
                      </label>

                      <div className=" d-flex mb-4">
                        <div className="form-check me-5">
                          {gender == "" ? (
                            <input
                              onChange={(e) => setGender(e.target.id)}
                              className="form-check-input p-2 bg-1 me-3 rounded-0"
                              type="radio"
                              name="radio"
                              id="male"
                              checked={gender == "male" ? true : false}
                              required
                            />
                          ) : (
                            <input
                              onChange={(e) => setGender(e.target.id)}
                              className="form-check-input p-2 bg-1 me-3 rounded-0"
                              type="radio"
                              name="radio"
                              id="male"
                              checked={gender == "male" ? true : false}
                            />
                          )}

                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Male
                          </label>
                        </div>
                        <div className="form-check me-5">
                          <input
                            onChange={(e) => setGender(e.target.id)}
                            className="form-check-input p-2 bg-1 me-3 rounded-0"
                            type="radio"
                            name="radio"
                            id="female"
                            checked={gender == "female" ? true : false}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Female
                          </label>
                        </div>

                        {/* <div className="form-check">
                          <input

                            onChange={(e) => setGender(e.target.id)}
                            className="form-check-input p-2 bg-1 me-3 rounded-0"
                            type="radio"
                            name="radio"
                            id="transgender"
                             checked={gender == "transgender" ? true : false}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Transgender
                          </label>
                        </div> */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label fw-medium "
                        >
                          Designation
                        </label>
                        <input
                          value={designation}
                          onChange={(e) => setDesignation(e.target.value)}
                          type="text"
                          className="form-control form-in bg-1 rounded-0 "
                          id="exampleFormControlInput1"
                          placeholder=" Your Designation"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label fw-medium "
                        >
                          state
                        </label>
                        <select
                          onChange={(e) => {
                            onselectState(e.target.value);
                          }}
                          className="form-select form-in bg-1 rounded-0"
                          required
                        >
                          <option value={state} selected hidden>
                            {state ? state : "Select"}
                          </option>
                          {selectState.map((item) => {
                            return <option>{item.name}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label fw-medium "
                        >
                          City
                        </label>
                        <select
                          onChange={(e) => setCity(e.target.value)}
                          className="form-select form-in bg-1 rounded-0"
                          aria-label="Default select example"
                          required
                        >
                          <option value={city} selected hidden>
                            {city ? city : "Select"}
                          </option>
                          {selectCity.map((item) => {
                            return <option>{item.name}</option>;
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium "
                      >
                        Experience Level
                      </label>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className=" col-5">
                          <select
                            type="number"
                            className="form-select form-in bg-1 rounded-0"
                            onChange={(e) => setSelectYear(e.target.value)}
                            aria-label="Default select example"
                            placeholder="Year"
                          >
                            <option value={selectYear} disabled selected hidden>
                              {selectYear ? selectYear : "Select Year..."}
                            </option>
                            {year.map((item) => {
                              return <option>{item}</option>;
                            })}
                          </select>
                        </div>
                        <div className="px-3 d-flex ">to</div>
                        <div className="col-5">
                          <select
                            type="number"
                            className="form-select form-in bg-1 rounded-0"
                            onChange={(e) => setSelectMonth(e.target.value)}
                            aria-label="Default select example"
                            placeholder="Year"
                          >
                            <option
                              value={selectMonth}
                              disabled
                              selected
                              hidden
                            >
                              {selectMonth ? selectMonth : "Select Month..."}
                            </option>
                            {month.map((item) => {
                              return <option>{item}</option>;
                            })}
                          </select>
                        </div>
                      </div>

                      {/* <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium "
                      >
                        Experience Level
                      </label>
                      <input
                        className="form-control form-in bg-1 rounded-0"
                        onChange={(e) => setExperienceLvl(e.target.value)}
                        aria-label="Default select example"
                        required
                      />
                    </div> */}
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label fw-medium "
                        >
                          Language
                        </label>
                        <input
                          value={language}
                          onChange={(e) => setLanguage(e.target.value)}
                          type="text"
                          className="form-control form-in bg-1 rounded-0 "
                          id="exampleFormControlInput1"
                          placeholder="Language"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label fw-medium "
                        >
                          Current Sallery
                        </label>
                        <input
                          value={currentSallery}
                          onChange={(e) => setCurrentSallery(e.target.value)}
                          type="number"
                          className="form-control form-in bg-1 rounded-0 "
                          id="exampleFormControlInput1"
                          placeholder="Enter Current Sallery"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label fw-medium "
                        >
                          Expected Sallery
                        </label>
                        <input
                          value={expectedSallery}
                          onChange={(e) => setExpectedSallery(e.target.value)}
                          type="number"
                          className="form-control form-in bg-1 rounded-0 "
                          id="exampleFormControlInput1"
                          placeholder=" Enter Expected Sallery"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label fw-medium "
                        >
                          Employment Type
                        </label>
                        <select
                          className="form-select form-in bg-1 rounded-0"
                          onChange={(e) => setEmployementType(e.target.value)}
                          required
                        >
                          <option
                            value={employementType}
                            selected
                            disabled
                            hidden
                          >
                            {employementType == "full-time"
                              ? "Full Time"
                              : employementType == "half-time"
                                ? "Half Time"
                                : employementType == "freelancer"
                                  ? "Freelancer"
                                  : "Job Type..."}
                          </option>
                          <option value={"full-time"}>Full Time</option>
                          <option value={"half-time"}>Half Time</option>
                          <option value={"freelancer"}>Freelancer</option>
                        </select>
                      </div>
                    </div>
                    {/* ========== */}
                    <div className="col-md-12">
                      <h4 className="my-3 scrollsection" id="summary">
                        Summary
                      </h4>
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium "
                      >
                        Description
                      </label>
                      <textarea
                        onChange={(e) => setDescription(e.target.value)}
                        className="form-control  form-in bg-1 rounded-0"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        placeholder="Enter a description"
                        value={description}
                        required
                      />
                    </div>
                    {/* ============== */}

                    {inputSections.map((section, index) => {
                      console.log(section);

                      return (
                        <div className="row" key={section.id}>
                          <div className="col-md-12 mt-2">
                            <div className="d-flex align-items-center justify-content-between">
                              <h4
                                className="my-3 scrollsection"
                                id="professional"
                              >
                                Job History
                              </h4>
                              <button
                                type="button"
                                onClick={() => removeInputSection(index)}
                                className="border-0 bg-transparent fs-3"
                              >
                                <i className="fa-solid fa-xmark"></i>{" "}
                              </button>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium"
                              >
                                Job Title
                              </label>
                              <input
                                value={section.title}
                                onChange={(e) =>
                                  abc(section.id, "title", e.target.value)
                                }
                                type="text"
                                className="form-control form-in bg-1 rounded-0 "
                                id="exampleFormControlInput1"
                                placeholder="Job Title"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium "
                              >
                                Employment type
                              </label>

                              <select
                                className="form-select form-in bg-1 rounded-0"
                                onChange={(e) =>
                                  abc(
                                    section.id,
                                    "employmentType",
                                    e.target.value
                                  )
                                }
                              >
                                <option
                                  value={section.employmentType}
                                  selected
                                  disabled
                                  hidden
                                >
                                  {section.employmentType == "full-time"
                                    ? "Full Time"
                                    : section.employmentType == "half-time"
                                      ? "Half Time"
                                      : section.employmentType == "freelancer"
                                        ? "Freelancer"
                                        : "Job Type..."}
                                </option>
                                <option value={"full-time"}>Full Time</option>
                                <option value={"half-time"}>Half Time</option>
                                <option value={"freelancer"}>Freelancer</option>
                              </select>
                              {/* <input
                                value={section.employmentType}
                                onChange={(e) =>
                                  abc(
                                    section.id,
                                    "employmentType",
                                    e.target.value
                                  )
                                }
                                className="form-control form-in bg-1 rounded-0"
                                aria-label="Default select example"
                                placeholder="Your Type"
                              /> */}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium "
                              >
                                Company Name
                              </label>
                              <input
                                value={section.companyName}
                                onChange={(e) =>
                                  abc(section.id, "companyName", e.target.value)
                                }
                                type="text"
                                className="form-control form-in bg-1 rounded-0 "
                                id="exampleFormControlInput1"
                                placeholder="Enter your Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium "
                              >
                                Company Location
                              </label>
                              <input
                                value={section.location}
                                onChange={(e) =>
                                  abc(section.id, "location", e.target.value)
                                }
                                type="text"
                                className="form-control form-in bg-1 rounded-0 "
                                id="exampleFormControlInput1"
                                placeholder="Enter your Company location"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            {currentWork}
                            <div className="form-check mb-4">
                              <input
                                onChange={(e) =>
                                  abc(
                                    section.id,
                                    "currentWork",
                                    e.target.checked
                                  )
                                }
                                className="form-check-input p-2 bg-1 me-3 rounded-0"
                                type="checkbox"
                                defaultValue
                                id="flexCheckDefault"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                I am currently working in this role
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium "
                              >
                                Start date
                              </label>
                              <input
                                value={section.startDate}
                                onChange={(e) =>
                                  abc(section.id, "startDate", e.target.value)
                                }
                                type="date"
                                className="form-control form-in bg-1 rounded-0 "
                                id="exampleFormControlInput1"
                                placeholder="Enter your name"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium "
                              >
                                End date
                              </label>
                              <input
                                value={section.enDate}
                                onChange={(e) =>
                                  abc(section.id, "enDate", e.target.value)
                                }
                                type="date"
                                className="form-control form-in bg-1 rounded-0 "
                                id="exampleFormControlInput1"
                                placeholder="Enter your name"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium "
                              >
                                Profile Summary
                              </label>
                              <input
                                value={section.profileHeadline}
                                onChange={(e) =>
                                  abc(
                                    section.id,
                                    "profileHeadline",
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control form-in bg-1 rounded-0 "
                                id="exampleFormControlInput1"
                                placeholder="Profile Summary"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="col-md-12 mt-4">
                      {" "}
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium "
                      >
                        Add More History
                      </label>
                    </div>
                    <div className="col-md-12">
                      <button
                        onClick={addInputSection}
                        className=" ph-add-btn border-0 fs-6"
                      >
                        + Add More
                      </button>
                    </div>
                    {/* =========== */}
                    {educationInput.map((section, index) => {
                      console.log(section);

                      return (
                        <div className="row" key={section.id}>
                          <div className="col-md-12 mt-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <h4
                                className="my-3  scrollsection"
                                id="education"
                              >
                                Education History
                              </h4>
                              <button
                                type="button"
                                onClick={() => removeEducationInput(index)}
                                className="border-0 bg-transparent  fs-3"
                              >
                                <i className="fa-solid fa-xmark"></i>{" "}
                              </button>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium "
                              >
                                University Name
                              </label>
                              <input
                                value={section.school}
                                onChange={(e) =>
                                  educationInputFunc(
                                    section.id,
                                    "school",
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control form-in bg-1 rounded-0 "
                                id="exampleFormControlInput1"
                                placeholder="University Name"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium "
                              >
                                Degree
                              </label>
                              <input
                                value={section.degree}
                                onChange={(e) =>
                                  educationInputFunc(
                                    section.id,
                                    "degree",
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control form-in bg-1 rounded-0 "
                                id="exampleFormControlInput1"
                                placeholder="Bachelor’s degree"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium "
                              >
                                Field of study
                              </label>
                              <input
                                value={section.fieldOfStudy}
                                onChange={(e) =>
                                  educationInputFunc(
                                    section.id,
                                    "fieldOfStudy",
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control form-in bg-1 rounded-0 "
                                id="exampleFormControlInput1"
                                placeholder="your field"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium "
                              >
                                Start date
                              </label>
                              <input
                                value={section.studyStartDate}
                                onChange={(e) =>
                                  educationInputFunc(
                                    section.id,
                                    "studyStartDate",
                                    e.target.value
                                  )
                                }
                                type="date"
                                className="form-control form-in bg-1 rounded-0 "
                                id="exampleFormControlInput1"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium "
                              >
                                End date
                              </label>
                              <input
                                value={section.studyEndDate}
                                onChange={(e) =>
                                  educationInputFunc(
                                    section.id,
                                    "studyEndDate",
                                    e.target.value
                                  )
                                }
                                type="date"
                                className="form-control form-in bg-1 rounded-0 "
                                id="exampleFormControlInput1"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium "
                              >
                                Grade
                              </label>
                              <input
                                value={section.grade}
                                onChange={(e) =>
                                  educationInputFunc(
                                    section.id,
                                    "grade",
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control form-in bg-1 rounded-0 "
                                id="exampleFormControlInput1"
                                placeholder="Ente your grade"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium "
                              >
                                Activities and societies
                              </label>
                              <textarea
                                value={section.activities}
                                onChange={(e) =>
                                  educationInputFunc(
                                    section.id,
                                    "activities",
                                    e.target.value
                                  )
                                }
                                className="form-control  form-in bg-1 rounded-0"
                                id="exampleFormControlTextarea1"
                                rows={3}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-medium "
                              >
                                Description
                              </label>
                              <textarea
                                value={section.educationDescription}
                                onChange={(e) =>
                                  educationInputFunc(
                                    section.id,
                                    "educationDescription",
                                    e.target.value
                                  )
                                }
                                className="form-control  form-in bg-1 rounded-0"
                                id="exampleFormControlTextarea1"
                                rows={3}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div className="col-md-12 mt-4">
                      {" "}
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fw-medium "
                      >
                        Add More Education
                      </label>
                    </div>
                    <div className="col-md-12">
                      <button
                        onClick={educationInputSection}
                        className=" ph-add-btn border-0 fs-6"
                      >
                        + Add More
                      </button>
                    </div>
                    {/* ======== */}
                    <div className="col-md-12 mt-3">
                      <h4 className="my-3 scrollsection" id="skills">
                        Skills
                      </h4>
                      <p className="text-black">Skills</p>
                      <div className="skill-btn">
                        {skillsName == "" ? (
                          <input
                            type="text"
                            className="form-control form-in bg-1 rounded-0 visually-hidden"
                            placeholder="Enter your skills"
                            required
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control form-in bg-1 rounded-0  visually-hidden"
                            placeholder="Enter your skills"
                          />
                        )}
                        {skillsName.map((item, index) => {
                          return (
                            <button className=" ui-btn ph-add-btn border-0 mb-3 rounded-0 position-relative">
                              <span>{item}</span>
                              <button
                                type="submit"
                                onClick={() => handleSkillsTag(index)}
                                className="border-0 bg-transparent position-absolute  top-0 text-white fs-6"
                                style={{ right: "4px" }}
                              >
                                <i className="fa-solid fa-xmark"></i>{" "}
                              </button>
                            </button>
                          );
                        })}

                        <div
                          className="modal fade"
                          id="exampleModal"
                          tabIndex={-1}
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h1
                                  className="modal-title fs-5"
                                  id="exampleModalLabel"
                                >
                                  Enter Your Skills
                                </h1>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                />
                              </div>
                              <div className="modal-body">
                                <input
                                  id="skillsInput"
                                  type="text"
                                  onChange={handleSkillsChange}
                                  className="form-control form-in bg-1 rounded-0"
                                  placeholder="Enter your skills"
                                />
                              </div>
                              <div className="modal-footer">
                                {output == "" ? (
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    style={{ cursor: "not-allowed" }}
                                  >
                                    Save changes
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => setSkills()}
                                    type="button"
                                    className="btn btn-primary"
                                  >
                                    Save changes
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <button
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          className="ui-btn  ui-btn1 ph-add-btn border-0 rounded-0 "
                        >
                          {" "}
                          <span className="fs-5 pe-2">+</span>Add More
                        </button>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <h4 className="my-3 scrollsection" id="skills">
                        Social Media
                      </h4>

                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label fw-medium "
                          >
                            Facebook
                          </label>
                          <input
                            value={facebook}
                            onChange={(e) => setFacebook(e.target.value)}
                            type="url"
                            className="form-control form-in bg-1 rounded-0 "
                            id="exampleFormControlInput1"
                            placeholder="Enter your facebook url"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label fw-medium "
                          >
                            Twitter
                          </label>
                          <input
                            value={twitter}
                            onChange={(e) => setTwitter(e.target.value)}
                            type="url"
                            className="form-control form-in bg-1 rounded-0 "
                            id="exampleFormControlInput1"
                            placeholder="Enter your twitter url"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label fw-medium "
                          >
                            Instagram
                          </label>
                          <input
                            value={instagram}
                            onChange={(e) => setInstagram(e.target.value)}
                            type="url"
                            className="form-control form-in bg-1 rounded-0 "
                            id="exampleFormControlInput1"
                            placeholder="Enter your instagraam url"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* ===== */}
                    <div className="col-md-12 mt-3">
                      <h4 className="my-3  scrollsection" id="settings">
                        Confirmation &amp; settings
                      </h4>
                      <div className="form-check mb-4">
                        <input
                          className="form-check-input p-2 bg-1 me-3 rounded-0"
                          type="checkbox"
                          defaultValue
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          If you work for a company and want to hide your name
                          and photo under the company's name, click here.
                        </label>
                      </div>
                      <div className="previous-pbtn mb-4">
                        <button className=" pre-y-pro-btn border-0 rounded-0 mb-2 ">
                          Preview your Profile
                        </button>
                      </div>
                      <hr />
                      <div className="form-end">
                        <div className="row">
                          <div className="col-md-5">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit.&nbsp;
                            </p>
                          </div>
                          <div className="col-md-7 text-end ">
                            <button className=" form-ebtn1  border-0 rounded-0 me-2  ">
                              {" "}
                              Cancel
                            </button>
                            <button className=" form-ebtn2  border-0 rounded-0 me-2 ">
                              {" "}
                              Preview as a Candidate
                            </button>
                            {loader ? (
                              <button
                                type="submit"
                                className=" form-ebtn3  border-0 rounded-0 mb-2 position-relative"
                                disabled
                              >
                                <span className="opacity-0">
                                  {" "}
                                  Save &amp; Publish
                                </span>
                                <div
                                  className="spinner-border position-absolute"
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    right: "60px",
                                  }}
                                  role="status"
                                >
                                  <span class="visually-hidden ">
                                    Loading...
                                  </span>
                                </div>
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className=" form-ebtn3  border-0 rounded-0 mb-2 "
                              >
                                {" "}
                                Save &amp; Publish
                              </button>
                            )}
                            <h4>{response}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="footer-bar footer-bg ">
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-end">
                  <p className="m-0 p-0">
                    <span className="pe-5">Terms and Conditions</span>
                    <span>Privacy and Policy</span>
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </div>
    </div>
  );
};

export default Profile;
