import React from 'react'
import Header from '../partials/UserHeader'
import { Link } from 'react-router-dom'


const Loading = () => {
  return (
    <div>
  <Header/>

    <div className='bg-1'>

  <section className="find-job border-bottom mt-5 pb-5">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-md-8">
          <div className="card text-center border-0 rounded-0">
            <div className="card-body p-5">
              <div className="upload  ">
                <input type="file" />
                <img src="./images/upload-file-icon.png" alt />
              </div>
              <h6 className="fw-normal w-50 mx-auto mt-4"><Link to="">Click to upload</Link> or drag and drop SVG, PNG, JPG or PDF
                (Max. 2MB)</h6>
            </div>
          </div>
          <p className="pt-3">Lorem ipsum dolor sit amet consectetur adipiscing elit.</p>
          <div className="buttons-assment justify-content-between d-flex">
            <button className=" rounded-0 d-block cancel-btn px-4 me-3"><i className="bi bi-arrow-left pe-2" />Cancel</button>
            <button className=" rounded-0 text-black d-block px-4 continue-btn">Submit <i className="bi bi-arrow-right ps-2" /></button>
          </div>
        </div>
        <div className="col-md-4 ">
          <div className="card py-2 rounded-0 border-0">
            <div className="card-body">
              <p>Due: 29, March 2024, 11:11 AM</p>
              <div className="clocker-time fs-5 text-primary bg-1 d-inline px-3 py-2">
                <span><i className="bi bi-alarm pe-2" /></span>
                <span>00:20:25s</span>
              </div>
              <span className="ps-3">Remaining</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="footer-bar footer-bg ">
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-end">
            <p className="m-0 p-0"><span className="pe-3">Terms and Conditions</span><span>Privacy and Policy</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </section>
</div>

    </div>
  )
}

export default Loading
