import React, { useEffect, useState } from "react";
import CompanyHeader from "../partials/CompanyHeader";
import { useNavigate } from "react-router";
import axios from "axios";
import AllCandidate from "./AllCandidate";
import { Link } from "react-router-dom";

const APIURL = "https://job.creativewebpixel.in";

const Companydashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const type = localStorage.getItem("type");
    if (type !== "company") {
      navigate("/user-dashboard");
    }
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }
  }, []);





  const [profile, setProfile] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("email");

    axios({
      url: `${APIURL}/userDetails`,
      method: "GET",

      headers: {
        Authorization: `${userId}`,
      },
    })
      .then((response) => response.data)
      .then((res) => {
        console.log("userdetails", res.message[0]);

        setProfile(res.message[0]);
      });
  }, []);


  const [allCandidate, setAllCandidate] = useState([]);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    fetch(`${APIURL}/getAllJobRequest`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `${userId}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setAllCandidate(response.message.sort().reverse());
        console.log(response.message);
      });
  }, []);


  const [ListedJobs, setListedJobs] = useState([])


  useEffect(() => {
    fetch(`${APIURL}/ourListedJob`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `${userId}`
      }
    }).then((res) => res.json()).then((response) => {

      setListedJobs(response.message.sort().reverse())
      console.log(response.message)
    })
  }, [])


  return (
    <>
      <div>
        <CompanyHeader />
        <section className="mt-5">
          <div>
            <div className="container">
              <h1 className="fw-semibold">Welcome back, {profile.fullname}!</h1>
              <p className="m-0">Experience the benefits of Candidate Tracking, Management, and Forecasting.</p>
              <div className="row pt-5">
                <div className=" col-lg-4 col-md-6 mb-3">
                  <div className="bg-white border border-1 py-4 px-4">
                    <div className="d-flex  justify-content-between align-items-center">
                      <h6 className="fw-semibold m-0">Total Candidate</h6>
                      {/* <a href="#" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical text-color2 fs-3" /></a>
                      <ul className="dropdown-menu dropdown-menu1">
                        <li><a className="dropdown-item" href="#">Action</a></li>
                        <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                      </ul> */}
                    </div>
                    <p className="fw-bold fs-1 pt-3 text-black m-0" id="number1">{allCandidate.length}</p>
                    <div className="d-flex  justify-content-between align-items-center">
                      <p className="text-color5 fw-medium m-0"><i className="bi bi-arrow-up ">40% <span className="ps-2 text-black">vs Last month</span></i></p>
                      <p className="bg-color6 px-2 m-0 rounded-1"><i className="bi bi-people fs-3 text-white" /></p>
                    </div>
                  </div>
                </div>
                <div className=" col-lg-4 col-md-6 mb-3">
                  <div className="bg-white border border-1 py-4 px-4">
                    <div className="d-flex  justify-content-between align-items-center">
                      <h6 className="fw-semibold m-0">New Candidates</h6>
                      {/* <a href="#" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical text-color2 fs-3" /></a>
                      <ul className="dropdown-menu dropdown-menu3">
                        <li><a className="dropdown-item" href="#">kkkkk</a></li>
                        <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                      </ul> */}
                    </div>
                    <p className="fw-bold fs-1 pt-3 text-black m-0" id="number2">10</p>
                    <div className="d-flex  justify-content-between align-items-center">
                      <p className="text-color5 fw-medium m-0"><i className="bi bi-arrow-up ">12% <span className="ps-2 text-black">vs Last month</span></i></p>
                      <p className="bg-color1 px-2 m-0 rounded-1"><i className="bi bi-person-check-fill fs-3 text-white" /></p>
                    </div>
                  </div>
                </div>
                <div className=" col-lg-4 col-md-6 mb-3">
                  <div className="bg-white border border-1 py-4 px-4">
                    <div className="d-flex  justify-content-between align-items-center">
                      <h6 className="fw-semibold m-0">New Job Create</h6>
                      {/* <a href="#" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical text-color2 fs-3" /></a>
                      <ul className="dropdown-menu dropdown-menu6">
                        <li><a className="dropdown-item" href="#">kkkkk</a></li>
                        <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                      </ul> */}
                    </div>
                    <p className="fw-bold fs-1 pt-3 text-black m-0" id="number3">{ListedJobs.length}</p>
                    <div className="d-flex  justify-content-between align-items-center">
                      <p className="text-color5 fw-medium m-0"><i className="bi bi-arrow-up ">6% <span className="ps-2 text-black">vs Last month</span></i></p>
                      <p className="bg-black px-2 m-0 rounded-1"><i className="bi bi-envelope fs-3 text-white" /></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*-=======================section=2============================== */}
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-12 mb-3">
                    <div className="bg-white border  border-1 overflow-x-auto">
                      <div className="d-flex justify-content-between align-items-center px-4  py-2">
                        <h5>Applications</h5>
                        {/* <a href="#" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical text-color2 fs-3" /></a>
                        <ul className="dropdown-menu dropdown-menu4">
                          <li><a className="dropdown-item" href="#">jojoggrroi</a></li>
                          <li><a className="dropdown-item" href="#">Another action</a></li>
                          <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul> */}
                      </div>
                      <table className="table">
                        <tbody>
                          {allCandidate.map((item) => {

                            return (

                              <tr style={{ border: 'none #fff', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                <th className="text-center" style={{ width: '10%' }}>
                                  {item.userId.avtar == "" || item.userId.avtar == null ? (
                                    <Link to={`/candidate-detail/${item.userId.id}`}> <img
                                      // style={{
                                      //   width: "30%",
                                      //   height: "100px",
                                      //   objectFit: "cover",
                                      // }}
                                      width="60%"
                                      src="images/profile.jpg"
                                      alt=""
                                      className="rounded-circle"
                                    />
                                    </Link>
                                  ) : (
                                    <Link to={`/candidate-detail/${item.userId.id}`}>
                                      <img width="60%" src={APIURL + "/img/" + item.avtar} alt className="rounded-circle" /></Link>
                                  )}


                                </th>
                                <td className=" ">{item.userId.fullname}</td>
                                <td className><p className="bg-color3 text-color m-0 text-center rounded-pill px-3">{item.userId.designation}</p></td>

                                <td className=" text-center">{item.userId.employementType == "full-time" ? "Full Time" : item.userId.employementType == "half-time" ? "Half Time" : item.userId.employementType == "freelancer" ? "Freelancer" : ""}</td>
                              </tr>
                            )
                          })}

                        </tbody></table>
                    </div>
                  </div>
                  <div className=" col-lg-4 col-md-12 mb-3">
                    <div className="bg-white border border-1 py-2 px-4">
                      <div className="d-flex  justify-content-between align-items-center pb-4">
                        <h6 className="fw-semibold m-0">Jobs</h6>
                        {/* <a href="#" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical text-color2 fs-3" /></a>
                        <ul className="dropdown-menu dropdown-menu5">
                          <li><a className="dropdown-item" href="#">jojoggrroi</a></li>
                          <li><a className="dropdown-item" href="#">Another action</a></li>
                          <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul> */}
                      </div>
                      {ListedJobs.map((item) => {
                        return (
                          <div className="d-flex  justify-content-between align-items-center pt-3">
                            <p className=" fw-medium m-0">{item.jobTitle}</p>
                            <p className=" m-0 ">{item.jobType == "full-time" ? "Full Time" : item.jobType == "half-time" ? "Half Time" : item.jobType == "freelancer" ? "Freelancer" : ""}</p>
                          </div>
                        )
                      })}

                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        <section className="footer-bar footer-bg ">
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-end">
                  <p className="m-0 p-0">
                    <span className="pe-3">Terms and Conditions</span>
                    <span>Privacy and Policy</span>
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </div>
    </>
  );
};

export default Companydashboard;
