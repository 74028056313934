import React, { useEffect, useState } from "react";
import Header from "../partials/UserHeader";
import { useNavigate } from "react-router";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import axios from "axios";
import { Link } from "react-router-dom";

const APIURL = "https://job.creativewebpixel.in";

const Userdashboard = () => {
  const navigate = useNavigate();

  const [searchJob, setSearchJob] = useState("");
  useEffect(() => {
    const type = localStorage.getItem("type");
    if (type !== "student") {
      navigate("/company-dashboard");
    }
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    if (!type || !email || !userId) {
      navigate("/");
    }
  }, []);

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const userId = localStorage.getItem("email");

    axios({
      url: `${APIURL}/userDetails`,
      method: "GET",

      headers: {
        Authorization: `${userId}`,
      },
    })
      .then((response) => response.data)
      .then((res) => {
        console.log("userdetails", res.message[0]);
        if (res.message[0].status == "panding") {
          setPercentage(25);
        } else {
          setPercentage(100);
        }

        //setProfileStatus(res.message[0].status);
      });
  }, []);

  return (
    <>
      <div>
        <Header />
        <section className="find-job border-bottom  pb-5">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-12">
                <h3 className="mt-5">Find a job with Us</h3>
                <p className>
                  Experience the benefits of Candidate Tracking, Management, and
                  Forecasting.
                </p>
              </div>
              <div className="col-md-10">
                <div className="input-group my-2">
                  <span
                    className="input-group-text seaching-icon  "
                    id="inputGroup-sizing-default"
                  >
                    {" "}
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control search-input"
                    placeholder=" Job title, keywords, or company"
                    aria-label="Sizing example input"
                    onChange={(e) => setSearchJob(e.target.value)}
                    aria-describedby="inputGroup-sizing-default"
                  />
                </div>
              </div>

              <div className="col-md-2">
                {searchJob == "" ? (
             <Link to="">
             <button
               className="btn btn-warning rounded-0 w-100 "
               style={{ paddingTop: 14, paddingBottom: 14 }}
             >
               <i class="fa-solid fa-magnifying-glass"></i>{" "}
               &nbsp;&nbsp;Search
             </button>
           </Link>
                ) : (
                  <Link to={`/all-jobs/?jobs=${searchJob}`}>
                  <button
                    className="btn btn-warning rounded-0 w-100 "
                    style={{ paddingTop: 14, paddingBottom: 14 }}
                  >
                    <i class="fa-solid fa-magnifying-glass"></i>{" "}
                    &nbsp;&nbsp;Search
                  </button>
                </Link>
                )}
              </div>

              {/* <div className="col-md-2 my-2">
                <select
                  className="form-select select-meanu "
                  aria-label="Default select example"
                >
                  <option selected>Experience Level</option>
                  <option value={1}>One</option>
                  <option value={2}>Two</option>
                  <option value={3}>Three</option>
                </select>
              </div>
              <div className="col-md-2 my-2">
                <select
                  className="form-select select-meanu "
                  aria-label="Default select example"
                >
                  <option selected>Salary estimate</option>
                  <option value={1}>One</option>
                  <option value={2}>Two</option>
                  <option value={3}>Three</option>
                </select>
              </div>
              <div className="col-md-3 my-2">
                <select
                  className="form-select select-meanu "
                  aria-label="Default select example"
                >
                  <option selected>City, State, Zip code, or Remort</option>
                  <option value={1}>One</option>
                  <option value={2}>Two</option>
                  <option value={3}>Three</option>
                </select>
              </div> */}
            </div>
          </div>
        </section>
        <section className="career-profile py-5">
          <div className="container">
            <div className="row bg-white pt-5 jobdetail">
              <div className="col-md-2 my-2">
                <CircularProgressbar
                  value={percentage}
                  text={`${percentage}%`}
                  styles={{
                    // Customize the root svg element
                    root: {},
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: `#1a2fe7`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // Customize transition animation
                      transition: "stroke-dashoffset 3s ease 0s",
                      // Rotate the path
                      transform: "rotate(0turn)",
                      transformOrigin: "center center",
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      // Trail color
                      stroke: "#d6d6d6",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // Rotate the trail
                      transform: "rotate(0.25turn)",
                      transformOrigin: "center center",
                    },
                    // Customize the text
                    text: {
                      // Text color
                      fill: "#000",
                      // Text size
                      fontSize: "20px",
                      dominantBaseline: "middle",
                      textAnchor: "middle",
                    },
                    // Customize background - only used when the `background` prop is true
                    background: {
                      fill: "#3e98c7",
                    },
                  }}
                />
              </div>
              <div className="col-md-7 my-2">
                <h3 className="pb-1">Complete Career Profile</h3>
                <p className="">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  sagittis, elit vitae hendrerit fringilla, mauris ipsum
                  faucibus magna.
                </p>
                <Link to="/userProfile">
                  <button className="btn btn-warning rounded-0 px-3">
                    Complete My Career Profile
                  </button>
                </Link>
              </div>
              <div className="col-md-3 my-2">
                <img
                  src="./images/OBJECTS-profile.png"
                  className="img-fluid"
                  alt
                />
              </div>
            </div>
          </div>
        </section>
        <section className="footer-bar footer-bg ">
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-end">
                  <p className="m-0 p-0">
                    <span className="pe-3">Terms and Conditions</span>
                    <span>Privacy and Policy</span>
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </div>
    </>
  );
};

export default Userdashboard;
