import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import UserHeader from '../partials/UserHeader';
const APIURL = "https://job.creativewebpixel.in";


const PortfolioForm = () => {
    const navigate = useNavigate()


    const [projectUrl, setProjectUrl] = useState("")
    const [projectName, setProjectName] = useState("")
    const [projectImg, setProjectImg] = useState("")
    const [projectTeam, setProjectTeam] = useState("")
    const [projectRole, setProjectRole] = useState("")
    const [projectDuration, setProjectDuration] = useState("")
    const [projectDescription, setProjectDescription] = useState("")
    const [loader, setLoader] = useState(false)
    const [response, setResponse] = useState("")


    const handleSubmit = (e) => {
        e.preventDefault()


        const object = {
            projectUrl,
            projectName,
            projectImg,
            projectTeam,
            projectRole,
            projectDuration,
            projectDescription,
        }
        console.log(object)
        const userId = localStorage.getItem("userId")
        const formData = new FormData();

        formData.append("projectName", projectName);
        formData.append("description", projectDescription);
        formData.append("duration", projectDuration);
        formData.append("image", projectImg);
        formData.append("roll", projectRole);
        formData.append("team", projectTeam);
        formData.append("url", projectUrl);
        formData.append("userId", userId);
        setLoader(true)
        axios({
            url: `${APIURL}/addProjects`,
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            },
            data: formData,
        }).then((response) => response.data)
            .then((res) => {
                console.log(res)
                setResponse(res.message)
                if (res.message = "project create successfully") {
                    setTimeout(() => {
                        navigate("/portfolio")
                    }, 1000)
                }
                setLoader(false)

            })

    }



    return (
        <div>
            <UserHeader />
            <section className="information-data border-bottom  pb-5">
                
                <div className="container">

                <h4 className=" mt-5 border-bottom pb-3" id="information">
                                Project Details
                            </h4>
                    <div className="row mt-5 information-linkes">
                       
                        <div className="col-md-9">
                        
                            {/* <label htmlFor="#" className="mb-2">
                  Upload
                </label> */}
                            <form onSubmit={handleSubmit}>
                                <div className="uplaod-file mb-4">

                                    <div className="drop_box">
                                        <input
                                            onChange={(e) => setProjectImg(e.target.files[0])}
                                            type="file"
                                            accept=""
                                            id="fileID"
                                        />
                                        {/* <button className="btn p-0">
                                            <img
                                                src="images/image-upload.png"
                                                alt
                                                className="img-fluid"
                                            />
                                        </button> */}
                                    </div>
                                    <div>
                                        {/* <img
                                            style={{ width: "200px" }}
                                           src={`${APIURL}/img/${image}`}
                                        /> */}
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="exampleFormControlInput1"
                                                className="form-label fw-medium"
                                            >
                                                Project Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control form-in bg-1 rounded-0 "
                                                id="exampleFormControlInput1"
                                                placeholder="Project Name"
                                                onChange={(e) => setProjectName(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="exampleFormControlInput1"
                                                className="form-label fw-medium "
                                            >
                                                Project Url
                                            </label>
                                            <input
                                                type='url'
                                                className="form-control form-in bg-1 rounded-0"
                                                onChange={(e) => setProjectUrl(e.target.value)}
                                                required
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="exampleFormControlInput1"
                                                className="form-label fw-medium "
                                            >
                                                Team Member
                                            </label>
                                            <input
                                                type='number'
                                                className="form-control form-in bg-1 rounded-0"
                                                onChange={(e) => setProjectTeam(e.target.value)}
                                                required
                                            />

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="exampleFormControlInput1"
                                                className="form-label fw-medium "
                                            >
                                                Your Role
                                            </label>
                                            <input
                                                className="form-control form-in bg-1 rounded-0"
                                                aria-label="Default select example"
                                                onChange={(e) => setProjectRole(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label fw-medium "
                                        >
                                            Project Duration
                                        </label>
                                        <input
                                            className="form-control form-in bg-1 rounded-0"
                                            aria-label="Default select example"
                                            onChange={(e) => setProjectDuration(e.target.value)}
                                            required
                                        />

                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="exampleFormControlInput1"
                                                className="form-label fw-medium"
                                            >
                                                Project Details
                                            </label>
                                            <textarea
                                                type="text"
                                                className="form-control form-in bg-1 rounded-0"
                                                aria-label="Default select example"
                                                onChange={(e) => setProjectDescription(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* <div class="col-md-3">
                          
                      </div> */}







                                    <hr />
                                    <div className="form-end">
                                        <div className="row">
                                          
                                            <div className="col-md-12  ">
                                               
                                                {loader ? (
                                                    <button
                                                        type="submit"
                                                        className=" form-ebtn3  border-0 rounded-0 mb-2 position-relative"
                                                        disabled
                                                    >
                                                        <span className="opacity-0">
                                                            {" "}
                                                            Save &amp; Publish
                                                        </span>
                                                        <div
                                                            className="spinner-border position-absolute"
                                                            style={{
                                                                width: "25px",
                                                                height: "25px",
                                                                right: "60px",
                                                            }}
                                                            role="status"
                                                        >
                                                            <span class="visually-hidden ">Loading...</span>
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="submit"
                                                        className=" form-ebtn3  border-0 rounded-0 mb-2 "
                                                    >
                                                        {" "}
                                                        Save &amp; Publish
                                                    </button>
                                                )}
                                                <h5 style={{ color: "#FFCD4D" }}> {response}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PortfolioForm
