import { useEffect, useState } from 'react';
import { Link, history, useLocation } from 'react-router-dom';
import logo from "../../css/images/Black-Logo.png";
import VectorDown from "../../css/images/Vector-down.png";
import profile from "../../css/images/profile.png";
import ph_bell from "../../css/images/ph_bell.png";

const AllHeader = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState();
  useEffect(() => {
    setCurrentPath(location.pathname);

    // window.scrollTo(0, 0);
  }, [location]);

  return (


    <header>
      <nav className="navbar navbar-expand-lg bg-white" style={{ zIndex: 2 }}>
        <div className="container">
          <Link className="navbar-brand " to="/">
            <img src={logo} alt />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav nav-underline">
{/*          
              <li className="nav-item position-relative">
                <Link className="nav-link  " to="">
                  Work Opportunities
                </Link>
             
              </li>

              <li className="nav-item ">
                <Link className="nav-link " to="">
                  Interview Preperation
                </Link>
              </li> */}
              
              <li className="nav-item ">
                <Link className={currentPath=="/candidates"?"nav-link fw-normal active":"nav-link fw-normal"} to="/candidates">
                  Candidates
                </Link>
              </li>

              <li className="nav-item ">
                <Link className="nav-link " to="https://creativewebpixel.com/contact-us">
                  Contact us
                </Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link " to="/login">
                  Login
                </Link>
              </li>
              <li className="nav-item ">
                <Link className="btn btn-primary" to="/company-signup">
                  Registeration
                </Link>
              </li>


            </ul>
          </div>
        
        </div>
      </nav>
    </header>
  )
};

export default AllHeader;