
import './App.css';
import React from 'react';

import { BrowserRouter, Routes, Route ,useHistory} from 'react-router-dom';




import Home from './component/website/Home';
import Userdashboard from './component/student/Userdashboard';
import Loading from './component/student/Loading';
import Result from './component/student/Result';
import AllJobs from './component/student/AllJobs';
import Interview from './component/student/Interview';
import Online from './component/student/Online';
import UserProfile from './component/student/UserProfile';
import Companydashboard from './component/company/Companydashboard';


import './css/style.css';
import './css/hover-min.css'
import CreateJob from './component/company/CreateJob';
import JobDetail from './component/student/JobDetail';
import CompanyProfile from './component/company/CompanyProfile';
import AllCandidate from './component/company/AllCandidate';
import CandidateDetail from './component/company/CandidateDetail';
import StudentSignup from './component/student/StudentSignup';
import CompanySignup from './component/company/CompanySignup';
import Login from './component/website/Login';
import OurListedJob from './component/company/OurListedJob';
import AppliedJobs from './component/student/AppliedJobs';
import HiredCandidate from './component/company/HiredCandidate';
import RequestJobCandidate from './component/company/RequestJobCandidate';
import Auth from './component/partials/AllHeader';
import Portfolio from './component/student/Portfolio';
import PortfolioForm from './component/student/PortfolioForm';
import HomeJobs from './component/website/HomeJobs';
import HomeCandidate from './component/website/HomeCandidate';
import ForgetPassword from './component/website/ForgetPassword';
import NewUpdatePassword from './component/website/NewUpdatePassword';
import EditJob from './component/company/EditJob';
import MyJobDetail from './component/company/MyJobDetail';
import InterviewQuestions from './component/student/InterviewQuestions';
import InterviewStart from './component/student/InterviewStart';
import Subscription from './component/website/Subscription';
import CompanyInteriview from './component/company/CompanyInteriview';






function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/user-dashboard' element={<Userdashboard  />}  />
        <Route path='/company-dashboard' element={<Companydashboard />}  />
        <Route path='/candidate-signup' element={<StudentSignup />} />
        <Route path='/company-signup' element={<CompanySignup />} />
        <Route path='/login' element={<Login />} />
        <Route path='/loading' element={<Loading />} />
        <Route path='/result' element={<Result />} />
        <Route path='/all-jobs' element={<AllJobs />} />
        <Route path='/interview' element={<Interview />} />
        <Route path='/online' element={<Online />} />
        <Route path='/userProfile' element={<UserProfile />} />
        <Route path='/create-job' element={<CreateJob />} />
        <Route path='/job-details/:id' element={<JobDetail />} />
        <Route path='/company-profile' element={<CompanyProfile />} />
        <Route path='/all-candidate' element={<AllCandidate />} />
        <Route path='/listed-job' element={<OurListedJob />} />
        <Route path='/applied-job' element={<AppliedJobs />} />
        <Route path='/candidate-detail/:id' element={<CandidateDetail />} />
        <Route path='/hired-candidate' element={<HiredCandidate />} />
        <Route path='/candidate-request' element={<RequestJobCandidate />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/add-portfolio' element={<PortfolioForm />} />
        <Route path='/jobs' element={<HomeJobs />} />
        <Route path='/candidates' element={<HomeCandidate />} />
        <Route path='/forget-password' element={<ForgetPassword />} />
        <Route path='/enternewpassword' element={<NewUpdatePassword />} />
        <Route path='/edit-job/:id' element={<EditJob />} />
        <Route path='/my-job-detail/:id' element={<MyJobDetail />} />
        <Route path='/questions/:id' element={<InterviewQuestions />} />
        <Route path='/interview/:id' element={<InterviewStart />} />
        <Route path='/subscribe' element={<Subscription />} />
        <Route path='/company-interview' element={<CompanyInteriview />} />

      </Routes>


    </BrowserRouter>


  );
}

export default App;
